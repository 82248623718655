import { ObjValues } from '../types/util';

const riskPage = {
  risk: 'risk-recommendations',
  viewRecsBtn: 'risk-recommendations--view-recommendations-button',
} as const;
const riskSummary = {
  // controls on the summary
  previousMonthBtn: 'risk-recommendations--previous-month-button',
  nextMonthBtn: 'risk-recommendations--next-month-button',

  // fields on the summary
  numberOfUsers: 'risk-recommendations--num-users',
  numberOfRecommendations: 'risk-recommendations--num-recommendations',
  recommendationsRun: 'risk-recommendations--recommendations-run',
  totalExpiringArr: 'risk-recommendations--total-expiring-arr',
  totalNewContractValue: 'risk-recommendations--total-new-contract-value',
  totalNRR: 'risk-recommendations--total-nrr',
} as const;

const growthPage = {
  growth: 'growth-recommendations',
  viewRecsBtn: 'growth-recommendations--view-recommendations-button',
} as const;
const growthSummary = {
  // controls on the summary
  previousMonthBtn: 'growth-recommendations--previous-month-button',
  nextMonthBtn: 'growth-recommendations--next-month-button',

  // fields on the summary
  numberOfUsers: 'growth-recommendations--num-users',
  numberOfRecommendations: 'growth-recommendations--num-recommendations',
  recommendationsRun: 'growth-recommendations--recommendations-run',
  totalPriorPipeline: 'growth-recommendations--total-prior-pipeline',
  totalNetNewPipeline: 'growth-recommendations--total-net-new-pipeline',
} as const;

export const recommendations$ = {
  risk: { page: riskPage, summary: riskSummary },
  growth: { page: growthPage, summary: growthSummary },
};

export type Recommendations$Values =
  | ObjValues<typeof riskPage>
  | ObjValues<typeof riskSummary>
  | ObjValues<typeof growthPage>
  | ObjValues<typeof growthSummary>;
