import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import React from 'react';

export const ConnectedButton = () => (
  <Button
    variant="contained"
    disabled
    startIcon={<CheckIcon />}
    sx={{
      '&.Mui-disabled': {
        background: (theme) => theme.palette.success.main,
        color: (theme) => theme.palette.success.contrastText,
      },
    }}
  >
    Connected
  </Button>
);
