import { noop } from 'lodash';
import { createContext, useContext } from 'react';

import { AuthApi } from '../apis/auth';

/**
 * Error we raise when the user logs in, but has an invalid impersonation state.
 */
export class ImpersonationError extends Error {}

export class UnknownClientError extends Error {}
export class UnknownUserError extends Error {}

export enum AuthActionType {
  Federated = 'federated',
  UserPass = 'user-pass',
  Skeleton = 'skeleton',
  SkeletonSignUp = 'skeleton-sign-up',
  SignUp = 'sign-up',
  ConfirmSignUp = 'confirm-user',
  ResendSignUp = 'resend-sign-up',
}

/**
 * Login type used to authenticate with federated login.
 */
interface FederatedLogin {
  type: AuthActionType.Federated;
}

/**
 * Login type used to authenticate with username/password.
 */
interface UsernamePasswordLogin {
  type: AuthActionType.UserPass;
  username: string;
  password: string;
}

/**
 * Login type used to authenticate with username/password,
 * for the purposes of impersonating another user.
 */
interface SkeletonLogin {
  type: AuthActionType.Skeleton;
  username: string;
  password: string;
  clientMetadata: {
    impersonation_user: string;
  };
}

interface SkeletonSignUp {
  type: AuthActionType.SkeletonSignUp;
  username: string;
  password: string;
}

interface SignUpLogin {
  type: AuthActionType.SignUp;
  username: string;
  password: string;
}

interface ConfirmUser {
  type: AuthActionType.ConfirmSignUp;
  username: string;
  answer: string;
}

interface ResendSignUp {
  type: AuthActionType.ResendSignUp;
  username: string;
}

export type AuthAction =
  | FederatedLogin
  | UsernamePasswordLogin
  | SkeletonLogin
  | SkeletonSignUp
  | SignUpLogin
  | ConfirmUser
  | ResendSignUp;

export enum AuthStatus {
  /**
   * Status when the auth state has not yet been determined.
   */
  Loading = 'loading',
  /**
   * Status when the user is signed in and has a valid access token.
   */
  SignedIn = 'signed-in',
  /**
   * Status when the user is signed out.
   */
  SignedOut = 'signed-out',
  /**
   * Status when the user is being validated.
   */
  Confirming = 'confirming',
}

export interface SessionInfo {
  /**
   * The user's client id.
   */
  clientId: string;
  /**
   * Boolean flag, set if current user session is impersonating another user.
   */
  isImpersonating: boolean;
  /**
   * String label describing current user's authorization provider.
   */
  authProvider: string;
  /**
   * API-generated Knock user token; undefined when impersonating.
   */
  knockToken?: string;
}

interface LoadingAuth {
  status: AuthStatus.Loading;
}

interface SignedInAuth {
  status: AuthStatus.SignedIn;
  /**
   * Info related to the current user's auth session.
   */
  session: SessionInfo;
}

interface SignedOutAuth {
  status: AuthStatus.SignedOut;
}

interface ConfirmingAuth {
  status: AuthStatus.Confirming;
  signUpOptions: { username: string; password?: string | undefined };
  confirmed: boolean;
}

/**
 * The union of states that auth can be in.
 */
export type AuthState = LoadingAuth | SignedInAuth | SignedOutAuth | ConfirmingAuth;

export interface AuthActions {
  /**
   * Callback to log into the app.
   * @param options options used to login
   */
  authenticate(options: AuthAction): Promise<void>;
  /**
   * Callback to log out of the app.
   */
  logout(): Promise<void>;
}

export interface AuthError {
  error: Error | undefined;
  /**
   * Clears the current auth error.
   */
  clearError(): void;
}

/**
 * Tuple of the auth state and the auth service.
 */
export type AuthContext = [AuthState, AuthActions, AuthError];

/**
 * Context for the user's auth state.
 */
export const ReefAuthContext = createContext<AuthContext>([
  { status: AuthStatus.Loading },
  { authenticate: () => Promise.reject(noop()), logout: () => Promise.reject(noop()) },
  { error: undefined, clearError: noop },
]);

/**
 * Context for reef auth service.
 */
export const ReefAuthServiceContext = createContext<AuthApi | undefined>(undefined);

export const useReefAuthContext = (): AuthContext => useContext(ReefAuthContext);

/**
 * Hook to get an auth error if one exists.
 * @returns tuple of the auth error with a clear error callback
 */
export const useAuthError = (): [Error | undefined, () => void] => {
  const [, , { error, clearError }] = useContext(ReefAuthContext);
  return [error, clearError];
};
export const useReefAuthService = (): AuthApi => {
  const authService = useContext(ReefAuthServiceContext);
  if (authService == null) {
    throw new Error(
      'Auth service not initialized. Make sure this component is the child of a <ReefAuthProvider />.',
    );
  }
  return authService;
};

// TODO: clean up the various stats of this auth payload and properly union
export interface ReefAuthClaims {
  failed_skeleton?: 'true';
  impersonation_client_id?: string;
  client_id: string;
  impersonation_user_id?: string;
  user_id: string;
  email?: string;
  knock_user_token?: string;
  identities?: Array<{ providerName: string }>;
}

/**
 * Function to get session info from an ID token payload.
 * @param payload id token payload
 * @throws impersonation error if the user fails to skeleton
 * @returns session info
 */
export function getReefSessionInfo(payload: ReefAuthClaims): SessionInfo {
  if (String(payload['failed_skeleton']).toLowerCase() === 'true') {
    throw new ImpersonationError('not able to impersonate that user');
  }

  const isImpersonating = !!payload['impersonation_client_id'];
  const clientId = payload['impersonation_client_id'] ?? payload['client_id'];
  const authProvider: string =
    payload.identities == null
      ? 'Email/Password'
      : `${payload.identities[0].providerName} Authorization`;
  const knockToken = payload['knock_user_token'];

  return { clientId, isImpersonating, authProvider, knockToken };
}
