import { ArrowBack, ExitToApp } from '@mui/icons-material';
import { Box, Button, Container, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const NotFound = () => {
  const nav = useNavigate();
  const goBack = useCallback(() => nav(-1), [nav]);
  const returnHome = useCallback(() => nav('/'), [nav]);

  return (
    <Container sx={{ my: 4 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h4">Sorry, your destination does not exist!</Typography>
      </Box>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<ArrowBack />}
          onClick={goBack}
        >
          Back
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<ExitToApp />}
          onClick={returnHome}
        >
          Return Home
        </Button>
      </Box>
    </Container>
  );
};
