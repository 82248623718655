import { useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { SprintInviteUserDialogProps } from '../components/Dialogs/SprintInviteUserDialog';
import { SendUserInviteDocument } from '../graphql/generated';
import { Activity } from '../models/sprint';
import { asUUID, UUID } from '../models/uuid';
import { MISSING_USER, User } from './user';

export interface PromptUserInviteOptions {
  activity: Activity;
  userToInvite: User;
}
interface UseSprintInviteUserDialogProps {
  sprintId: UUID;
}
interface UseSprintInviteUserDialog extends SprintInviteUserDialogProps {
  promptUserInvite: ({ activity, userToInvite }: PromptUserInviteOptions) => void;
}
export const useSprintInviteUserDialog = ({
  sprintId,
}: UseSprintInviteUserDialogProps): UseSprintInviteUserDialog => {
  const [sendInvite] = useMutation(SendUserInviteDocument);
  const [relatedActivity, setRelatedActivity] = useState<Activity | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const userToInvite = useMemo(() => (selectedUser ? selectedUser : MISSING_USER), [selectedUser]);
  const promptUserInvite = useCallback(({ activity, userToInvite }: PromptUserInviteOptions) => {
    setRelatedActivity(activity);
    setSelectedUser(userToInvite);
  }, []);
  const sendUserInvite = useCallback(async () => {
    await sendInvite({
      variables: {
        inviteeId: userToInvite.id,
        sprintId,
      },
    });
  }, [sendInvite, sprintId, userToInvite.id]);
  const closeInvite = useCallback(() => setRelatedActivity(null), []);

  return useMemo(
    (): UseSprintInviteUserDialog => ({
      open: relatedActivity != null,
      sprintId,
      activityId: relatedActivity?.id ?? asUUID('MISSING-ACTIVITY-ID'),
      userToInvite,
      promptUserInvite,
      sendUserInvite,
      onClose: closeInvite,
    }),
    [closeInvite, promptUserInvite, relatedActivity, sendUserInvite, sprintId, userToInvite],
  );
};
