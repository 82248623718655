import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { ClientStatus } from '../../../graphql/generated';
import { useClientStatus } from '../../../hooks/client';

export const ActiveClientRequiredOutlet = () => {
  const { value: clientStatus } = useClientStatus();
  const nav = useNavigate();

  useEffect(() => {
    if (clientStatus === ClientStatus.Signed) {
      nav('/connectors');
    }
  }, [clientStatus, nav]);

  return <Outlet />;
};
