import { Add as AddIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useState } from 'react';

import { DaysUntilRenewalFilter } from '../../../../models/filters';
import { filterCustomers$ } from '../../../../selectors';
import { FilterStack } from '../FilterStack';

interface UpcomingRenewalFilterInputsProps {
  filter: DaysUntilRenewalFilter | undefined;
  disabled?: boolean;
  loading: boolean;
  onChange(days: number | undefined): void;
}
export const UpcomingRenewalFilterInput = ({
  filter,
  disabled,
  loading,
  onChange,
}: UpcomingRenewalFilterInputsProps) => {
  const [days, setDays] = useState<number>();
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} useFlexGap>
        <FormControl size="small" fullWidth>
          <InputLabel id="upcoming-renewal-label">Within days</InputLabel>
          <Select
            labelId="upcoming-renewal-label"
            label="Within days"
            value={days ?? ''}
            disabled={disabled}
            onChange={(e) => typeof e.target.value != 'string' && setDays(e.target.value)}
          >
            <MenuItem value={30}>30 Days</MenuItem>
            <MenuItem value={60}>60 Days</MenuItem>
            <MenuItem value={90}>90 Days</MenuItem>
            <MenuItem value={120}>120 Days</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          data-uid={filterCustomers$.editFilter.upcomingRenewal.saveBtn}
          sx={{ my: 'auto' }}
          variant="contained"
          size="small"
          color="primary"
          disabled={disabled || days == null}
          startIcon={<AddIcon />}
          onClick={() => {
            onChange(days);
            setDays(undefined);
          }}
          loading={loading}
        >
          Add
        </LoadingButton>
      </Stack>
      <FilterStack
        filters={filter != null ? [filter] : []}
        onDeleteFilter={() => onChange(undefined)}
      />
    </Stack>
  );
};
