import { Typography } from '@mui/material';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import React from 'react';

import { DATE_FORMAT_LONG_LOCALIZED } from '../../../../constants';
import { useUserDateSettings } from '../../../../contexts/userSettingsContext';
import { toDescriptiveDateTitle } from '../../../../utils';
import { FieldWrapper } from '../Account/FieldWrapper';

type DateCellProps = GridRenderCellParams<GridValidRowModel, Date>;
export const DateCell = ({ field, value, rowNode }: DateCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  const { locale } = useUserDateSettings();
  return (
    <FieldWrapper field={field}>
      <Typography variant="body2" title={toDescriptiveDateTitle(value, { locale })}>
        {value != null ? format(value, DATE_FORMAT_LONG_LOCALIZED, { locale }) : missingValue}
      </Typography>
    </FieldWrapper>
  );
};
