import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

import { dialog$ } from '../selectors';

interface DeleteConfirmationDialogProps {
  /**
   * The type of the delete dialog - used to determine the `data-uid` attribute.
   *
   * Leaving unset will result in an `'untyped'` type.
   */
  // TODO: [REEF-1152] add support for the 'list' type
  type?: 'action' | 'objective' | 'filter';
  open: boolean;
  /**
   * Called when the dialog is closed.
   */
  onClose(): void;
  /**
   * Callback to set dialog state after it has been closed to prevent weird data flashing
   * as it continues to close.
   */
  onExited(): void;
  /**
   * Called when the delete is confirmed.
   */
  onDelete(): void;
  title: string | JSX.Element;
  confirmationText: string | JSX.Element;
  /**
   * `true` if the delete is in progress
   */
  loading?: boolean;
}

export const DeleteConfirmationDialog = ({
  type,
  open,
  onClose,
  onExited,
  onDelete,
  title,
  confirmationText,
  loading,
}: DeleteConfirmationDialogProps) => {
  return (
    <Dialog
      data-uid={dialog$.delete.confirmDeleteDialog(type ?? 'untyped')}
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {typeof confirmationText === 'string' ? (
          <DialogContentText>{confirmationText}</DialogContentText>
        ) : (
          confirmationText
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-uid={dialog$.delete.cancelDeleteButton(type ?? 'untyped')}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          data-uid={dialog$.delete.confirmDeleteButton(type ?? 'untyped')}
          variant="contained"
          onClick={onDelete}
          loading={loading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
