import { Avatar, AvatarProps } from '@mui/material';
import React from 'react';

/**
 * Ripped from https://mui.com/material-ui/react-avatar/
 *
 * Converts some unrelated string into a hex color.
 * @param string to be converted
 * @returns hex color value
 */
function stringToColor(string: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

/**
 * Function to get avatar props from a persons name.
 * @param name user's name to get the letters and color for
 * @returns props for the avatar component
 */
function stringAvatar(name: string): AvatarProps {
  return {
    sx: { bgcolor: stringToColor(name) },
    children: `${name.split(' ')[0]?.[0] ?? ''}${name.split(' ')[1]?.[0] ?? ''}`,
  };
}

interface BackgroundLettersAvatarProps extends AvatarProps {
  name: string;
}

export const BackgroundLettersAvatar = ({ name, ...rest }: BackgroundLettersAvatarProps) => {
  return <Avatar {...rest} {...stringAvatar(name)} />;
};
