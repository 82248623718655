import { Alert, AlertTitle, Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  ImpersonationError,
  UnknownClientError,
  UnknownUserError,
  useAuthError,
} from '../../contexts/reefAuthContext';

interface AuthErrorProps {
  username: string;
}
export const AuthError = ({ username }: AuthErrorProps) => {
  const [authError] = useAuthError();

  if (authError == null) return null;
  if (authError instanceof ImpersonationError) {
    return (
      <Alert severity="error">
        <AlertTitle>Failed to impersonate user.</AlertTitle>
        Check to make sure that you entered the impersonating email correctly.
      </Alert>
    );
  }
  if (authError instanceof UnknownClientError) {
    return (
      <Alert severity="error">
        It looks like your company is not registered with Reef.ai.{' '}
        <Link
          component={RouterLink}
          to={`mailto:sales@reef.ai?subject=${encodeURIComponent(`Tell me more about Reef.ai!`)}`}
          target="_blank"
          rel="noopener"
        >
          Reach out to our sales team
        </Link>{' '}
        for more information.
      </Alert>
    );
  }
  if (authError instanceof UnknownUserError) {
    return (
      <Alert severity="error">
        Something went wrong and we were unable to find your user.{' '}
        <Link
          component={RouterLink}
          to={`mailto:support@reef.ai?subject=${encodeURIComponent(
            `Action required | Unable to sign up with email "${username}"`,
          )}`}
          target="_blank"
          rel="noopener"
        >
          Reach out to Reef.ai support
        </Link>{' '}
        to get assistance.
      </Alert>
    );
  }

  return <Alert severity="error">{authError.message}</Alert>;
};
