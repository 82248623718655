import { Email, Logout } from '@mui/icons-material';
import { Box, Link, Skeleton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';

import { CURRENCY_FORMATTER, DATE_FORMAT_LONG_LOCALIZED } from '../../constants';
import { useUserDateSettings } from '../../contexts/userSettingsContext';
import { getAccountBucket } from '../../hooks/useAccountChartConfig';
import { Account, AccountCaseDetails } from '../../models/account';
import { customerDetails$ } from '../../selectors/customerDetails';
import {
  AVERAGE_NUMBER_FORMATTER,
  isSalesforceLink,
  NUMBER_FORMATTER,
  PERCENT_NUMBER_FORMATTER,
  pluralize,
} from '../../utils';
import { ScoreQualityChip } from '../ScoreChip';
import { DynamicScoreChip } from '../ScoreChip/DynamicScoreChip';
import { NullScoreTooltip } from '../Tooltips';
import { ComingSoonFieldItem } from './ComingSoonFieldItem';
import { AccountOverviewField, ComingSoonFields, SupportCasesOverviewField } from './types';

const FieldValueSkeleton = () => <Skeleton variant="text" sx={{ minHeight: 24 }} />;
interface FutureOverviewFieldProps {
  field: ComingSoonFields;
  loading: boolean;
}
interface AccountOverviewFieldProps {
  field: AccountOverviewField;
  account: Account | null;
  onHover?: 'hide-icon';
}
interface SupportCasesOverviewFieldProps {
  field: SupportCasesOverviewField;
  details: AccountCaseDetails | null;
}
type OverviewFieldProps =
  | FutureOverviewFieldProps
  | AccountOverviewFieldProps
  | SupportCasesOverviewFieldProps;
const MissingFieldValue = () => <Typography>&ndash;</Typography>;
export const OverviewFieldItem = (props: OverviewFieldProps): JSX.Element => {
  const { field } = props;
  const hideIcon = 'onHover' in props && props.onHover === 'hide-icon';
  const [hidden, setHidden] = useState(hideIcon);
  const { locale } = useUserDateSettings();
  const onMouseEnter = useCallback(() => (hideIcon ? setHidden(false) : undefined), [hideIcon]);
  const onMouseLeave = useCallback(() => (hideIcon ? setHidden(true) : undefined), [hideIcon]);
  switch (field) {
    case 'executiveSponsor':
    case 'activeSprints':
    case 'start':
    case 'contractSummary':
    case 'pipelineSummary':
      return (
        <ComingSoonFieldItem field={field} loading={'loading' in props ? props.loading : false} />
      );

    case 'crmLink': {
      const crmLinkValue = props.account != null ? props.account[field] : null;
      return (
        <Stack
          data-uid={customerDetails$.overview.field(field)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {props.account ? (
            <Stack direction="row" spacing={1}>
              {crmLinkValue != null ? (
                <>
                  <Link
                    href={crmLinkValue}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>
                        {isSalesforceLink(crmLinkValue) ? 'Salesforce' : 'Profile'}
                      </Typography>
                      <Logout
                        fontSize="small"
                        sx={{
                          transition: (theme) => theme.transitions.create('opacity'),
                          opacity: hidden ? 0 : 1,
                        }}
                      />
                    </Stack>
                  </Link>
                </>
              ) : (
                <MissingFieldValue />
              )}
            </Stack>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">CRM Profile</Typography>
        </Stack>
      );
    }
    case 'ownerName': {
      const ownerValue = props.account != null ? props.account[field] : null;
      return (
        <Stack
          data-uid={customerDetails$.overview.field(field)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {props.account ? (
            <Stack direction="row" spacing={1}>
              {ownerValue != null ? (
                <>
                  <Link
                    href={`mailto:${props.account.ownerEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>{`${props.account[field]}`}</Typography>
                      <Email
                        fontSize="small"
                        sx={{
                          transition: (theme) => theme.transitions.create('opacity'),
                          opacity: hidden ? 0 : 1,
                        }}
                      />
                    </Stack>
                  </Link>
                </>
              ) : (
                <MissingFieldValue />
              )}
            </Stack>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Customer Lead</Typography>
        </Stack>
      );
    }
    case 'renewalOwnerName': {
      const renewalOwnerValue = props.account != null ? props.account[field] : null;
      return (
        <Stack
          data-uid={customerDetails$.overview.field(field)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {props.account ? (
            <Stack direction="row" spacing={1}>
              {renewalOwnerValue != null ? (
                <>
                  <Link
                    href={`mailto:${props.account.renewalOwnerEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>{renewalOwnerValue}</Typography>
                      <Email
                        fontSize="small"
                        sx={{
                          transition: (theme) => theme.transitions.create('opacity'),
                          opacity: hidden ? 0 : 1,
                        }}
                      />
                    </Stack>
                  </Link>
                </>
              ) : (
                <MissingFieldValue />
              )}
            </Stack>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Renewal Lead</Typography>
        </Stack>
      );
    }
    case 'secondaryOwnerName': {
      const secondaryOwnerValue = props.account != null ? props.account[field] : null;
      return (
        <Stack
          data-uid={customerDetails$.overview.field(field)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {props.account ? (
            <Stack direction="row" spacing={1}>
              {secondaryOwnerValue != null ? (
                <>
                  <Link
                    href={`mailto:${props.account.secondaryOwnerEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>{secondaryOwnerValue}</Typography>
                      <Email
                        fontSize="small"
                        sx={{
                          transition: (theme) => theme.transitions.create('opacity'),
                          opacity: hidden ? 0 : 1,
                        }}
                      />
                    </Stack>
                  </Link>
                </>
              ) : (
                <MissingFieldValue />
              )}
            </Stack>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Sales Lead</Typography>
        </Stack>
      );
    }
    case 'engagement': {
      let valueElement = <FieldValueSkeleton />;
      if (props.account != null) {
        const engagementValue = props.account[field];
        const engagementLevel = getAccountBucket(engagementValue?.value ?? null, 'engagement');
        if (engagementLevel != null) {
          valueElement = (
            <Box>
              <ScoreQualityChip
                level={engagementLevel}
                description={
                  <NullScoreTooltip field="engagement" accountName={props.account.name} />
                }
                score={engagementValue?.value ?? undefined}
                scoreVariant="single-score"
              />
            </Box>
          );
        }
      }

      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {valueElement}
          <Typography variant="caption">Engagement Score</Typography>
        </Stack>
      );
    }
    case 'productEngagement': {
      let valueElement = <FieldValueSkeleton />;
      if (props.account != null) {
        const productEngagementValue = props.account != null ? props.account[field] : null;
        const productEngagementLevel = getAccountBucket(
          productEngagementValue?.value ?? null,
          'productEngagement',
        );
        if (productEngagementLevel != null) {
          valueElement = (
            <Box>
              <ScoreQualityChip
                level={productEngagementLevel}
                description={
                  <NullScoreTooltip field="productEngagement" accountName={props.account.name} />
                }
                score={productEngagementValue?.value ?? undefined}
                scoreVariant="single-score"
              />
            </Box>
          );
        }
      }

      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {valueElement}
          <Typography variant="caption">Product Score</Typography>
        </Stack>
      );
    }
    case 'supportEngagement': {
      let valueElement = <FieldValueSkeleton />;
      const supportEngagementSource =
        'details' in props ? props.details : 'account' in props ? props.account : null;
      const supportEngagementValue = supportEngagementSource?.supportEngagement?.value;
      const supportEngagementLevel =
        supportEngagementSource != null
          ? getAccountBucket(supportEngagementValue ?? null, 'supportEngagement')
          : null;

      if (supportEngagementSource != null && supportEngagementLevel != null) {
        valueElement = (
          <Box>
            <ScoreQualityChip
              level={supportEngagementLevel}
              description={
                <NullScoreTooltip
                  field="supportEngagement"
                  accountName={supportEngagementSource?.name}
                />
              }
              score={supportEngagementValue}
              scoreVariant="single-score"
            />
          </Box>
        );
      }

      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {valueElement}
          <Typography variant="caption">Support Score</Typography>
        </Stack>
      );
    }
    case 'supportCaseCount': {
      const supportCaseCountSource =
        'details' in props ? props.details : 'account' in props ? props.account : null;
      const supportCaseCountValue =
        supportCaseCountSource != null ? supportCaseCountSource.supportCaseCount : null;
      const supportCaseTotalValue =
        supportCaseCountSource != null ? supportCaseCountSource.supportCaseTotal : null;
      const title =
        supportCaseTotalValue != null
          ? `${PERCENT_NUMBER_FORMATTER.format(
              (supportCaseCountValue ?? 0) / supportCaseTotalValue,
            )} Open, ${PERCENT_NUMBER_FORMATTER.format(
              1 - (supportCaseCountValue ?? 0) / supportCaseTotalValue,
            )} Closed`
          : undefined;
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {supportCaseCountSource ? (
            <Typography title={title}>
              {supportCaseCountValue != null ? (
                NUMBER_FORMATTER.format(supportCaseCountValue)
              ) : (
                <>&ndash;</>
              )}
              {' / '}
              {supportCaseTotalValue != null ? (
                NUMBER_FORMATTER.format(supportCaseTotalValue)
              ) : (
                <>&ndash;</>
              )}
            </Typography>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Open Cases / Total Cases</Typography>
        </Stack>
      );
    }
    case 'supportCaseOpenDuration': {
      const supportCaseOpenDurationSource =
        'details' in props ? props.details : 'account' in props ? props.account : null;
      const supportCaseOpenDurationValue =
        supportCaseOpenDurationSource != null ? supportCaseOpenDurationSource[field] : null;
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {supportCaseOpenDurationSource ? (
            <Typography>
              {supportCaseOpenDurationValue != null ? (
                `${AVERAGE_NUMBER_FORMATTER.format(supportCaseOpenDurationValue)} ${pluralize({
                  count: supportCaseOpenDurationValue,
                  singular: 'Day',
                })}`
              ) : (
                <>&ndash;</>
              )}
            </Typography>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Open Cases Avg. Age</Typography>
        </Stack>
      );
    }
    case 'supportCaseClosedDuration': {
      const supportCaseClosedDurationSource =
        'details' in props ? props.details : 'account' in props ? props.account : null;
      const supportCaseClosedDurationValue =
        supportCaseClosedDurationSource != null ? supportCaseClosedDurationSource[field] : null;
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {supportCaseClosedDurationSource ? (
            <Typography>
              {supportCaseClosedDurationValue != null ? (
                `${AVERAGE_NUMBER_FORMATTER.format(supportCaseClosedDurationValue)} ${pluralize({
                  count: supportCaseClosedDurationValue,
                  singular: 'Day',
                })}`
              ) : (
                <>&ndash;</>
              )}
            </Typography>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Avg. Case Duration</Typography>
        </Stack>
      );
    }
    case 'customHealthscore': {
      let valueElement = <FieldValueSkeleton />;
      if (props.account != null && props.account.customHealthscore != null) {
        valueElement =
          props.account.customHealthscore.category != null ? (
            <DynamicScoreChip value={props.account.customHealthscore.category} />
          ) : (
            <>&ndash;</>
          );
      }
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {valueElement}
          <Typography variant="caption">Health Score</Typography>
        </Stack>
      );
    }
    case 'customerRevenueBand': {
      let valueElement = <FieldValueSkeleton />;
      if (props.account != null && props.account.customerRevenueBand != null) {
        valueElement =
          props.account.customerRevenueBand.category != null ? (
            <DynamicScoreChip value={props.account.customerRevenueBand.category} />
          ) : (
            <>&ndash;</>
          );
      }
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {valueElement}
          <Typography variant="caption">Revenue Band</Typography>
        </Stack>
      );
    }
    case 'potential': {
      let valueElement = <FieldValueSkeleton />;
      if (props.account != null) {
        const potentialValue = props.account != null ? props.account[field] : null;
        const potentialLevel = getAccountBucket(potentialValue?.value ?? null, 'potential');
        if (potentialLevel != null) {
          valueElement = (
            <Box>
              <ScoreQualityChip
                level={potentialLevel}
                description={
                  <NullScoreTooltip field="potential" accountName={props.account.name} />
                }
                score={potentialValue?.value ?? undefined}
                scoreVariant="single-score"
              />
            </Box>
          );
        }
      }

      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {valueElement}
          <Typography variant="caption">Potential Score</Typography>
        </Stack>
      );
    }
    case 'renewalDate': {
      const dateValue = props.account != null ? props.account[field] : null;
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {props.account ? (
            <Typography>
              {dateValue != null ? (
                format(dateValue, DATE_FORMAT_LONG_LOCALIZED, { locale })
              ) : (
                <>&ndash;</>
              )}
            </Typography>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Next Renewal Date</Typography>
        </Stack>
      );
    }
    case 'currentArr': {
      const arrValue = props.account != null ? props.account[field] : null;
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {props.account ? (
            <Typography>
              {arrValue != null ? CURRENCY_FORMATTER.format(arrValue) : <>&ndash;</>}
            </Typography>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Current ARR</Typography>
        </Stack>
      );
    }
    case 'rawPipeline': {
      const pipelineValue = props.account != null ? props.account[field] : null;
      return (
        <Stack data-uid={customerDetails$.overview.field(field)}>
          {props.account ? (
            <Typography>
              {pipelineValue != null ? CURRENCY_FORMATTER.format(pipelineValue) : <>&ndash;</>}
            </Typography>
          ) : (
            <FieldValueSkeleton />
          )}
          <Typography variant="caption">Total Pipeline</Typography>
        </Stack>
      );
    }
    default:
      console.error(`Missing overview field rendering for: ${field}`);
      return <></>;
  }
};
