import { Box, BoxProps } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnHeaders,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import React, { memo, PropsWithChildren, useMemo } from 'react';

import { table$, tooltip$ } from '../../../selectors';
import { GroupedContentFooter } from './Common';

interface GridWrapperBoxProps {
  outer?: BoxProps;
  inner?: BoxProps;
  auto?: BoxProps;
}
export interface CustomGridProps<T extends GridValidRowModel> extends DataGridPremiumProps<T> {
  GridWrapperBoxProps?: GridWrapperBoxProps;
}

interface GridWrapperProps<T extends GridValidRowModel>
  extends PropsWithChildren<Pick<CustomGridProps<T>, 'autoHeight'>> {
  GridWrapperBoxProps?: GridWrapperBoxProps;
}

// https://mui.com/x/react-data-grid/performance/#memoize-inner-components-with-react-memo
const MemoizedColumnHeaders = memo(GridColumnHeaders);
const MemoizedGroupedContentFooter = memo(GroupedContentFooter);

const GridWrapper = <T extends GridValidRowModel>({
  autoHeight,
  GridWrapperBoxProps = { outer: {}, inner: {}, auto: {} },
  children,
}: GridWrapperProps<T>) =>
  autoHeight ? (
    <Box
      {...GridWrapperBoxProps.auto}
      sx={{ width: '100%', minHeight: '3rem', ...GridWrapperBoxProps.auto?.sx }}
    >
      {children}
    </Box>
  ) : (
    <Box
      {...GridWrapperBoxProps.outer}
      sx={{
        height: `calc(100vh - 10.5rem)`,
        width: '100%',
        minHeight: '3rem',
        ...GridWrapperBoxProps.outer?.sx,
      }}
    >
      <Box
        {...GridWrapperBoxProps.inner}
        sx={{ display: 'flex', height: '100%', ...GridWrapperBoxProps.inner?.sx }}
      >
        <Box sx={{ flexGrow: 1, width: '100%' }}>{children}</Box>
      </Box>
    </Box>
  );

export const CustomGrid = <T extends GridValidRowModel>({
  slotProps: initialSlotProps,
  GridWrapperBoxProps,
  slots: initialSlots,
  children,
  sx: initialSx,
  ...rest
}: PropsWithChildren<CustomGridProps<T>>) => {
  const slots = useMemo(
    (): DataGridPremiumProps['slots'] => ({
      ...initialSlots,
      columnHeaders: MemoizedColumnHeaders,
      footer: MemoizedGroupedContentFooter,
    }),
    [initialSlots],
  );
  const slotProps = useMemo(
    (): DataGridPremiumProps['slotProps'] => ({
      ...initialSlotProps,
      row: {
        ...initialSlotProps?.row,
        'data-uid': table$.tableRow,
      },
      cell: {
        ...initialSlotProps?.cell,
        'data-uid': table$.rowCell,
      },
      baseCheckbox: {
        ...initialSlotProps?.baseCheckbox,
        'data-uid': table$.selectBtn,
      },
      baseTooltip: {
        ...initialSlotProps?.baseTooltip,
        'data-uid': tooltip$.customGridTooltip,
      },
      toolbar: {
        ...initialSlotProps?.toolbar,
        'data-uid': table$.tableHeader,
      },
      footer: {
        ...initialSlotProps?.footer,
        'data-uid': table$.tableFooter,
        apiRef: rest.apiRef,
      },
      basePopper: {
        ...initialSlotProps?.basePopper,
        'data-uid': table$.tablePopper,
      },
    }),
    [rest.apiRef, initialSlotProps],
  );
  const sx = useMemo(
    (): DataGridPremiumProps['sx'] => ({
      '.MuiDataGrid-footerContainer': {
        minHeight: '2rem',
      },
      ...initialSx,
    }),
    [initialSx],
  );
  return (
    <GridWrapper autoHeight={rest.autoHeight} GridWrapperBoxProps={GridWrapperBoxProps}>
      {children}
      <DataGridPremium slots={slots} slotProps={slotProps} sx={sx} {...rest} />
    </GridWrapper>
  );
};
