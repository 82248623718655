import { ChartTypeRegistry, TickOptions, TooltipItem, TooltipOptions } from 'chart.js';
import { merge, times } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ChartProps } from 'react-chartjs-2';

import { RENEWAL_ANALYTICS_COLORS } from '../components/Charts/Analytics/utils';
import { barYTicksHandler } from '../components/Charts/BarChart/barChartUtils';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import { ExpiringSegments, Renewal, RenewedSegments } from './renewal';

const tooltipTitleHandler = (tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]) => {
  // assume the first label of every dataset is reliable, if present
  return tooltipItems.at(0)?.dataset?.label || '';
};
export const baseWaterfallChartOptions: ChartProps = {
  data: {
    datasets: [],
  },
  type: 'bar',
  options: {
    layout: {
      padding: 16,
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // skip tooltips for Plan line points
        filter: ({ parsed: { _custom } }) =>
          _custom !== null && _custom !== undefined && Object.keys(_custom).length > 0,
        callbacks: {
          title: tooltipTitleHandler,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        position: 'right',
      },
    },
  },
};
interface UseRenewalOverviewChartConfigProps {
  renewals: Renewal[];
}
export const useRenewalOverviewChartConfig = ({
  renewals,
}: UseRenewalOverviewChartConfigProps): ChartProps => {
  const { currencyFormatter, formatCurrencyShort } = useUserSettingsContext();
  const label = useCallback<TooltipOptions['callbacks']['label']>(
    (barItem) => {
      // default to empty label for custom interaction mode
      let label = '';

      if (barItem.parsed.y !== null) {
        const { barEnd, barStart } = barItem.parsed._custom;
        label += currencyFormatter.format(barEnd - barStart);
      }
      return label;
    },
    [currencyFormatter],
  );
  const callback = useCallback<TickOptions['callback']>(
    (value) => barYTicksHandler(value, formatCurrencyShort),
    [formatCurrencyShort],
  );
  const totals = useMemo(
    () =>
      renewals.reduce(
        (acc, c) => ({
          ...acc,
          expiring: {
            ...acc.expiring,
            Churn: c.analytics.expiring.Churn + acc.expiring.Churn,
            Pending: c.analytics.expiring.Pending + acc.expiring.Pending,
            Renewed: c.analytics.expiring.Renewed + acc.expiring.Renewed,
          },
          renewed: {
            ...acc.renewed,
            Renewed: c.analytics.renewed.Renewed + acc.renewed.Renewed,
            Upsell: c.analytics.renewed.Upsell + acc.renewed.Upsell,
          },
        }),
        {
          expiring: {
            [ExpiringSegments.Churn]: 0,
            [ExpiringSegments.Pending]: 0,
            [ExpiringSegments.Renewed]: 0,
          },
          renewed: {
            [RenewedSegments.Renewed]: 0,
            [RenewedSegments.Upsell]: 0,
          },
        },
      ),
    [renewals],
  );
  const data: ChartProps['data'] = useMemo(
    () => ({
      labels: ['Total Expiring', 'Renewed', 'Pending', 'Churn', 'Upsell', 'Total Renewed'],
      datasets: [
        {
          label: 'Total Expiring',
          data: [
            [
              0,
              totals.renewed[ExpiringSegments.Renewed] +
                totals.expiring[ExpiringSegments.Pending] +
                totals.expiring[ExpiringSegments.Churn],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.TotalExpiring,
        },
        {
          label: 'Renewed',
          data: [null, [0, totals.renewed[RenewedSegments.Renewed]]],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Renewed,
        },
        {
          label: 'Pending',
          data: [
            null,
            null,
            [
              totals.renewed[RenewedSegments.Renewed],
              totals.renewed[RenewedSegments.Renewed] + totals.expiring[ExpiringSegments.Pending],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Pending,
        },
        {
          label: 'Churn',
          data: [
            null,
            null,
            null,
            [
              totals.renewed[RenewedSegments.Renewed] + totals.expiring[ExpiringSegments.Pending],
              totals.renewed[RenewedSegments.Renewed] +
                totals.expiring[ExpiringSegments.Pending] +
                totals.expiring[ExpiringSegments.Churn],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Churn,
        },
        {
          label: 'Upsell',
          data: [
            null,
            null,
            null,
            null,
            [
              totals.renewed[RenewedSegments.Renewed] +
                totals.expiring[ExpiringSegments.Pending] +
                totals.expiring[ExpiringSegments.Churn],
              totals.renewed[RenewedSegments.Renewed] +
                totals.expiring[ExpiringSegments.Pending] +
                totals.expiring[ExpiringSegments.Churn] +
                totals.renewed[RenewedSegments.Upsell],
            ],
            [
              totals.renewed[RenewedSegments.Renewed] +
                totals.expiring[ExpiringSegments.Pending] +
                totals.expiring[ExpiringSegments.Churn],
              totals.renewed[RenewedSegments.Renewed] +
                totals.expiring[ExpiringSegments.Pending] +
                totals.expiring[ExpiringSegments.Churn] +
                totals.renewed[RenewedSegments.Upsell],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Upsell,
        },
        {
          label: 'Total Renewed',
          data: [null, null, null, null, null, [0, totals.renewed[RenewedSegments.Renewed]]],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.TotalRenewed,
        },
        {
          label: 'Plan',
          data: times(
            6,
            () =>
              totals.expiring[ExpiringSegments.Renewed] +
              totals.expiring[ExpiringSegments.Pending] +
              totals.expiring[ExpiringSegments.Churn],
          ),
          borderColor: 'rgba(0, 0, 0, 0.87);',
          pointRadius: 0,
          showLine: true,
          segment: {
            borderDash: [3, 2],
            borderWidth: 0.65,
          },
          borderDashOffset: 5,
          type: 'line',
        },
      ],
    }),
    [totals],
  );

  return {
    ...merge({}, baseWaterfallChartOptions, {
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label,
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback,
            },
          },
        },
      },
    }),
    data,
  };
};
