import { useMutation } from '@apollo/client';
import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { CURRENCY_SYMBOLS } from '../../../../constants';
import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import {
  UpdateUserCustomerRegionSettingDocument,
  UpdateUserDefaultCurrencyCodeDocument,
} from '../../../../graphql/generated';
import { useClientConfig } from '../../../../hooks/client';
import { useCurrentUser, useCurrentUserDetails } from '../../../../hooks/user';
import { ResultType } from '../../../../models/result';
import { profile$ } from '../../../../selectors/profile';
import { UserField } from './UserField';

export const Profile = () => {
  const currentUserResult = useCurrentUser();
  const {
    authProvider,
    user: { name, defaultCurrencyCode, customerRegionSetting },
  } = useCurrentUserDetails();
  const [{ currencyCodes, defaultBusinessCurrencyCode, customerRegions }, clientConfigLoading] =
    useClientConfig();
  const { isImpersonating, showDemoData, setShowDemoData } = useUserSettingsContext();

  // currency code state and helpers
  const [isCurrencyInEditMode, setIsCurrencyInEditMode] = useState<boolean>(false);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string>('USD');
  const [updateDefaultCurrencyCode, currencyResult] = useMutation(
    UpdateUserDefaultCurrencyCodeDocument,
  );
  const saveDefaultCurrencyCode = useCallback(
    (code: string) => updateDefaultCurrencyCode({ variables: { defaultCurrencyCode: code } }),
    [updateDefaultCurrencyCode],
  );
  useEffect(
    () => setSelectedCurrencyCode(defaultCurrencyCode ?? defaultBusinessCurrencyCode),
    [defaultCurrencyCode, defaultBusinessCurrencyCode],
  );

  // region state and helpers
  const [isRegionInEditMode, setIsRegionInEditMode] = useState<boolean>(false);
  const [selectedCustomerRegion, setSelectedCustomerRegion] = useState<string | null>(null);
  const [updateCustomerRegionSetting, regionResult] = useMutation(
    UpdateUserCustomerRegionSettingDocument,
  );
  const saveCustomerRegionSetting = useCallback(
    (customerRegionSetting: string | null) =>
      updateCustomerRegionSetting({ variables: { customerRegionSetting } }),
    [updateCustomerRegionSetting],
  );
  useEffect(() => setSelectedCustomerRegion(customerRegionSetting), [customerRegionSetting]);

  const [isDemoInEditMode, setIsDemoInEditMode] = useState<boolean>(false);
  const [selectedShowDemoData, setSelectedShowDemoData] = useState<boolean | null>(null);
  useEffect(() => setSelectedShowDemoData(showDemoData), [showDemoData]);

  const formattedDisplayCurrencyCode = (code: string) =>
    `${code} ${code in CURRENCY_SYMBOLS ? CURRENCY_SYMBOLS[code] : ''}`;

  const currencySelect = (
    <FormControl>
      <Select
        data-uid={profile$.currencySelect}
        labelId="user-default-currency-code-select-label"
        id="user-default-currency-code-select"
        value={selectedCurrencyCode}
        onChange={(event: SelectChangeEvent) => setSelectedCurrencyCode(event.target.value)}
        size="small"
      >
        {currencyCodes &&
          defaultBusinessCurrencyCode &&
          currencyCodes.map((code: string) => (
            <MenuItem data-uid={profile$.currencySelectOption(code)} value={code} key={code}>
              {formattedDisplayCurrencyCode(code)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );

  return (
    <Container sx={{ my: 4, p: 0, mx: 3 }}>
      <UserField field="name" value={name} />
      <Divider />
      <UserField field="auth" value={authProvider} />
      <Divider sx={{ height: 25, border: 'none' }} />
      <UserField
        field="currency"
        loading={clientConfigLoading || currencyResult.loading}
        value={formattedDisplayCurrencyCode(selectedCurrencyCode)}
        editableFieldComponent={currencySelect}
        isInEditMode={isCurrencyInEditMode}
        onEdit={() => setIsCurrencyInEditMode(true)}
        onSave={async () => {
          await saveDefaultCurrencyCode(selectedCurrencyCode);
          setIsCurrencyInEditMode(false);
        }}
      />
      <Divider sx={{ height: 25, border: 'none' }} />
      {customerRegions && (
        <UserField
          field="region"
          loading={clientConfigLoading || regionResult.loading}
          value={customerRegionSetting ?? 'All regions'}
          editableFieldComponent={
            <FormControl>
              <Select
                data-uid={profile$.regionSelect}
                labelId="user-customer-region-select-label"
                id="user-customer-region-select"
                size="small"
                displayEmpty
                placeholder="Customer Region"
                value={selectedCustomerRegion ?? ''}
                onChange={(event) =>
                  setSelectedCustomerRegion(event.target.value !== '' ? event.target.value : null)
                }
              >
                <MenuItem data-uid={profile$.regionSelectOption('all')} value="">
                  <em>All regions</em>
                </MenuItem>
                {customerRegions.map((region: string) => (
                  <MenuItem
                    data-uid={profile$.regionSelectOption(region)}
                    value={region}
                    key={region}
                  >
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          isInEditMode={isRegionInEditMode}
          onEdit={() => setIsRegionInEditMode(true)}
          onSave={async () => {
            await saveCustomerRegionSetting(selectedCustomerRegion);
            setIsRegionInEditMode(false);
          }}
          onCancel={() => setIsRegionInEditMode(false)}
        />
      )}
      {isImpersonating ? (
        <>
          <Divider sx={{ height: 25, border: 'none' }} />
          <UserField
            field="demo"
            loading={currentUserResult.state === ResultType.Loading}
            value={showDemoData ? 'Showing Demo Data' : 'Showing Real Data'}
            editableFieldComponent={
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selectedShowDemoData ?? undefined}
                        onChange={(event) => setSelectedShowDemoData(event.target.checked)}
                        name="show-demo-data"
                      />
                    }
                    label={selectedShowDemoData ? 'Showing Demo Data' : 'Showing Real Data'}
                  />
                </FormGroup>
              </FormControl>
            }
            isInEditMode={isDemoInEditMode}
            onEdit={() => setIsDemoInEditMode(true)}
            onSave={async () => {
              setShowDemoData(selectedShowDemoData ?? false);
              setIsDemoInEditMode(false);
            }}
            onCancel={() => {
              setSelectedShowDemoData(showDemoData);
              setIsDemoInEditMode(false);
            }}
          />
        </>
      ) : null}
    </Container>
  );
};
