import { Add as AddIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useState } from 'react';

import { AccountFilterType, RenewalFiscalQuarterFilter } from '../../../../models/filters';
import { filterCustomers$ } from '../../../../selectors';
import { FilterStack } from '../FilterStack';

interface RenewalQuarterFilterInputProps {
  filter: RenewalFiscalQuarterFilter | undefined;
  yearOptions: number[];
  disabled?: boolean;
  loading: boolean;
  onChange(filters: Omit<RenewalFiscalQuarterFilter, 'id'>[]): void;
}
export const RenewalQuarterFilterInput = ({
  filter,
  yearOptions,
  disabled,
  loading,
  onChange,
}: RenewalQuarterFilterInputProps) => {
  const [year, setYear] = useState<number>();
  const [quarter, setQuarter] = useState<1 | 2 | 3 | 4>();
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} useFlexGap>
        <FormControl fullWidth size="small" disabled={disabled}>
          <InputLabel id="fiscal-year-label">Fiscal Year</InputLabel>
          <Select
            data-uid={filterCustomers$.editFilter.fiscalQuarter.yearSelect}
            labelId="fiscal-year-label"
            label="Fiscal Year"
            value={year ?? ''}
            onChange={(e) => e instanceof Event && setYear(e.target.value)}
          >
            {yearOptions.map((year) => (
              <MenuItem
                key={year}
                data-uid={filterCustomers$.editFilter.fiscalQuarter.yearOption(year)}
                value={year}
              >
                FY{year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" disabled={disabled}>
          <InputLabel id="fiscal-quarter-label">Fiscal Quarter</InputLabel>
          <Select
            data-uid={filterCustomers$.editFilter.fiscalQuarter.quarterSelect}
            labelId="fiscal-quarter-label"
            label="Fiscal Quarter"
            value={quarter ?? 'any-quarter'}
            onChange={(e) =>
              e instanceof Event &&
              setQuarter(typeof e.target.value == 'number' ? e.target.value : undefined)
            }
          >
            <MenuItem value="any-quarter">All</MenuItem>
            <MenuItem
              value={1}
              data-uid={filterCustomers$.editFilter.fiscalQuarter.quarterOption(1)}
            >
              Q1
            </MenuItem>
            <MenuItem
              value={2}
              data-uid={filterCustomers$.editFilter.fiscalQuarter.quarterOption(2)}
            >
              Q2
            </MenuItem>
            <MenuItem
              value={3}
              data-uid={filterCustomers$.editFilter.fiscalQuarter.quarterOption(3)}
            >
              Q3
            </MenuItem>
            <MenuItem
              value={4}
              data-uid={filterCustomers$.editFilter.fiscalQuarter.quarterOption(4)}
            >
              Q4
            </MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          data-uid={filterCustomers$.editFilter.fiscalQuarter.saveBtn}
          sx={{ my: 'auto' }}
          variant="contained"
          size="small"
          color="primary"
          disabled={disabled || year == null}
          startIcon={<AddIcon />}
          onClick={() => {
            if (year != null) {
              onChange([{ type: AccountFilterType.RenewalFiscalQuarter, year, quarter }]);
              setYear(undefined);
              setQuarter(undefined);
            }
          }}
          loading={loading}
        >
          Add
        </LoadingButton>
      </Stack>
      <FilterStack filters={filter != null ? [filter] : []} onDeleteFilter={() => onChange([])} />
    </Stack>
  );
};
