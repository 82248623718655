import { Alert, Container, LinearProgress } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useClientAccounts, useObjectiveActions, useObjectives } from '../../../hooks/client';
import { useRecommendation } from '../../../hooks/recommendation';
import { Account } from '../../../models/account';
import { ResultType } from '../../../models/result';
import { Action, DraftActivity } from '../../../models/sprint';
import { asUUID, UUID } from '../../../models/uuid';
import { sprint$ } from '../../../selectors';
import { isNonNull } from '../../../utils';
import { SprintCreation } from './SprintCreation';

const buildActivity = (account: Account, action: Action): DraftActivity => ({ account, action });

export type CreateSprintVariantType = 'create' | 'capture';

interface CreateSprintProps {
  variant?: CreateSprintVariantType;
}

export const CreateSprint = ({ variant = 'create' }: CreateSprintProps) => {
  const [params] = useSearchParams();
  const playbookId = params.get('playbook');
  const recParam = params.get('recId');
  const nav = useNavigate();
  /**
   * NOTE: recommendation ID, to be used with sprint + recommendation updates
   */
  const recId = recParam != null ? asUUID(recParam) : null;

  // FIXME: scope querying
  const playbooksResult = useObjectives({});
  const accountsResult = useClientAccounts();
  const actionsResult = useObjectiveActions(
    playbookId != null
      ? {
          objectiveId: asUUID(playbookId),
        }
      : { skip: true },
  );
  const recResult = useRecommendation(recId);

  const playbook = useMemo(
    () =>
      playbooksResult.state === ResultType.Value
        ? (playbooksResult.value.find((pb) => pb.id === playbookId) ?? null)
        : null,
    [playbookId, playbooksResult.state, playbooksResult.value],
  );
  const playbookActions = useMemo(
    () => (actionsResult.state === ResultType.Value ? actionsResult.value : null),
    [actionsResult.state, actionsResult.value],
  );

  const accountsById = useMemo(() => {
    if (accountsResult.state === ResultType.Value) {
      return accountsResult.value.reduce<Record<UUID, Account>>(
        (acc, account) => ({ ...acc, [account.id]: account }),
        {},
      );
    }
    return {};
  }, [accountsResult.state, accountsResult.value]);
  const accounts = useMemo(() => {
    const accountIds = params.getAll('accounts');
    if (accountsResult.state === ResultType.Value) {
      const matchingAccounts = accountIds.map((id) => accountsById[asUUID(id)]).filter(isNonNull);
      return matchingAccounts.length > 0 ? matchingAccounts : null;
    }
    return null;
  }, [accountsById, accountsResult.state, params]);

  const activities = useMemo(() => {
    if (playbookActions != null && accounts != null) {
      return accounts.reduce<DraftActivity[]>(
        (list, account) => [
          ...list,
          ...playbookActions.map((action) => buildActivity(account, action)),
        ],
        [],
      );
    }
    return [];
  }, [accounts, playbookActions]);

  const recommendation = useMemo(
    () => (recResult.state === ResultType.Value ? recResult.value : null),
    [recResult.state, recResult.value],
  );

  useEffect(() => {
    if (recommendation?.sprint) {
      nav(`/sprint/${recommendation.sprint.id}`);
    }
  }, [recommendation, nav]);

  if (playbooksResult.state === ResultType.Loading) {
    return (
      <Container>
        <LinearProgress />
        <Alert data-uid={sprint$.creation.loadingAlert} severity="info">
          Loading your Playbooks...
        </Alert>
      </Container>
    );
  }

  if (accountsResult.state === ResultType.Loading) {
    return (
      <Container>
        <LinearProgress />
        <Alert data-uid={sprint$.creation.loadingAlert} severity="info">
          Loading your Accounts...
        </Alert>
      </Container>
    );
  }

  if (actionsResult.state === ResultType.Loading) {
    return (
      <Container>
        <LinearProgress />
        <Alert data-uid={sprint$.creation.loadingAlert} severity="info">
          Loading your Playbook Actions...
        </Alert>
      </Container>
    );
  }

  if (recResult.state === ResultType.Loading) {
    return (
      <Container>
        <LinearProgress />
        <Alert data-uid={sprint$.creation.loadingAlert} severity="info">
          Loading your Recommendation...
        </Alert>
      </Container>
    );
  }

  if (playbook == null) {
    return (
      <Container>
        <Alert data-uid={sprint$.creation.errorAlert} severity="error">
          Unable to find the requested playbook {playbookId}
        </Alert>
      </Container>
    );
  }

  if (playbookActions == null) {
    return (
      <Container>
        <Alert data-uid={sprint$.creation.errorAlert} severity="error">
          Unable to find the requested playbook {playbookId} actions
        </Alert>
      </Container>
    );
  }

  if (accounts == null) {
    return (
      <Container>
        <Alert data-uid={sprint$.creation.errorAlert} severity="error">
          Unable to find the requested account(s) {params.getAll('accounts').join(', ')}
        </Alert>
      </Container>
    );
  }

  if (recId != null && recommendation == null) {
    return (
      <Container>
        <Alert data-uid={sprint$.creation.errorAlert} severity="error">
          Unable to find the requested recommendation {recId}
        </Alert>
      </Container>
    );
  }

  return (
    <SprintCreation
      accounts={accounts}
      activities={activities}
      playbook={playbook}
      playbookActions={playbookActions}
      recId={recId}
      end={params.get('end') ?? ''}
      endOffset={params.get('endOffset') ?? ''}
      name={params.get('name') ?? ''}
      start={params.get('start') ?? ''}
      variant={variant}
    />
  );
};
