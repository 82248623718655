import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  CircularProgress,
  ListItem,
  ListItemText,
  Popper,
  PopperProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useFindUsers, User } from '../../hooks/user';
import { ResultType } from '../../models/result';

type NonRequiredFields = 'options' | 'renderInput';
interface UserAutocompleteProps<T>
  extends Omit<AutocompleteProps<T, false, boolean, false>, NonRequiredFields> {
  loading?: boolean;
  dataUid: string;
  handleOnUserSelect: (selectedUser: User) => void;
  renderInputParams?: AutocompleteRenderInputParams | TextFieldProps;
}
export const UserAutocomplete = ({
  dataUid,
  loading,
  value,
  handleOnUserSelect,
  renderInputParams,
  ...rest
}: UserAutocompleteProps<User>) => {
  const [findUsers, usersResult] = useFindUsers();

  const isLoading = useMemo(
    () => usersResult.state === ResultType.Loading || loading,
    [loading, usersResult.state],
  );

  const AutocompletePopper = useCallback(
    (props: PopperProps) => <Popper {...props} data-uid={`${dataUid}-popper`} />,
    [dataUid],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        placeholder="Unassigned"
        {...params}
        {...renderInputParams}
        InputProps={{
          ...params.InputProps,
          ...renderInputParams?.InputProps,
          endAdornment: (
            <>
              {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [isLoading, renderInputParams],
  );
  const renderOption = useCallback(
    (liProps: React.HTMLAttributes<HTMLLIElement>, user: User) => (
      <ListItem {...liProps} key={user.id}>
        <ListItemText primary={user.name} secondary={user.statusDescription} />
      </ListItem>
    ),
    [],
  );

  const debouncedFindUsers = useMemo(() => debounce(findUsers, 200), [findUsers]);

  const options = useMemo((): Readonly<User[]> => {
    if (usersResult.state === ResultType.Value) {
      return usersResult.value;
    }
    return [];
  }, [usersResult]);

  // cancel the debounce calls when the component is unmounted
  useEffect(() => {
    return () => debouncedFindUsers.cancel();
  }, [debouncedFindUsers]);

  return (
    <Autocomplete
      data-uid={dataUid}
      size="small"
      fullWidth
      // use `null` so the input stays "controlled"
      value={value ?? null}
      options={options}
      noOptionsText="No users"
      loading={isLoading}
      // conditionally set `disableClearable`. if disable clearable is `true`, then the `value`
      // prop's type is non-nullable
      disableClearable={value != null}
      // wake up the query result with an empty search when it's opened
      // this will return all of the users
      onOpen={() => debouncedFindUsers({ variables: { searchTerm: '' } })}
      blurOnSelect
      // providing this prevents warnings in the console
      isOptionEqualToValue={(option, value) => option.id === value.id}
      // getOptionDisabled={(user) => !user.isValidAssignee}
      getOptionLabel={(user) => user.name}
      // followed the recommendations in the mui autocomplete docs for
      // search-as-you-type autocomplete props
      // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
      filterOptions={(allOptions) => allOptions}
      onChange={(_, newValue, reason) => {
        // this is only nullable because disableClearable is conditionally true
        // if _all_ activities had an assignee, then we could set disableClearable to
        // true and value's type would change to NonNullable<T>
        // TODO: [REEF-1277] backfill assignee field
        newValue != null &&
          // also, only want to handle the "selectOption" reason because autocomplete tries to
          // trigger onChange when "blur" is called too
          reason === 'selectOption' &&
          // selecting the same entity doesn't trigger an onChange
          value?.id !== newValue.id &&
          handleOnUserSelect(newValue);
      }}
      onInputChange={(_, searchTerm, reason) =>
        // we don't want to do any querying when the field is 'reset'
        // this will happen when the result of the assignee updates from a loading to value state
        reason !== 'reset' && debouncedFindUsers({ variables: { searchTerm } })
      }
      PopperComponent={AutocompletePopper}
      renderInput={renderInput}
      renderOption={renderOption}
      {...rest}
    />
  );
};
