import { Help } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { useUserSettingsContext } from '../../contexts/userSettingsContext';
import { AccountHeatmapConfig } from '../../hooks/useAccountChartConfig';
import { Account } from '../../models/account';
import { heatmap$, tooltip$ } from '../../selectors';
import { HeatmapVariation } from '../../types/heatmap';
import { NUMBER_FORMATTER } from '../../utils';
import { HeatmapSettingsDrawer } from './HeatmapSettingsDrawer';

interface HeatmapOverviewProps {
  heatmapConfig: AccountHeatmapConfig;
  heatmapVariation: HeatmapVariation;
  accounts: Account[];
  onSelectHeatmapVariation(variant: HeatmapVariation): void;
}
export const HeatmapOverview = ({
  heatmapConfig,
  heatmapVariation,
  accounts,
  onSelectHeatmapVariation,
}: HeatmapOverviewProps) => {
  const { formatCurrencyShort } = useUserSettingsContext();
  const accountTotals = useMemo(
    () =>
      accounts.reduce(
        (acc, account) => ({
          num: acc.num + 1,
          arr: acc.arr + (account.currentArr ?? 0),
          pipeline: acc.pipeline + (account.rawPipeline ?? 0),
        }),
        {
          num: 0,
          arr: 0,
          pipeline: 0,
        },
      ),
    [accounts],
  );
  const formattedSummaryValues = useMemo(
    () => ({
      numSelected: {
        label: heatmapConfig.selectionSummary.numAccounts,
        title: NUMBER_FORMATTER.format(heatmapConfig.selectionSummary.numAccounts),
      },
      numTotal: {
        label: accountTotals.num,
        title: NUMBER_FORMATTER.format(accountTotals.num),
      },
      arrSelected: {
        label: formatCurrencyShort(heatmapConfig.selectionSummary.currentArr),
        title: NUMBER_FORMATTER.format(heatmapConfig.selectionSummary.currentArr),
      },
      arrTotal: {
        label: formatCurrencyShort(accountTotals.arr),
        title: NUMBER_FORMATTER.format(accountTotals.arr),
      },
      pipelineSelected: {
        label: formatCurrencyShort(heatmapConfig.selectionSummary.pipeline),
        title: NUMBER_FORMATTER.format(heatmapConfig.selectionSummary.pipeline),
      },
      pipelineTotal: {
        label: formatCurrencyShort(accountTotals.pipeline),
        title: NUMBER_FORMATTER.format(accountTotals.pipeline),
      },
    }),
    [
      accountTotals.arr,
      accountTotals.num,
      accountTotals.pipeline,
      formatCurrencyShort,
      heatmapConfig.selectionSummary.currentArr,
      heatmapConfig.selectionSummary.numAccounts,
      heatmapConfig.selectionSummary.pipeline,
    ],
  );
  const tabSx = useMemo(
    (): SxProps<Theme> => ({
      alignItems: 'flex-start',
      py: 0,
      borderRadius: '4px 4px 0 0',
      transition: (theme) => theme.transitions.create(['color', 'background-color']),
      '&:hover': {
        color: (theme) => theme.palette.primary.main,
        backgroundColor: (theme) => theme.palette.primary.s12p,
      },
    }),
    [],
  );
  const summaryTextColor = useCallback(
    (theme: Theme, variation: HeatmapVariation) =>
      heatmapVariation === variation ? theme.palette.primary.main : undefined,
    [heatmapVariation],
  );
  const summarySubTextColor = useCallback(
    (theme: Theme, variation: HeatmapVariation) =>
      heatmapVariation === variation ? theme.palette.primary.light : theme.palette.grey[500],
    [heatmapVariation],
  );

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ my: 'auto' }} variant="overline">
          Heatmap
        </Typography>
        <HeatmapSettingsDrawer />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ ml: 1 }}>
          <Tooltip
            data-uid={tooltip$.component}
            title={
              <Typography>
                The <strong>heatmap</strong> is unique, top-down view of your customers (separated
                by customers, their ARR and pipeline), collecting them by engagement (x-axis) and
                potential (y-axis). Their distribution in individual segments can help you notice
                different groupings where you can focus your time and energy, using{' '}
                <strong>sprints</strong>.
              </Typography>
            }
          >
            <IconButton size="small">
              <Help fontSize="inherit" color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Tabs value={heatmapVariation} variant="fullWidth">
        <Tab
          data-uid={heatmap$.heatmapTabCustomers}
          value={HeatmapVariation.NumAccounts}
          label={
            <Typography variant="overline" sx={{ lineHeight: 1 }}>
              Customers
            </Typography>
          }
          onClick={() => onSelectHeatmapVariation(HeatmapVariation.NumAccounts)}
          sx={tabSx}
        />
        <Tab
          data-uid={heatmap$.heatmapTabARR}
          value={HeatmapVariation.CurrentArr}
          label={
            <Typography variant="overline" sx={{ lineHeight: 1 }}>
              Current ARR
            </Typography>
          }
          onClick={() => onSelectHeatmapVariation(HeatmapVariation.CurrentArr)}
          sx={tabSx}
        />
        <Tab
          data-uid={heatmap$.heatmapTabPipeline}
          value={HeatmapVariation.Pipeline}
          label={
            <Typography variant="overline" sx={{ lineHeight: 1 }}>
              Pipeline
            </Typography>
          }
          onClick={() => onSelectHeatmapVariation(HeatmapVariation.Pipeline)}
          sx={tabSx}
        />
      </Tabs>
      <Divider />
      <Box sx={{ display: 'flex', pt: 0.5 }}>
        <Stack direction="column" spacing={-0.5} sx={{ width: '30%', pl: 2 }}>
          <Typography
            data-uid={heatmap$.heatmapSummaryCustomers}
            variant="h6"
            sx={{ color: (theme) => summaryTextColor(theme, HeatmapVariation.NumAccounts) }}
            title={formattedSummaryValues.numSelected.title}
          >
            {formattedSummaryValues.numSelected.label}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => summarySubTextColor(theme, HeatmapVariation.NumAccounts),
            }}
            title={formattedSummaryValues.numTotal.title}
          >
            / {formattedSummaryValues.numTotal.label}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={-0.5} sx={{ width: '30%', pl: 2 }}>
          <Typography
            data-uid={heatmap$.heatmapSummaryARR}
            variant="h6"
            sx={{ color: (theme) => summaryTextColor(theme, HeatmapVariation.CurrentArr) }}
            title={formattedSummaryValues.arrSelected.title}
          >
            {formattedSummaryValues.arrSelected.label}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: (theme) => summarySubTextColor(theme, HeatmapVariation.CurrentArr) }}
            title={formattedSummaryValues.arrTotal.title}
          >
            / {formattedSummaryValues.arrTotal.label}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={-0.5} sx={{ width: '30%', pl: 2 }}>
          <Typography
            data-uid={heatmap$.heatmapSummaryPipeline}
            variant="h6"
            sx={{ color: (theme) => summaryTextColor(theme, HeatmapVariation.Pipeline) }}
            title={formattedSummaryValues.pipelineSelected.title}
          >
            {formattedSummaryValues.pipelineSelected.label}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: (theme) => summarySubTextColor(theme, HeatmapVariation.Pipeline) }}
            title={formattedSummaryValues.pipelineTotal.title}
          >
            / {formattedSummaryValues.pipelineTotal.label}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
