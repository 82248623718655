import { Visibility, VisibilityOutlined } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { format, isFuture } from 'date-fns';
import React, { useMemo, useState } from 'react';

import { DATE_FORMAT_LONG_LOCALIZED } from '../../../../constants';
import { useUserDateSettings } from '../../../../contexts/userSettingsContext';
import { ActivityStatus } from '../../../../graphql/generated';
import { ACTIVITY_STATUS_LABELS, Sprint } from '../../../../models/sprint';
import { pluralize, toDescriptiveDateTitle, toOptionalString } from '../../../../utils';
import { CustomScoreChip } from '../../../ScoreChip';
import { DynamicScoreChip } from '../../../ScoreChip/DynamicScoreChip';
import { useSprintSummary } from './useSprintSummary';

export interface SprintSummaryProps {
  activities: Sprint['activities'];
  startDate: Sprint['start'];
  endDate: Sprint['end'];
  createdBy: Sprint['createdBy']['name'];
}

export const SprintSummary = ({
  activities,
  startDate,
  endDate,
  createdBy,
}: SprintSummaryProps) => {
  const { locale } = useUserDateSettings();
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const {
    customHealthScore,
    growthScore,
    riskScore,
    renewalDate,
    customerLead,
    renewalLead,
    salesLead,
    totalActivities,
    totalNotStarted,
    totalInProgress,
    totalCompleted,
    totalRejected,
    totalAssignees,
    assigneeNames,
    arrValue,
    rawArrValue,
    pipelineValue,
    rawPipelineValue,
  } = useSprintSummary({ activities: activities });

  const visibilityIcon = useMemo(
    () => (showDetails ? <Visibility /> : <VisibilityOutlined />),
    [showDetails],
  );

  const ProgressCountCell = ({
    progressCount,
    type,
  }: {
    progressCount: number;
    type: ActivityStatus;
  }) => {
    return (
      <Box
        sx={{
          color: (theme) => {
            switch (type) {
              case ActivityStatus.InProcess:
                return theme.palette.info.main;
              case ActivityStatus.Complete:
                return theme.palette.success.main;
              case ActivityStatus.Rejected:
                return theme.palette.warning.main;
              default:
                return theme.palette.text.secondary;
            }
          },
        }}
      >
        <DataCell
          boxSx={{ minWidth: 'unset' }}
          value={`${progressCount}/${totalActivities}`}
          label={ACTIVITY_STATUS_LABELS[type]}
        />
      </Box>
    );
  };

  const DataCell = ({
    value,
    label,
    variant,
    boxSx,
    titleValue = null,
  }: {
    value: string | number | React.ReactNode | null;
    label: string;
    variant?: 'primary';
    boxSx?: BoxProps['sx'];
    titleValue?: string | null;
  }) => {
    return (
      <Box sx={{ minWidth: '7rem', ...boxSx }}>
        <Typography
          variant="h6"
          color={variant === 'primary' ? 'primary' : 'inherit'}
          sx={{
            maxWidth: '7rem',
            textOverflow: 'ellipsis',
            textDecoration: variant === 'primary' ? 'underline' : 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          title={toOptionalString(titleValue)}
        >
          {value ?? <>&ndash;</>}
        </Typography>
        <Typography variant="caption" noWrap>
          {label}
        </Typography>
      </Box>
    );
  };

  const DataRow = ({ spacing, children }: { spacing?: number; children: React.ReactNode }) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          paddingY: 2,
          paddingX: 3,
          '& > :not(:last-child)': {
            marginRight: spacing ?? 2,
          },
        }}
      >
        {children}
      </Stack>
    );
  };

  const DataCard = ({
    title,
    width,
    children,
  }: {
    title: string;
    width?: string | number;
    children: React.ReactNode;
  }) => {
    return (
      <Paper variant="outlined" elevation={0} sx={{ width: width ?? 'auto' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingY: 0, paddingLeft: 3, paddingRight: 1 }}
        >
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {title}
          </Typography>
          <IconButton size="small" onClick={() => setShowDetails(!showDetails)}>
            {visibilityIcon}
          </IconButton>
        </Stack>
        <Divider />
        {children}
      </Paper>
    );
  };

  const { isFutureEnd, isFutureRenew, isFutureStart } = useMemo(
    () => ({
      isFutureStart: startDate != null && isFuture(startDate),
      isFutureEnd: endDate != null && isFuture(endDate),
      isFutureRenew: renewalDate != null && isFuture(renewalDate),
    }),
    [endDate, renewalDate, startDate],
  );

  return (
    <Stack direction="row" spacing={3} sx={{ mx: 3 }}>
      <DataCard title="Progress">
        <DataRow spacing={2}>
          <ProgressCountCell progressCount={totalNotStarted} type={ActivityStatus.NotStarted} />
          <ProgressCountCell progressCount={totalInProgress} type={ActivityStatus.InProcess} />
          <ProgressCountCell progressCount={totalCompleted} type={ActivityStatus.Complete} />
          <ProgressCountCell progressCount={totalRejected} type={ActivityStatus.Rejected} />
        </DataRow>
        <Collapse in={showDetails} unmountOnExit>
          <DataRow spacing={4}>
            <DataCell
              boxSx={{ minWidth: '8rem' }}
              value={
                startDate ? format(startDate, DATE_FORMAT_LONG_LOCALIZED, { locale }) : startDate
              }
              label="Start Date"
              titleValue={toDescriptiveDateTitle(startDate, {
                prefix: isFutureStart ? 'Starts ' : 'Started ',
                locale,
              })}
            />
            <DataCell
              boxSx={{ minWidth: '8rem' }}
              value={endDate ? format(endDate, DATE_FORMAT_LONG_LOCALIZED, { locale }) : endDate}
              label="End Date"
              titleValue={toDescriptiveDateTitle(endDate, {
                prefix: isFutureEnd ? 'Ends ' : 'Ended ',
                locale,
              })}
            />
          </DataRow>
        </Collapse>
      </DataCard>
      <DataCard title="Customer Details" width="100%">
        <DataRow>
          <DataCell value={createdBy} label="Creator" variant="primary" titleValue={createdBy} />
          <DataCell
            value={totalAssignees}
            label={pluralize({ count: totalAssignees, singular: 'Assignee' })}
            variant="primary"
            titleValue={toOptionalString(assigneeNames)}
          />
          <DataCell value={arrValue} label="ARR" titleValue={toOptionalString(rawArrValue)} />
          <DataCell
            value={pipelineValue}
            label="Pipeline"
            titleValue={toOptionalString(rawPipelineValue)}
          />
          <DataCell
            value={
              customHealthScore ? <DynamicScoreChip value={customHealthScore} /> : <>&ndash;</>
            }
            label="Health Score"
            titleValue={toOptionalString(customHealthScore)}
          />
          <DataCell
            value={
              growthScore ? (
                <CustomScoreChip
                  value={growthScore.value}
                  label={growthScore.category}
                  color={growthScore.color}
                />
              ) : (
                <>&ndash;</>
              )
            }
            label="Growth Score"
            titleValue={toOptionalString(growthScore?.value)}
          />
          <DataCell
            value={
              riskScore ? (
                <CustomScoreChip
                  value={riskScore.value}
                  label={riskScore.category}
                  color={riskScore.color}
                />
              ) : (
                <>&ndash;</>
              )
            }
            label="Risk Score"
            titleValue={toOptionalString(riskScore?.value)}
          />
        </DataRow>
        <Collapse in={showDetails} unmountOnExit>
          <DataRow>
            <DataCell
              value={
                renewalDate
                  ? format(renewalDate, DATE_FORMAT_LONG_LOCALIZED, { locale })
                  : renewalDate
              }
              label="Renewal Date"
              titleValue={toDescriptiveDateTitle(renewalDate, {
                prefix: isFutureRenew ? 'Renews ' : 'Renewed ',
                locale,
              })}
            />
            <DataCell
              value={customerLead}
              label="Customer Lead"
              titleValue={toOptionalString(customerLead)}
            />
            <DataCell
              value={renewalLead}
              label="Renewal Lead"
              titleValue={toOptionalString(renewalLead)}
            />
            <DataCell
              value={salesLead}
              label="Sales Lead"
              titleValue={toOptionalString(salesLead)}
            />
          </DataRow>
        </Collapse>
      </DataCard>
    </Stack>
  );
};
