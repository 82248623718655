import { ObjValues } from '../types/util';

const tileBtn = (x?: number, y?: number) =>
  (x ?? NaN) >= 0 && (y ?? NaN) >= 0
    ? (`heatmap-tile-btn-${x}-${y}` as const)
    : ('heatmap-tile-btn' as const);
const selectAllTiles = 'heatmap-select-all-tiles-btn';
const tooltipTitle = 'heatmap-tooltip-title';
const tooltipXAxisMetric = 'heatmap-tooltip-x-axis-metric';
const tooltipYAxisMetric = 'heatmap-tooltip-y-axis-metric';
const heatmapTabCustomers = 'heatmap-tab-customers';
const heatmapSummaryCustomers = 'heatmap-summary-customers';
const heatmapSummaryPipeline = 'heatmap-summary-pipeline';
const heatmapSummaryARR = 'heatmap-summary-curr-arr';
const heatmapTabPipeline = 'heatmap-tab-pipeline';
const heatmapTabARR = 'heatmap-tab-curr-arr';
const heatmapComponent = 'heatmap-component';
const settingsOpen = 'heatmap-settings-open';
const settingsClose = 'heatmap-settings-close';
const settingsXAxisSelect = 'heatmap-settings-x-axis-select';
const settingsXAxisSelectOptions = 'heatmap-settings-x-axis-select-options';
const settingsYAxisSelect = 'heatmap-settings-y-axis-select';
const settingsYAxisSelectOptions = 'heatmap-settings-y-axis-select-options';
interface Heatmap$ {
  tileBtn: typeof tileBtn;
  selectAllTiles: typeof selectAllTiles;
  tooltipTitle: typeof tooltipTitle;
  tooltipXAxisMetric: typeof tooltipXAxisMetric;
  tooltipYAxisMetric: typeof tooltipYAxisMetric;
  heatmapTabCustomers: typeof heatmapTabCustomers;
  heatmapTabPipeline: typeof heatmapTabPipeline;
  heatmapTabARR: typeof heatmapTabARR;
  heatmapSummaryCustomers: typeof heatmapSummaryCustomers;
  heatmapSummaryPipeline: typeof heatmapSummaryPipeline;
  heatmapSummaryARR: typeof heatmapSummaryARR;
  heatmapComponent: typeof heatmapComponent;
  settingsOpen: typeof settingsOpen;
  settingsClose: typeof settingsClose;
  settingsXAxisSelect: typeof settingsXAxisSelect;
  settingsXAxisSelectOptions: typeof settingsXAxisSelectOptions;
  settingsYAxisSelect: typeof settingsYAxisSelect;
  settingsYAxisSelectOptions: typeof settingsYAxisSelectOptions;
}

export const heatmap$: Heatmap$ = {
  tileBtn,
  selectAllTiles,
  tooltipTitle,
  tooltipXAxisMetric,
  tooltipYAxisMetric,
  heatmapComponent,
  heatmapTabCustomers,
  heatmapTabPipeline,
  heatmapTabARR,
  heatmapSummaryCustomers,
  heatmapSummaryPipeline,
  heatmapSummaryARR,
  settingsOpen,
  settingsClose,
  settingsXAxisSelect,
  settingsXAxisSelectOptions,
  settingsYAxisSelect,
  settingsYAxisSelectOptions,
};
export type Heatmap$Values = ObjValues<Heatmap$>;
