import { UUID } from '../models/uuid';
import { ObjValues } from '../types/util';

const page = {
  root: 'user-work--page-container',
  header: 'user-work--page-header',
} as const;
const sprints = {
  tab: 'user-work--tab--sprints',
  section: 'user-work--sprints-section',
} as const;
const actions = {
  tab: 'user-work--tab--actions',
  section: 'user-work--actions-section',
} as const;
const recommendations = {
  tab: 'user-work--tab--recommendations',
  section: 'user-work--recommendations-section',
  loader: 'user-work--recommendations--loader',
  error: 'user-work--recommendations--error-alert',
  empty: 'user-work--recommendations--empty-alert',
  list: 'user-work--recommendations--list',
  item: (id: UUID) => `user-work--recommendations--rec-${id}` as const,
  name: 'user-work--recommendation--name',
  accountLink: (id: UUID) => `user-work--recommendations--rec-${id}--account-link` as const,
  fieldHeader: (id: UUID, field: string) =>
    `user-work--recommendations--rec-${id}--${field}-field-header` as const,
  fieldValue: (id: UUID, field: string) =>
    `user-work--recommendations--rec-${id}--${field}-field-value` as const,
  createSprintMenuOpenerBtn: (id: UUID) =>
    `user-work--recommendations--rec-${id}--create-sprint-menu-opener-btn` as const,
  createSprintBtn: (id: UUID) =>
    `user-work--recommendations--rec-${id}--create-sprint-btn` as const,
  inProgressSprintBtn: (id: UUID) =>
    `user-work--recommendations--rec-${id}--in-progress-sprint-btn` as const,
  viewSprintBtn: (id: UUID) => `user-work--recommendations--rec-${id}--view-sprint-btn` as const,
  missingSprintBtn: (id: UUID) =>
    `user-work--recommendations--rec-${id}--missing-sprint-btn` as const,
  rejectedSprintBtn: (id: UUID) =>
    `user-work--recommendations--rec-${id}--rejected-sprint-btn` as const,
} as const;

export const userWork$ = {
  page,
  sprints,
  actions,
  recommendations,
} as const;

export type UserWork$Values =
  | ObjValues<typeof page>
  | ObjValues<typeof sprints>
  | ObjValues<typeof actions>
  | ObjValues<typeof recommendations>;
