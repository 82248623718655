import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

export const ProgressiveSpinner = ({ progress }: { progress: number }) => (
  <Box sx={{ position: 'relative', height: 'auto' }}>
    <CircularProgress
      data-uid="progressive-spinner"
      variant="determinate"
      value={progress}
      size={50}
      sx={{ display: 'block', height: 'auto' }}
    />
    <Typography
      variant="caption"
      sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      {progress}%
    </Typography>
  </Box>
);
