import { Box, Container, Typography } from '@mui/material';
import React from 'react';

import reefHeaderLogo from '../../../images/reefHeaderLogo.svg';
import { DataConnectorList } from './DataConnectorList/DataConnectorList';

export const DataConnectors = () => {
  const title = 'Add Data Sources';
  const description = "To activate your Reef.ai instance connect your company's data source.";
  return (
    <Container>
      <Box sx={{ py: 6 }}>
        <Box sx={{ pb: 4 }}>
          <img src={reefHeaderLogo} />
        </Box>
        <Typography variant="h4" color="primary" sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Typography variant="subtitle1">{description}</Typography>
      </Box>
      <DataConnectorList />
    </Container>
  );
};
