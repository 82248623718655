import {
  GridFilterInitialState,
  GridLogicOperator,
  GridSortingInitialState,
} from '@mui/x-data-grid-premium';

import { ActivityStatus } from '../graphql/generated';
import { AccountHealthField } from '../models/account';
import { ACTIVITY_STATUS_LABELS } from '../models/sprint';

/**
 * Truncate list menu item text after 32 chars, on 1440px wide screen.
 */
export const LIST_MENU_ITEM_CHAR_LIMIT = 32;

/**
 * Truncate title char text after 32 chars, on 1440px wide screen.
 */
export const TITLE_CHAR_LIMIT = 32;

/**
 * Truncate Customer details name after 20 chars, on 1440px wide screen.
 */
export const CUSTOMER_NAME_CHAR_LIMIT = 20;

/**
 * Truncate action title char text after 20 chars.
 */
export const PLAYBOOK_MANAGEMENT_TITLE_LIMIT = 20;

/**
 * Truncate the char text after 38 chars.
 */
export const DATA_GRID_TEXT_LIMIT = 38;

// https://date-fns.org/v3.6.0/docs/format
/**
 * `Long localized date	P	04/29/1453	7`
 */
export const DATE_FORMAT_LONG_LOCALIZED = 'P';

/**
 * `Combination of date and time	Pp	04/29/1453, 12:00 AM	7`
 */
export const DATE_FORMAT_DATE_AND_TIME = 'Pp';

/**
 * `Combination of date and time	PPPppp	April 29th, 1453 at 12:00:00 AM GMT+2	7`
 */
export const DATE_FORMAT_DATE_AND_TIME_EXPANDED = 'PPPppp';

/**
 * Simple array of label strings, to be used with the Sprint Action Doughnut.
 */
export const ACTION_DOUGHNUT_LABELS = [
  ACTIVITY_STATUS_LABELS[ActivityStatus.NotStarted],
  ACTIVITY_STATUS_LABELS[ActivityStatus.InProcess],
  ACTIVITY_STATUS_LABELS[ActivityStatus.Complete],
  ACTIVITY_STATUS_LABELS[ActivityStatus.Rejected],
] as const;
/**
 * Simple array of hex colors, used in the Sprint Action Doughnut.
 */
export const ACTION_DOUGHNUT_COLORS = ['#818181', '#7C62DE', '#70D950', '#ff9800'];
/**
 * An Activity status label --> Hex Color map.
 */
export const ACTION_DOUGHNUT_COLORS_MAP: Record<ActivityStatus, string> = {
  [ActivityStatus.NotStarted]: ACTION_DOUGHNUT_COLORS[0],
  [ActivityStatus.InProcess]: ACTION_DOUGHNUT_COLORS[1],
  [ActivityStatus.Complete]: ACTION_DOUGHNUT_COLORS[2],
  [ActivityStatus.Rejected]: ACTION_DOUGHNUT_COLORS[3],
  [ActivityStatus.NotActive]: '#ccc',
};

/**
 * Account "health" fields to consider for the Data Quality Sync page.
 */
export const HEALTH_FIELDS: AccountHealthField[] = [
  'name',
  'ownerName',
  'currentArr',
  'customerRevenue',
  'renewalDate',
];

/**
 * Default empty filtering state for Customers Grid persistence.
 */
export const DEFAULT_CUSTOMERS_GRID_FILTERING: GridFilterInitialState = {
  filterModel: {
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterValues: [],
    quickFilterLogicOperator: GridLogicOperator.And,
  },
};

/**
 * Default name-based sorting state for Customers Grid persistence.
 */
export const DEFAULT_CUSTOMERS_GRID_SORTING: GridSortingInitialState = {
  sortModel: [{ field: 'name', sort: 'asc' }],
};

/**
 * Standard currency formatter.
 */
export const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0, // prefer whole numbers
  maximumFractionDigits: 0, // prefer whole numbers
  style: 'currency',
  currency: 'USD',
});

/**
 * Lifted from https://stackoverflow.com/a/201378
 */
export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

/**
 * Support email
 */
export const REEF_SUPPORT_EMAIL = 'support@reefai.zendesk.com';

/**
 * Heatmap grid / selection size; assumed to be square, so this configures the heatmap for tiling up to 10x10.
 */
export const MINIMUM_HEATMAP_GRID_SIZE = 10;

/**
 * Mapping for currency symbols
 */
export const CURRENCY_SYMBOLS: { [key: string]: string } = {
  AUD: '$', // Australian Dollar
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};
