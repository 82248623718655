import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { AuthApi, CognitoAuth, CognitoErrorCodes, isCognitoError } from '../apis/auth';
import { asUUID } from '../models/uuid';
import { bottom } from '../utils';
import { useReefAnalyticsContext } from './reefAnalyticsContext';
import {
  AuthAction,
  AuthActions,
  AuthActionType,
  AuthContext,
  AuthError,
  AuthState,
  AuthStatus,
  getReefSessionInfo,
  ImpersonationError,
  ReefAuthClaims,
  ReefAuthContext,
  ReefAuthServiceContext,
  UnknownClientError,
  UnknownUserError,
} from './reefAuthContext';

/**
 * Redirect with browser state for any oauth errors detected.
 * @returns error if one exists
 */
function useOAuthError(): Error | undefined {
  const [err, setErr] = useState<Error>();
  useEffect(() => {
    const timer = setInterval(() => {
      const state = { ...history.state };
      if (state.oAuthError != null) {
        // capture the error and remove it from browser state
        // TODO: would it be better to just use browser storage here?
        setErr(state.oAuthError);
        delete state.oAuthError;
        history.replaceState(state, '', window.location.href);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return err;
}

interface ReefAuthProviderProps {
  userPoolId: string;
  webClientId: string;
  cognitoCustomDomain: string;
  oAuthRedirect: string;
}

export const ReefAuthProvider = ({
  userPoolId,
  webClientId,
  oAuthRedirect,
  cognitoCustomDomain,
  children,
}: PropsWithChildren<ReefAuthProviderProps>) => {
  const authService = useMemo(
    () =>
      new CognitoAuth({
        userPoolId,
        webClientId,
        oAuthRedirect,
        oAuthDomain: cognitoCustomDomain,
      }),
    [cognitoCustomDomain, oAuthRedirect, userPoolId, webClientId],
  );

  const [authState, setAuthState] = useState<AuthState>({ status: AuthStatus.Loading });
  const [error, setError] = useState<Error>();
  const { identify, reset } = useReefAnalyticsContext();

  // load the oauth error into the provider state
  const oAuthError = useOAuthError();
  useEffect(() => {
    if (oAuthError != null) {
      setError(oAuthError);
    }
  }, [oAuthError]);

  // login callback used to get and validate the session - then navigate to the app
  const authenticate = useCallback(
    async (options: AuthAction): Promise<void> => {
      // determine which type of authentication to use
      switch (options.type) {
        case AuthActionType.SkeletonSignUp:
        case AuthActionType.SignUp: {
          const { username, password } = options;
          try {
            const isKnock = options.type === AuthActionType.SkeletonSignUp;
            const { userConfirmed } = await authService.signUp(username, password, { isKnock });
            if (!userConfirmed) {
              setAuthState({
                status: AuthStatus.Confirming,
                signUpOptions: { username, password },
                confirmed: userConfirmed,
              });
            }
          } catch (e) {
            if (e instanceof Error) {
              if (isCognitoError(e)) {
                switch (e.code) {
                  case CognitoErrorCodes.UserLambdaValidationException:
                    if (e.message.includes('unknown client')) {
                      setError(new UnknownClientError());
                    } else if (e.message.includes('unknown user')) {
                      setError(new UnknownUserError());
                    }
                    break;
                  default:
                    setError(e);
                }
              } else {
                setError(e);
              }
            } else {
              console.error(`got an unknown auth error`, e);
              setError(new Error(`${e}`));
            }
          }
          break;
        }
        case AuthActionType.ConfirmSignUp: {
          try {
            await authService.confirmSignUp(options.username, options.answer);
            setAuthState((prev) => ({ ...prev, confirmed: true }));
          } catch (e) {
            if (e instanceof Error) {
              setError(e);
            }
          }
          break;
        }
        case AuthActionType.ResendSignUp: {
          const { username } = options;
          try {
            await authService.resendSignUp(username);
            setAuthState({
              status: AuthStatus.Confirming,
              signUpOptions: { username },
              confirmed: false,
            });
          } catch (e) {
            if (e instanceof Error) {
              setError(e);
            }
            throw e;
          }
          break;
        }
        case AuthActionType.Skeleton:
        case AuthActionType.UserPass: {
          const { username, password } = options;
          const clientMetadata =
            options.type === AuthActionType.Skeleton ? options.clientMetadata : undefined;
          try {
            const result = await authService.signIn(username, password ?? '', clientMetadata);
            const user = result.getSignInUserSession();
            if (user != null) {
              const session = getReefSessionInfo(
                user.getIdToken().decodePayload() as ReefAuthClaims,
              );
              setAuthState({ status: AuthStatus.SignedIn, session });
            }
          } catch (e) {
            if (e instanceof ImpersonationError) {
              // in this case, the user actually successfully logged in, but the app
              // wont work because the user they are impersonating likely doesn't exist
              await authService.signOut();
            }
            if (e instanceof Error) {
              if (isCognitoError(e)) {
                switch (e.code) {
                  case CognitoErrorCodes.UserNotConfirmedException:
                    await authService.resendSignUp(username);
                    setAuthState({
                      status: AuthStatus.Confirming,
                      // because the user is trying to _sign in_ the password they provided
                      // may not be the same password that they provided when they did the sign-up flow
                      // so we should _only_ provide the username here to force the app to
                      // redirect back to the login flow
                      signUpOptions: { username },
                      confirmed: false,
                    });
                    break;
                  case CognitoErrorCodes.UserLambdaValidationException:
                    setError(new Error('Not a valid Reef.ai client or known Reef.ai user.'));
                    break;
                  default:
                    setError(e);
                }
              }
            }
          }
          break;
        }
        case AuthActionType.Federated:
          // capturing the result of auth here is pointless because this does
          // a hard navigate away from the page
          await authService.federatedSignIn();
          break;
        default:
          // istanbul ignore next
          bottom(options);
      }
    },
    [authService],
  );

  const logout = useCallback(async () => {
    await authService.signOut();
    setAuthState({ status: AuthStatus.SignedOut });
  }, [authService]);

  // init the user session when loading the app
  useEffect(() => {
    const loadSession = async (): Promise<void> => {
      const cognitoSession = await authService.getCurrentSession();
      if (cognitoSession != null) {
        identify(cognitoSession);
        const session = getReefSessionInfo(
          cognitoSession.getIdToken().decodePayload() as ReefAuthClaims,
        );
        setAuthState({ status: AuthStatus.SignedIn, session });
      } else if (authState.status === AuthStatus.Loading) {
        reset();
        setAuthState({ status: AuthStatus.SignedOut });
      }
    };

    loadSession();
  }, [authState.status, authService, identify, reset]);

  const authContext = useMemo((): AuthContext => {
    return [
      authState,
      { authenticate, logout },
      { error, clearError: () => setError(undefined) },
    ] satisfies AuthContext;
  }, [authState, error, authenticate, logout]);

  return (
    <ReefAuthContext.Provider value={authContext}>
      <ReefAuthServiceContext.Provider value={authService}>
        {children}
      </ReefAuthServiceContext.Provider>
    </ReefAuthContext.Provider>
  );
};

interface MockedAuthProviderProps {
  authState?: AuthState;
  authError?: Error;
  authService?: AuthApi;
}
export const MockedAuthProvider = ({
  children,
  authState,
  authService,
  authError,
}: PropsWithChildren<MockedAuthProviderProps>) => {
  const state = useMemo((): AuthState => {
    if (authState != null) {
      return authState;
    }
    return {
      status: AuthStatus.SignedIn,
      session: {
        authProvider: 'test',
        clientId: asUUID('test-client'),
        isImpersonating: false,
      },
    };
  }, [authState]);
  const actions = useMemo((): AuthActions => ({ logout: vi.fn(), authenticate: vi.fn() }), []);
  const errors = useMemo(
    (): AuthError => ({ error: authError ?? undefined, clearError: vi.fn() }),
    [authError],
  );
  return (
    <ReefAuthContext.Provider value={[state, actions, errors]}>
      <ReefAuthServiceContext.Provider value={authService}>
        {children}
      </ReefAuthServiceContext.Provider>
    </ReefAuthContext.Provider>
  );
};
