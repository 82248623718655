import React, { useEffect } from 'react';
import { Outlet, OutletProps, useLocation } from 'react-router-dom';

import { useAuthError } from '../../contexts/reefAuthContext';

export const AuthErrorOutlet = (props: OutletProps) => {
  const location = useLocation();
  const [, clearError] = useAuthError();

  // we want to clear the error when we navigate to a new path
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearError, [location.pathname]);

  // TODO: move error alert rendering here
  return <Outlet {...props} />;
};
