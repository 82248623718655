import { LoadingButton } from '@mui/lab';
import { Alert, Link, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { EMAIL_REGEX } from '../../../constants';
import {
  AuthActionType,
  useAuthError,
  useReefAuthContext,
} from '../../../contexts/reefAuthContext';

export const ResendSecurityCode = () => {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  const [, { authenticate }] = useReefAuthContext();
  const [authError, clearAuthError] = useAuthError();

  const navigate = useNavigate();

  const resendSignUp = useCallback(
    async (username: string) => {
      clearAuthError();
      setLoading(true);
      try {
        await authenticate({ type: AuthActionType.ResendSignUp, username });
        navigate('/sign-up/challenge');
        // do nothing with the error since it is propagated to the auth error hook
      } catch (e) {
        console.error('failed to resend sign up', e);
      } finally {
        setLoading(false);
      }
    },
    [authenticate, clearAuthError, navigate],
  );

  return (
    <Stack spacing={2} maxWidth={(theme) => theme.spacing(42)}>
      <TextField
        fullWidth
        variant="outlined"
        label="Email"
        name="email"
        required
        onChange={(e) => setUsername(e.target.value)}
        value={username}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        disabled={username.length === 0 || !EMAIL_REGEX.test(username)}
        loading={loading}
        onClick={() => resendSignUp(username)}
      >
        Submit
      </LoadingButton>
      <Typography variant="caption">
        Return to{' '}
        <Link component={RouterLink} sx={{ cursor: 'pointer' }} to="/login">
          log in
        </Link>
      </Typography>
      {authError != null && <Alert severity="error">{authError.message}</Alert>}
    </Stack>
  );
};
