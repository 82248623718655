import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React from 'react';

import { RecommendationMetric, RiskRecommendationRow } from '../../../../models/recommendation';
import { CustomScoreCell, PercentCell, RenewalScoreCell } from '..';

type RiskRecommendationScoreCellProps = GridRenderCellParams<
  RiskRecommendationRow,
  RecommendationMetric
>;
export const RiskRecommendationScoreCell = (cellParams: RiskRecommendationScoreCellProps) => {
  const { rowNode, value, formattedValue } = cellParams;
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  switch (value?.type) {
    case 'ACCOUNT_FULL_CHURN_RISK':
      return (
        <CustomScoreCell
          {...cellParams}
          value={{
            bucket: value?.bucket ?? NaN,
            category: value?.category ?? '',
            color: value?.color ?? '',
            value: parseFloat(value?.value ?? ''),
          }}
          formattedValue={{
            bucket: formattedValue?.bucket ?? NaN,
            category: formattedValue?.category ?? '',
            color: formattedValue?.color ?? '',
            value: parseFloat(formattedValue?.value ?? ''),
          }}
        />
      );
    case 'ACCOUNT_HEALTHSCORE':
    case 'CONTRACT_USAGE_RATING':
      return (
        <RenewalScoreCell
          {...cellParams}
          value={value?.value ?? undefined}
          formattedValue={formattedValue?.value ?? undefined}
        />
      );
    case 'PRODUCT_CONSUMPTION_PERCENT':
      return (
        <PercentCell
          {...cellParams}
          value={parseFloat(value?.value ?? '')}
          formattedValue={parseFloat(formattedValue?.value ?? '')}
        />
      );
    default:
      return missingValue;
  }
};
