import { ObjValues } from '../types/util';

const comingSoon = {
  icon: 'coming-soon--icon',
} as const;

export const misc$ = {
  comingSoon,
} as const;

export type Misc$Values = ObjValues<typeof comingSoon>;
