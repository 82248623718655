import React from 'react';

import { Renewal } from '../../../hooks/renewal';
import { useTotalRenewalStatusChartConfig } from '../../../hooks/useTotalRenewalStatusChartConfig';
import { BarChart } from './BarChart';

interface TotalRenewalStatusChartProps {
  renewals: Renewal[];
}
export const TotalRenewalStatusChart = ({ renewals }: TotalRenewalStatusChartProps) => {
  const barChartProps = useTotalRenewalStatusChartConfig({ renewals });
  return <BarChart {...barChartProps} />;
};
