import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridComparatorFn,
  GridGroupingValueGetterParams,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridValueGetterParams,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { add, format, isAfter, isBefore, isSameMonth, startOfToday, sub } from 'date-fns';
import { sumBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CURRENCY_FORMATTER } from '../../../constants';
import { useUserSettingsContext } from '../../../contexts/userSettingsContext';
import { useClientRecommendations } from '../../../hooks/recommendation';
import { useCommonGridHelpers } from '../../../hooks/useCommonGridHelpers';
import { PartialUser } from '../../../hooks/user';
import {
  RecommendationMetric,
  RISK_TYPE_LABELS,
  RiskRecommendation,
  RiskRecommendationRow,
  RiskRecommendationsSummary,
} from '../../../models/recommendation';
import { ResultType } from '../../../models/result';
import { UUID } from '../../../models/uuid';
import { recommendations$ } from '../../../selectors/recommendations';
import {
  getMUIMultiGroupedColumnField,
  NUMBER_FORMATTER,
  WHOLE_PERCENT_NUMBER_FORMATTER,
} from '../../../utils';
import {
  CurrencyCell,
  CustomerCell,
  CustomGrid,
  DateCell,
  MetricCell,
  PercentCell,
  RenewalScoreCell,
  StringCell,
} from '../../Tables/CustomGrid';
import { RiskRecommendationScoreCell } from '../../Tables/CustomGrid/Recommendation/RiskRecommendationScoreCell';

const MINIMUM_COLUMN_WIDTH = 150;

const renderCustomerCell = (params: GridRenderCellParams<RiskRecommendationRow, string>) => (
  <CustomerCell {...params} row={{ id: params.row.customerId }} showProductDetailsLink />
);

const renderDateCell = (params: GridRenderCellParams<RiskRecommendationRow, Date>) => (
  <DateCell {...params} />
);

const renderRenewalScoreCell = (params: GridRenderCellParams<RiskRecommendationRow, string>) => (
  <RenewalScoreCell {...params} />
);

const renderStringCell = (params: GridRenderCellParams<RiskRecommendationRow, string>) => (
  <StringCell {...params} />
);

const renderCurrencyCell = (params: GridRenderCellParams<RiskRecommendationRow, number>) => (
  <CurrencyCell {...params} />
);

const renderMetricCell = (params: GridRenderCellParams<RiskRecommendationRow, string>) => (
  <MetricCell {...params} />
);

const renderRiskScoreCell = (
  params: GridRenderCellParams<RiskRecommendationRow, RecommendationMetric>,
) => <RiskRecommendationScoreCell {...params} />;

const renderPercentCell = (params: GridRenderCellParams<RiskRecommendationRow, number>) => (
  <PercentCell {...params} />
);

const recMetricNumberOrStringValue = (
  v?: RecommendationMetric['value'],
): number | string | null | undefined => {
  const float = parseFloat(v ?? '');
  if (Number.isFinite(float)) {
    return float;
  }
  return v;
};
const customRiskScoreComparator: GridComparatorFn<RecommendationMetric | null> = (
  score1,
  score2,
  cell1,
  cell2,
) =>
  gridStringOrNumberComparator(
    recMetricNumberOrStringValue(score1?.value) ?? Number.MIN_SAFE_INTEGER,
    recMetricNumberOrStringValue(score2?.value) ?? Number.MIN_SAFE_INTEGER,
    {
      ...cell1,
      value: recMetricNumberOrStringValue(cell1.value?.value) ?? Number.MIN_SAFE_INTEGER,
    },
    {
      ...cell2,
      value: recMetricNumberOrStringValue(cell2.value?.value) ?? Number.MIN_SAFE_INTEGER,
    },
  );

/**
 * Async function to load mocked risk recommendations + load extra bundles.
 * @param cb dispatch function to update risk recommendations
 */
async function loadMockedRiskRecommendations(
  cb: (recommendations: RiskRecommendation[]) => void,
): Promise<void> {
  const { someRiskRecommendations } = await import('../../../graphql/mocks');
  const mockedRiskRecommendations = someRiskRecommendations({}, 25);

  cb(mockedRiskRecommendations);
}

interface UseAllRiskRecommendationsOptions {
  showDemoData: boolean;
}

/**
 * Hook to get all risk recommendations.
 * @param root0 options
 * @param root0.showDemoData flag to show mocked risk recommendations
 * @returns list of risk recommendations (or empty)
 */
function useAllRiskRecommendations({
  showDemoData,
}: UseAllRiskRecommendationsOptions): [RiskRecommendation[], boolean] {
  const result = useClientRecommendations({ options: { skip: showDemoData } });
  const loading = useMemo(() => result.state === ResultType.Loading, [result.state]);

  const [allRiskRecommendations, setAllRiskRecommendations] = useState<RiskRecommendation[]>([]);
  useEffect(() => {
    // NOTE: only load mocked recommendations once to avoid glitchy states
    if (allRiskRecommendations.length < 1) {
      if (result.state === ResultType.Value) {
        setAllRiskRecommendations(result.value);
      }
      if (showDemoData) {
        loadMockedRiskRecommendations(setAllRiskRecommendations);
      }
    }
  }, [allRiskRecommendations.length, result.state, result.value, showDemoData]);

  return [allRiskRecommendations, loading];
}

export const RiskRecommendations = () => {
  const { showDemoData } = useUserSettingsContext();
  const [riskRecommendations, loading] = useAllRiskRecommendations({ showDemoData });
  const { columnVisibilityModel, ...gridData } = useCommonGridHelpers({
    hideableColMap: {},
    localeEntity: 'Recommendation',
  });

  // TODO: backfill with browser state/local storage
  const [reportDate, setReportDate] = useState(startOfToday());

  const recommendations = useMemo(() => {
    if (riskRecommendations == null) {
      return [];
    }
    return riskRecommendations.filter(
      (r) => r.createdOn == null || isSameMonth(r.createdOn, reportDate),
    );
  }, [reportDate, riskRecommendations]);

  const earliestRecommendationDate = useMemo(
    () =>
      riskRecommendations.reduce((earliest: Date, r) => {
        if (r.createdOn != null) {
          const createdOn = r.createdOn;
          if (isBefore(createdOn, earliest)) {
            return createdOn;
          }
        }
        return earliest;
      }, startOfToday()) ?? new Date(2024, 0),
    [riskRecommendations],
  );

  const summary = useMemo((): RiskRecommendationsSummary => {
    const users = new Set(
      recommendations.reduce((owners: UUID[], r) => {
        if (r.owner != null && r.owner.id != null) {
          return [...owners, r.owner.id];
        }
        return owners;
      }, []),
    );
    const totalExpiringArr = sumBy(recommendations, 'contract.expiringArr');
    const totalNewContractValue = showDemoData
      ? sumBy(recommendations, 'metrics.newContractValue')
      : null;
    return {
      numberOfUsers: users.size,
      numberOfRecommendations: recommendations.length,
      totalRecommendationsRun: recommendations.filter((r) => r.sprint != null).length,
      totalExpiringArr,
      totalNewContractValue,
      totalNRR: showDemoData ? (totalNewContractValue ?? 0) / (totalExpiringArr || 1) : null,
    };
  }, [recommendations, showDemoData]);

  const existingColumns = useMemo(
    (): GridColDef<RiskRecommendationRow>[] => [
      {
        field: 'contractUsageRating',
        headerName: 'Renewal Score',
        minWidth: 170,
        flex: 170 / MINIMUM_COLUMN_WIDTH,
        type: 'string',
        renderCell: renderRenewalScoreCell,
        align: 'center',
        headerAlign: 'center',
        aggregable: false,
        groupable: true,
        description: 'The current Reef Renewal Score for the associated Contract.',
      },
      {
        field: 'customerHealthscore',
        type: 'string',
        headerName: 'Healthscore',
        minWidth: 150,
        flex: 150 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderMetricCell,
        align: 'center',
        headerAlign: 'center',
        aggregable: false,
        groupable: true,
        description: 'The current Healthscore of the recommended Customer.',
      },
      {
        field: 'contractExpiringArr',
        type: 'number',
        headerName: 'Expiring ARR',
        minWidth: 160,
        flex: 160 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderCurrencyCell,
        aggregable: true,
        groupable: false,
      },
    ],
    [],
  );
  const demoColumns = useMemo(
    (): GridColDef<RiskRecommendationRow>[] => [
      {
        field: 'riskScoreTypeLabel',
        type: 'string',
        headerName: 'Risk Type',
        minWidth: 190,
        flex: 190 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderStringCell,
        aggregable: false,
        groupable: true,
      },
      {
        field: 'originalRecommendationScore',
        headerName: 'Recommendation Score',
        minWidth: 230,
        flex: 230 / MINIMUM_COLUMN_WIDTH,
        type: 'string',
        renderCell: renderRiskScoreCell,
        sortComparator: customRiskScoreComparator,
        align: 'center',
        headerAlign: 'center',
        aggregable: false,
        groupable: true,
        description:
          'The original Reef Recommendation Score, capturing the relevant risk metric for a given recommendation.',
      },
      {
        field: 'currentRecommendationScore',
        headerName: 'Current Score',
        minWidth: 170,
        flex: 170 / MINIMUM_COLUMN_WIDTH,
        type: 'string',
        renderCell: renderRiskScoreCell,
        sortComparator: customRiskScoreComparator,
        align: 'center',
        headerAlign: 'center',
        aggregable: false,
        groupable: true,
        description:
          'The current Reef Recommendation Score, showing what the relevant risk metric looks like right now.',
      },
      {
        field: 'contractExpiringArr',
        type: 'number',
        headerName: 'Expiring ARR',
        minWidth: 160,
        flex: 160 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderCurrencyCell,
        aggregable: true,
        groupable: false,
      },
      {
        field: 'newContractStatus',
        type: 'string',
        headerName: 'Contract Status',
        minWidth: 170,
        flex: 170 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderStringCell,
        aggregable: false,
        groupable: true,
      },
      {
        field: 'newContractValue',
        type: 'number',
        headerName: 'Contract Value',
        minWidth: 170,
        flex: 170 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderCurrencyCell,
        aggregable: true,
        groupable: false,
      },
      {
        field: 'totalNRR',
        headerName: 'Total NRR',
        minWidth: 150,
        flex: 150 / MINIMUM_COLUMN_WIDTH,
        type: 'number',
        renderCell: renderPercentCell,
        aggregable: true,
        groupable: false,
      },
    ],
    [],
  );

  const columns = useMemo(
    (): GridColDef<RiskRecommendationRow>[] => [
      {
        field: 'owner',
        type: 'string',
        headerName: 'User',
        valueGetter: (params: GridValueGetterParams<RiskRecommendationRow, PartialUser | null>) =>
          params.value?.name,
        groupingValueGetter: (
          params: GridGroupingValueGetterParams<RiskRecommendationRow, PartialUser | null>,
        ) => params.value?.name ?? 'No Owner',
        renderCell: renderStringCell,
        minWidth: 175,
        flex: 175 / MINIMUM_COLUMN_WIDTH,
        aggregable: false,
        groupable: true,
      },
      {
        field: 'playbookName',
        type: 'string',
        headerName: 'Playbook Name',
        minWidth: 250,
        flex: 250 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderStringCell,
        aggregable: false,
        groupable: true,
      },
      {
        field: 'createdOn',
        headerName: 'Recommended On',
        minWidth: 190,
        flex: 190 / MINIMUM_COLUMN_WIDTH,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'customerName',
        type: 'string',
        headerName: 'Customer Name',
        minWidth: 175,
        flex: 175 / MINIMUM_COLUMN_WIDTH,
        renderCell: renderCustomerCell,
        aggregable: false,
        groupable: false,
      },
      {
        field: 'sprintStarted',
        type: 'boolean',
        headerName: 'Sprint Started?',
        minWidth: 170,
        flex: 170 / MINIMUM_COLUMN_WIDTH,
        aggregable: false,
        groupable: false,
      },
      ...(showDemoData ? demoColumns : existingColumns),
    ],
    [demoColumns, existingColumns, showDemoData],
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef: gridData.apiRef,
    initialState: {
      pinnedColumns: {
        left: [getMUIMultiGroupedColumnField('owner')],
      },
      rowGrouping: { model: ['owner'] },
      columns: { columnVisibilityModel },
      sorting: {
        sortModel: [
          { field: getMUIMultiGroupedColumnField('owner'), sort: 'asc' },
          { field: 'createdOn', sort: 'desc' },
        ],
      },
    },
  });

  const rows = useMemo(
    () =>
      recommendations.map(
        (r): RiskRecommendationRow => ({
          id: r.id,
          createdOn: r.createdOn,
          playbookName: r.playbook.title,
          customerId: r.account.id,
          customerName: r.account.name,
          sprintStarted: r.sprint != null,
          customerHealthscore: r.account.customerHealthscore,
          contractExpiringArr: r.contract.expiringArr,
          contractUsageRating: r.contract.usageRating,
          owner: r.owner,
          currentRecommendationScore: r.metrics?.currentScore ?? null,
          newContractStatus: r.metrics?.newContractStatus ?? null,
          newContractValue: r.metrics?.newContractValue ?? null,
          originalRecommendationScore: r.metrics?.originalScore ?? null,
          riskScoreTypeLabel: RISK_TYPE_LABELS[r.type],
          totalNRR: r.metrics?.totalNRR ?? null,
        }),
      ),
    [recommendations],
  );

  return (
    <Box
      data-uid={recommendations$.risk.page.risk}
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Container sx={{ mx: 0, flexGrow: 1 }} maxWidth={false}>
        <Stack spacing={2} flexGrow={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2" sx={{ pb: 3, pt: 4 }}>
              Monthly Risk Report
            </Typography>
            <Box sx={{ my: 'auto' }}>
              <Button
                component={RouterLink}
                data-uid={recommendations$.risk.page.viewRecsBtn}
                to="/work/my/recommendations"
                variant="contained"
                color="primary"
                disableElevation
              >
                View My Recommendations
              </Button>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Card elevation={1} sx={{ flexShrink: 0 }}>
              <CardHeader
                sx={{ px: 1, py: 1.4 }}
                title={
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      data-uid={recommendations$.risk.summary.previousMonthBtn}
                      size="small"
                      onClick={() => setReportDate(sub(reportDate, { months: 1 }))}
                      // don't allow the user to traverse before the earliest known recommendations
                      disabled={isSameMonth(reportDate, earliestRecommendationDate)}
                    >
                      <NavigateBefore />
                    </IconButton>
                    <Box
                      sx={{
                        flexGrow: 1,
                        // fitting for longer text e.g. "december xxxx"
                        minWidth: (theme) => theme.spacing(22),
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {format(reportDate, 'MMMM yyyy')}
                    </Box>
                    <IconButton
                      data-uid={recommendations$.risk.summary.nextMonthBtn}
                      size="small"
                      disabled={isAfter(add(reportDate, { months: 1 }), startOfToday())}
                      onClick={() => setReportDate(add(reportDate, { months: 1 }))}
                    >
                      <NavigateNext />
                    </IconButton>
                  </Stack>
                }
              />
              <Divider />
              <CardContent sx={{ p: 4 }}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="h4" data-uid={recommendations$.risk.summary.numberOfUsers}>
                      {NUMBER_FORMATTER.format(summary.numberOfUsers)}
                    </Typography>
                    <Typography variant="caption">Total Users</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      data-uid={recommendations$.risk.summary.numberOfRecommendations}
                    >
                      {NUMBER_FORMATTER.format(summary.numberOfRecommendations)}
                    </Typography>
                    <Typography variant="caption"># Recommendations</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      data-uid={recommendations$.risk.summary.recommendationsRun}
                    >
                      {NUMBER_FORMATTER.format(summary.totalRecommendationsRun)}
                    </Typography>
                    <Typography variant="caption">Total Run</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      data-uid={recommendations$.risk.summary.totalExpiringArr}
                    >
                      {CURRENCY_FORMATTER.format(summary.totalExpiringArr)}
                    </Typography>
                    <Typography variant="caption">Expiring ARR</Typography>
                  </Box>
                  {showDemoData ? (
                    <>
                      {summary.totalNewContractValue != null ? (
                        <Box>
                          <Typography
                            variant="h4"
                            data-uid={recommendations$.risk.summary.totalNewContractValue}
                          >
                            {CURRENCY_FORMATTER.format(summary.totalNewContractValue)}
                          </Typography>
                          <Typography variant="caption">Total New Contract Value</Typography>
                        </Box>
                      ) : null}
                      {summary.totalNRR != null ? (
                        <Box>
                          <Typography
                            variant="h4"
                            data-uid={recommendations$.risk.summary.totalNRR}
                          >
                            {WHOLE_PERCENT_NUMBER_FORMATTER.format(summary.totalNRR)}
                          </Typography>
                          <Typography variant="caption">Total NRR %</Typography>
                        </Box>
                      ) : null}
                    </>
                  ) : null}
                </Stack>
              </CardContent>
            </Card>
            <Card elevation={1} sx={{ mt: 3, mx: 3, flexGrow: 1 }}>
              <CustomGrid
                {...gridData}
                loading={loading}
                sx={{ borderColor: 'transparent' }}
                initialState={initialState}
                defaultGroupingExpansionDepth={1}
                groupingColDef={{ ...gridData.groupingColDef, flex: 1, minWidth: 175 }}
                columns={columns}
                rows={rows}
              />
            </Card>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
