import { DataConnectorSource } from '../../../../graphql/generated';

export const getDataConnectorSourceEnumKeyByValue = (value: string): DataConnectorSource => {
  const entries = Object.entries(DataConnectorSource);
  for (const [key, val] of entries) {
    if (val === value) {
      return DataConnectorSource[key as keyof typeof DataConnectorSource];
    }
  }

  return DataConnectorSource.Salesforce;
};
