import { useMutation } from '@apollo/client';
import { Star, StarBorder } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  FormControl,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import {
  AssignActivitiesToUserDocument,
  AssignActivityToUserDocument,
  FollowSprintDocument,
  GetMySprintsDocument,
  GetSprintDashboardDocument,
  UnfollowSprintDocument,
  UpdateActivityStatusDocument,
  UserStatus,
} from '../../../../graphql/generated';
import { useSprint } from '../../../../hooks/sprint';
import { MISSING_USER, useCurrentUserDetails, User } from '../../../../hooks/user';
import { ResultType } from '../../../../models/result';
import {
  Activity,
  ActivityStatus,
  DEFAULT_SPRINT,
  Sprint as SprintModel,
} from '../../../../models/sprint';
import { UUID } from '../../../../models/uuid';
import { sprint$ } from '../../../../selectors';
import { pluralize } from '../../../../utils';
import { EndSprintDialog } from '../../../Dialogs';
import { UserAutocomplete } from '../../../UserAutocomplete';
import { SprintActionCard } from './SprintActionCard';
import { SprintSummary } from './SprintSummary';

export interface UpdateActivityStatusOptions {
  activityId: UUID;
  status: ActivityStatus;
}

export interface AssignActivityToUserOptions {
  activityId: UUID;
  userId: UUID;
}

export const SprintDetails = () => {
  const { sprintId } = useParams();
  const sprintResult = useSprint(sprintId as UUID | undefined);
  const [sprint, setSprint] = useState<SprintModel>(DEFAULT_SPRINT);
  const [endSprintDialogOpen, setEndSprintDialogOpen] = useState(false);
  const [updateStatus] = useMutation(UpdateActivityStatusDocument);
  const [assignActivity] = useMutation(AssignActivityToUserDocument);
  const [assignActivities, { loading: isBulkProcessing }] = useMutation(
    AssignActivitiesToUserDocument,
  );
  const [loading, setLoading] = useState<{ [key: UUID]: boolean }>({});
  const { user } = useCurrentUserDetails();

  const [followSprint, { loading: followSprintLoading }] = useMutation(FollowSprintDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }, { query: GetSprintDashboardDocument }],
  });
  const [unfollowSprint, { loading: unfollowSprintLoading }] = useMutation(UnfollowSprintDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }, { query: GetSprintDashboardDocument }],
  });
  const isFollowDisabled = useMemo(
    () => followSprintLoading || unfollowSprintLoading,
    [followSprintLoading, unfollowSprintLoading],
  );
  const canFollow = useMemo(
    () => user !== MISSING_USER && sprint !== DEFAULT_SPRINT && user.id !== sprint.createdBy.id,
    [user, sprint],
  );

  const updateActivityStatus = useCallback(
    async ({ activityId, status }: UpdateActivityStatusOptions): Promise<void> => {
      setLoading({ ...loading, [activityId]: true });
      await updateStatus({ variables: { activityId, status } });
      setLoading({ ...loading, [activityId]: false });
    },
    [updateStatus, setLoading, loading],
  );

  const assignActivityToUser = useCallback(
    async ({ userId, activityId }: AssignActivityToUserOptions): Promise<void> => {
      setLoading({ ...loading, [activityId]: true });
      await assignActivity({ variables: { userId, activityId } });
      setLoading({ ...loading, [activityId]: false });
    },
    [assignActivity, setLoading, loading],
  );

  const onFollowClick = useCallback(() => {
    if (sprint.isFollowing) {
      unfollowSprint({ variables: { sprintId: sprint.id } });
    } else {
      followSprint({ variables: { sprintId: sprint.id } });
    }
  }, [sprint.isFollowing, sprint.id, followSprint, unfollowSprint]);

  const bulkAssignableSprintActivities = useMemo(
    () => sprint.activities.filter((sa) => !sa.isTerminal),
    [sprint],
  );
  const shouldDisableBulkAssign = useMemo(
    () => bulkAssignableSprintActivities.length === 0,
    [bulkAssignableSprintActivities.length],
  );
  const bulkAssignInputLabel = useMemo(
    () =>
      shouldDisableBulkAssign
        ? 'No Actions to Assign'
        : `Assign ${bulkAssignableSprintActivities.length} ${pluralize({
            count: bulkAssignableSprintActivities.length,
            singular: 'Action',
          })} To`,
    [bulkAssignableSprintActivities.length, shouldDisableBulkAssign],
  );

  const handleOnUserSelect = useCallback(
    async (selectedUser: User) => {
      if (selectedUser.status === UserStatus.Active && !shouldDisableBulkAssign) {
        await assignActivities({
          variables: {
            userId: selectedUser.id,
            activityIds: bulkAssignableSprintActivities.map((sa) => sa.id),
          },
        });
      }
    },
    [assignActivities, bulkAssignableSprintActivities, shouldDisableBulkAssign],
  );

  const filterBulkUserOptions = useCallback(
    (allOptions: User[]) => allOptions.filter((user) => user.status === UserStatus.Active),
    [],
  );

  useEffect(() => {
    sprintResult.state === ResultType.Value ? setSprint(sprintResult.value) : undefined;
    return () => setSprint(DEFAULT_SPRINT);
  }, [sprintResult.state, sprintResult.value]);

  if (sprintResult.state === ResultType.Loading) {
    return <LinearProgress />;
  }

  if (sprintResult.state === ResultType.NoValue || sprintResult.state === ResultType.Error) {
    return <Alert severity="error">Not able to get that sprint.</Alert>;
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Stack sx={{ maxWidth: '1400px' }}>
        <Stack direction="row" alignItems="stretch" justifyContent="space-between">
          <Box sx={{ mt: 6, mx: 6, mb: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: 400 }} data-uid={sprint$.details.title}>
              {sprint.name.replace(`- ${sprint.activities[0]?.account.name}`, '')}
            </Typography>
            <Link
              component={RouterLink}
              to={`/customer-details/${sprint.activities[0]?.account.id}`}
              data-uid={'fixme'}
              sx={{
                maxWidth: '45rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography variant="h5" color="primary" title={sprint.activities[0]?.account.name}>
                {sprint.activities[0]?.account.name}
              </Typography>
            </Link>
          </Box>
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
            {canFollow && (
              <Button
                variant="contained"
                startIcon={sprint.isFollowing ? <Star /> : <StarBorder />}
                sx={{
                  marginBottom: 3,
                  marginRight: 3,
                  backgroundColor: (theme) => theme.palette.magic.followSprintButtonBackground.main,
                  color: (theme) => theme.palette.text.primary,
                  '&:hover': {
                    backgroundColor: (theme) =>
                      theme.palette.magic.followSprintButtonBackground.dark,
                  },
                }}
                onClick={onFollowClick}
                disabled={isFollowDisabled}
              >
                {sprint.isFollowing ? 'Unfollow' : 'Follow'}
              </Button>
            )}
            <Button
              variant="contained"
              color="error"
              sx={{ marginBottom: 3, marginRight: 3 }}
              onClick={() => setEndSprintDialogOpen(true)}
            >
              End Sprint
            </Button>
          </Stack>
        </Stack>
        <SprintSummary
          activities={sprint.activities}
          startDate={sprint.start}
          endDate={sprint.end}
          createdBy={sprint.createdBy.name}
        />
        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ m: 3 }}>
          <FormControl
            sx={{ minWidth: 200, backgroundColor: (theme) => theme.palette.primary.contrastText }}
          >
            <UserAutocomplete
              dataUid={sprint$.details.bulkAssignInput}
              disabled={shouldDisableBulkAssign}
              loading={isBulkProcessing}
              value={undefined}
              handleOnUserSelect={handleOnUserSelect}
              filterOptions={filterBulkUserOptions}
              renderInputParams={{
                label: bulkAssignInputLabel,
              }}
            />
          </FormControl>
        </Stack>
        <Stack
          sx={{
            mx: 3,
            '& > :not(:first-of-type)': {
              marginTop: 2,
            },
            marginBottom: 2,
          }}
        >
          {sprint.activities.map((activity: Activity, index: number) => {
            return (
              <SprintActionCard
                key={`sprint-action-card-activity-${activity.id}`}
                onActivityChange={(e) =>
                  updateActivityStatus({
                    activityId: activity.id,
                    status: e.target.value as ActivityStatus,
                  })
                }
                onAssigneeChange={({ userId }) =>
                  assignActivityToUser({ userId, activityId: activity.id })
                }
                index={index}
                activity={activity}
                sprintId={sprint.id}
                loading={loading[activity.id]}
              />
            );
          })}
        </Stack>
      </Stack>
      <EndSprintDialog
        open={endSprintDialogOpen}
        onClose={() => setEndSprintDialogOpen(false)}
        sprint={sprintResult.value}
      />
    </Box>
  );
};
