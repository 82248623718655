import { Link, Stack } from '@mui/material';
import { isFuture } from 'date-fns';
import { format } from 'date-fns-tz';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CURRENCY_FORMATTER, DATE_FORMAT_LONG_LOCALIZED } from '../../../../constants';
import { useUserDateSettings } from '../../../../contexts/userSettingsContext';
import { RiskRecommendationFields } from '../../../../models/recommendation';
import { UUID } from '../../../../models/uuid';
import { userWork$ } from '../../../../selectors';
import {
  PERCENT_NUMBER_FORMATTER,
  pluralize,
  toDescriptiveDateTitle,
  toOptionalString,
} from '../../../../utils';
import { CustomScoreChip } from '../../../ScoreChip';
import { DynamicScoreChip } from '../../../ScoreChip/DynamicScoreChip';
import { HeaderTypography, ValueTypography } from './RecommendationFields';

export const RiskRecommendationField = <K extends keyof RiskRecommendationFields>({
  field,
  data,
  recId,
}: {
  recId: UUID;
  field: K;
  data: RiskRecommendationFields;
}) => {
  const { locale } = useUserDateSettings();
  switch (field) {
    case 'expiringArr':
    case 'contractExpiringARR': {
      const expiringArrValue =
        data.contractExpiringARR != null ? (
          CURRENCY_FORMATTER.format(data.contractExpiringARR)
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.contractExpiringARR)}
          >
            {expiringArrValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Expiring ARR
          </HeaderTypography>
        </Stack>
      );
    }
    case 'customerTotalARR': {
      const arrRiskValue =
        data.customerTotalARR != null ? (
          CURRENCY_FORMATTER.format(data.customerTotalARR)
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.customerTotalARR)}
          >
            {arrRiskValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            ARR Risk
          </HeaderTypography>
        </Stack>
      );
    }
    case 'link': {
      const linkValue = data.name;
      return (
        <Stack direction="column">
          {data.link != null ? (
            <Link component={RouterLink} to={data.link}>
              <ValueTypography
                data-uid={userWork$.recommendations.fieldValue(recId, field)}
                sx={{
                  maxWidth: '12rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                title={toOptionalString(linkValue)}
              >
                {linkValue}
              </ValueTypography>
            </Link>
          ) : (
            <ValueTypography
              data-uid={userWork$.recommendations.fieldValue(recId, field)}
              sx={{
                maxWidth: '12rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={toOptionalString(linkValue)}
            >
              {linkValue}
            </ValueTypography>
          )}
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Contract
          </HeaderTypography>
        </Stack>
      );
    }
    case 'contractEndDate': {
      const expirationDateValue =
        data.contractEndDate != null ? (
          format(data.contractEndDate, DATE_FORMAT_LONG_LOCALIZED, { timeZone: 'UTC', locale })
        ) : (
          <>&ndash;</>
        );
      const isFutureExpiration = data.contractEndDate != null && isFuture(data.contractEndDate);
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{ minWidth: '7.65rem' }}
            title={toDescriptiveDateTitle(data.contractEndDate, {
              prefix: isFutureExpiration ? 'Expires ' : 'Expired ',
              locale,
            })}
          >
            {expirationDateValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Contract {isFutureExpiration ? 'Expires' : 'Expired'}
          </HeaderTypography>
        </Stack>
      );
    }
    case 'createdOn': {
      const createdOnValue =
        data.createdOn != null ? (
          format(data.createdOn, DATE_FORMAT_LONG_LOCALIZED, { timeZone: 'UTC', locale })
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{ minWidth: '7.65rem' }}
            title={toDescriptiveDateTitle(data.createdOn, { prefix: 'Recommended ', locale })}
          >
            {createdOnValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Recommended On
          </HeaderTypography>
        </Stack>
      );
    }
    case 'contractEndFiscalQuarter': {
      const expirationQuarterValue =
        data.contractEndFiscalQuarter != null ? data.contractEndFiscalQuarter : <>&ndash;</>;
      return (
        <Stack direction="column">
          <ValueTypography data-uid={userWork$.recommendations.fieldValue(recId, field)}>
            {expirationQuarterValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Closing
          </HeaderTypography>
        </Stack>
      );
    }
    case 'productNames': {
      const contractProductsValue =
        data.productNames != null && data.productNames.length > 0 ? (
          data.productNames.join(', ')
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '20rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={data.productNames?.join(', ')}
          >
            {contractProductsValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Contract {pluralize({ count: data.productNames?.length ?? 0, singular: 'Product' })}
          </HeaderTypography>
        </Stack>
      );
    }
    case 'renewalOwnerEmail':
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.renewalOwnerEmail)}
          >
            {data.renewalOwnerName ?? <>&ndash;</>}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Renewal Lead
          </HeaderTypography>
        </Stack>
      );
    case 'ownerEmail':
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.ownerEmail)}
          >
            {data.ownerName ?? <>&ndash;</>}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Customer Lead
          </HeaderTypography>
        </Stack>
      );
    case 'currentScore': {
      const dataUid = userWork$.recommendations.fieldValue(recId, field);
      let scoreChip = <>&ndash;</>;
      if (data.currentScore?.value != null) {
        switch (data.currentScore.type) {
          case 'ACCOUNT_FULL_CHURN_RISK':
            scoreChip = (
              <CustomScoreChip
                value={parseFloat(data.currentScore.value)}
                color={data.currentScore.color ?? ''}
                label={data.currentScore.category ?? ''}
                data-uid={`${dataUid}--chip`}
              />
            );
            break;
          case 'ACCOUNT_HEALTHSCORE':
          case 'CONTRACT_USAGE_RATING':
            scoreChip = (
              <DynamicScoreChip data-uid={`${dataUid}--chip`} value={data.currentScore.value} />
            );
            break;
          case 'PRODUCT_CONSUMPTION_PERCENT': {
            const rawPercent = parseFloat(data.currentScore.value);
            scoreChip = (
              <>
                {PERCENT_NUMBER_FORMATTER.format(
                  Number.isFinite(rawPercent) ? rawPercent / 100 : rawPercent,
                )}
              </>
            );
            break;
          }
        }
      }
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={dataUid}
            title={toOptionalString(data.currentScore?.value)}
            component="div"
          >
            {scoreChip}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Recommendation Score
          </HeaderTypography>
        </Stack>
      );
    }
    default:
      return (
        <Stack direction="column">
          <ValueTypography data-uid={userWork$.recommendations.fieldValue(recId, field)}>
            &ndash;
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            &ndash;
          </HeaderTypography>
        </Stack>
      );
  }
};
