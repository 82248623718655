import { useMemo } from 'react';

import { Account } from '../models/account';
import {
  AccountXAxisMetric,
  AccountYAxisMetric,
  getAccountBucket,
  getAccountMetric,
} from './useAccountChartConfig';

export type HeatmapTileKey = `${number}-${number}`;

interface HeatmapAccounts {
  [key: HeatmapTileKey]: Account[] | undefined;
}

/**
 * Hook to organize a collection of accounts into tiles that are selectable by coordinate.
 * @param accounts to organize by heatmap tile
 * @param xAxisMetric the metric used to bucket accounts on the x-axis
 * @param yAxisMetric the metric used to bucket accounts on the y-axis
 * @returns a dictionary of accounts in the heatmap
 */
export function useHeatmapAccounts(
  accounts: Account[],
  xAxisMetric: AccountXAxisMetric,
  yAxisMetric: AccountYAxisMetric,
): HeatmapAccounts {
  return useMemo(() => {
    const heatmapAccounts: HeatmapAccounts = {};

    for (const account of accounts) {
      // default to the 0-0 tile if the account isn't bucketable
      const xBucket = getAccountBucket(getAccountMetric(account, xAxisMetric), xAxisMetric) ?? 0;
      const yBucket = getAccountBucket(getAccountMetric(account, yAxisMetric), yAxisMetric) ?? 0;

      const key: HeatmapTileKey = `${xBucket}-${yBucket}`;
      const tileAccounts = heatmapAccounts[key];

      // if an account already exists on that tile, just add this one to the list
      // otherwise, we need to create a new list of accounts for that tile
      if (tileAccounts != null) {
        heatmapAccounts[key] = [...tileAccounts, account];
      } else {
        heatmapAccounts[key] = [account];
      }
    }

    return heatmapAccounts;
  }, [accounts, xAxisMetric, yAxisMetric]);
}
