import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

interface LoadingPDFProps {
  assetName?: string;
}
export const LoadingPDF = ({ assetName = 'PDF' }: LoadingPDFProps) => (
  <Box
    sx={{
      height: 'calc(100vh - 8rem)',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack spacing={1} direction="row">
      <CircularProgress size={24} />
      <Typography variant="overline">Loading {assetName}...</Typography>
    </Stack>
  </Box>
);
