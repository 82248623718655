import { Box, Link, List, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { useUserSettingsContext } from '../../../contexts/userSettingsContext';
import { objective$ } from '../../../selectors';
import { pluralize } from '../../../utils';

/**
 * Account like values so we don't need to pull in the whole account interface.
 */
interface AccountOverviewFields {
  /**
   * The current ARR for the account.
   */
  currentArr: number | null;
  /**
   * The raw pipeline for the account.
   */
  rawPipeline: number | null;
  /**
   * The account name
   */
  name: string;
}

interface SprintAccountSelectionOverviewProps {
  accounts: AccountOverviewFields[];
}

export const SprintAccountSelectionOverview = ({
  accounts,
}: SprintAccountSelectionOverviewProps) => {
  const { formatCurrencyShort } = useUserSettingsContext();
  const { arrValue, pipelineValue } = useMemo(
    () => ({
      arrValue: formatCurrencyShort(accounts.reduce((arr, acc) => arr + (acc.currentArr ?? 0), 0)),
      pipelineValue: formatCurrencyShort(
        accounts.reduce((pipeline, acc) => pipeline + (acc.rawPipeline ?? 0), 0),
      ),
    }),
    [accounts, formatCurrencyShort],
  );

  return (
    <Stack direction="row" spacing={3}>
      <Box>
        <Tooltip
          placement="left-start"
          title={
            <Box>
              {accounts.map((account) => (
                <List key={`account-list-tooltip-${account.name}`} sx={{ margin: 0, padding: 0 }}>
                  <ListItemText>
                    <Typography variant="caption">{account.name}</Typography>
                  </ListItemText>
                </List>
              ))}
            </Box>
          }
        >
          <Typography variant="h5" data-uid={objective$.card.totalCustomers}>
            <Link sx={{ cursor: 'pointer' }}>{accounts.length}</Link>
          </Typography>
        </Tooltip>
        <Typography variant="caption">{`${pluralize({ count: accounts.length, singular: 'Customer' })}`}</Typography>
      </Box>
      <Box>
        <Typography variant="h5" data-uid={objective$.card.totalArr}>
          {arrValue}
        </Typography>
        <Typography variant="caption">ARR</Typography>
      </Box>
      <Box>
        <Typography variant="h5" data-uid={objective$.card.totalPipeline}>
          {pipelineValue}
        </Typography>
        <Typography variant="caption">Pipeline</Typography>
      </Box>
    </Stack>
  );
};
