import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { User } from '../../../hooks/user';
import { UUID } from '../../../models/uuid';
import { sprint$ } from '../../../selectors';

export interface SprintInviteUserDialogProps {
  /**
   * The activity ID that triggered this invite.
   */
  activityId: UUID;
  /**
   * The sprint ID of the activity that triggered this invite.
   */
  sprintId: UUID;
  userToInvite: User;
  open: boolean;
  onClose: () => void;
  sendUserInvite: () => Promise<void>;
}

export const SprintInviteUserDialog = ({
  sprintId,
  userToInvite,
  open,
  onClose,
  sendUserInvite,
}: SprintInviteUserDialogProps) => {
  const [sent, setSent] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const sprintHref = useMemo(() => `${window.location.origin}/sprint/${sprintId}`, [sprintId]);

  const handleUserInvite = useCallback(async () => {
    setSent(true);
    await sendUserInvite();
    setSent(false);
    setSnackbarOpen(true);
    onClose();
  }, [onClose, sendUserInvite]);

  // reset state on invitee change
  useEffect(() => {
    setSent(false);
    setSnackbarOpen(false);
  }, [userToInvite]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Invite Users to Your Sprint</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography component="span" fontWeight={500}>
              {userToInvite.name}
            </Typography>{' '}
            is not yet an active user of Reef.ai;
            <br />
            invite them to collaborate with you!
            <br />
            <br />
            You also can{' '}
            <Link
              data-uid={sprint$.inviteUserDialog.link}
              href={sprintHref}
              sx={{ cursor: 'pointer' }}
            >
              copy a link
            </Link>{' '}
            to this sprint and share it with them directly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-uid={sprint$.inviteUserDialog.cancelBtn}
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-uid={sprint$.inviteUserDialog.inviteBtn}
            disabled={sent}
            variant="contained"
            color="primary"
            endIcon={sent ? <CircularProgress size={24} /> : undefined}
            onClick={handleUserInvite}
          >
            {sent ? 'Inviting' : 'Invite'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        data-uid={sprint$.inviteUserDialog.success}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        message={`Invite sent to ${userToInvite.name}!`}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};
