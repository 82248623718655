import { kebabCase } from 'lodash';

import { ObjValues } from '../types/util';

const chartTypeSelect = {
  select: (variant: string) => `analytics--chart-type-select--${variant}-select`,
  item: (variant: string, type?: string) =>
    `analytics--chart-type-select--${variant}-select-item--${kebabCase(type)}`,
} as const;

const groupingFieldSelect = {
  select: 'analytics--grouping-field-select--select',
  item: (type?: string) => `analytics--grouping-field-select--select-item--${kebabCase(type)}`,
} as const;

const scoreFieldSelect = {
  select: 'analytics--score-field-select--select',
  item: (type?: string) => `analytics--score-field-select--select-item--${kebabCase(type)}`,
} as const;

const weightingFieldSelect = {
  select: 'analytics--weighting-field-select--select',
  item: (type?: string) => `analytics--weighting-field-select--select-item--${kebabCase(type)}`,
} as const;

const fiscalYearSelect = {
  select: 'analytics--fiscal-year-select--select',
  item: (type?: string) => `analytics--fiscal-year-select--select-item--${kebabCase(type)}`,
} as const;

const renewalOwnersSelect = {
  select: 'analytics--renewal-owners-select--select',
  item: (type?: string) => `analytics--renewal-owners-select--select-item--${kebabCase(type)}`,
} as const;

const fiscalQuarterToggle = {
  group: 'analytics--fiscal-quarter-group--group',
  button: (type?: string) => `analytics--fiscal-quarter-group--toggle-button--${kebabCase(type)}`,
} as const;

const page = {
  root: 'analytics--page--root',
  header: 'analytics--page--header',
  grid: 'analytics--page--grid',
} as const;

export const analytics$ = {
  page,
  chartTypeSelect,
  groupingFieldSelect,
  scoreFieldSelect,
  weightingFieldSelect,
  fiscalYearSelect,
  fiscalQuarterToggle,
  renewalOwnersSelect,
} as const;

export type Analytics$Values =
  | ObjValues<typeof page>
  | ObjValues<typeof chartTypeSelect>
  | ObjValues<typeof groupingFieldSelect>
  | ObjValues<typeof scoreFieldSelect>
  | ObjValues<typeof weightingFieldSelect>
  | ObjValues<typeof fiscalYearSelect>
  | ObjValues<typeof fiscalQuarterToggle>
  | ObjValues<typeof renewalOwnersSelect>;
