import { Create, Link as LinkIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { UserStatus } from '../../../../graphql/generated';
import { User } from '../../../../hooks/user';
import { useSprintActivityNoteDialog } from '../../../../hooks/useSprintActivityNoteDialog';
import { useSprintInviteUserDialog } from '../../../../hooks/useSprintInviteUserDialog';
import { Activity, ACTIVITY_STATUS_LABELS } from '../../../../models/sprint';
import { UUID } from '../../../../models/uuid';
import { sprint$, tooltip$ } from '../../../../selectors';
import { SprintActivityNoteDialog } from '../../../Dialogs/SprintActivityNoteDialog';
import { SprintInviteUserDialog } from '../../../Dialogs/SprintInviteUserDialog';
import { ActivityStatusSelect } from '../../../Selects/ActivityStatusSelect/ActivityStatusSelect';
import { UserAutocomplete } from '../../../UserAutocomplete';

export interface SprintActionCardProps {
  index: number;
  sprintId: UUID;
  activity: Activity;
  onActivityChange: (e: SelectChangeEvent) => void;
  onAssigneeChange: ({ userId }: { userId: UUID }) => void;
  loading: boolean;
}

export const SprintActionCard = ({
  index,
  sprintId,
  activity,
  onActivityChange,
  onAssigneeChange,
  loading,
}: SprintActionCardProps) => {
  const getOptionDisabled = useCallback((user: User) => !user.isValidAssignee, []);
  const filterOptions = useCallback(
    (allOptions: User[]) => allOptions.filter((user) => user.status !== UserStatus.Deactivated),
    [],
  );

  const { promptUserInvite, ...inviteDialogProps } = useSprintInviteUserDialog({
    sprintId,
  });
  const { editNote, ...noteDialogProps } = useSprintActivityNoteDialog();
  const clickNote = useCallback(() => editNote({ activity }), [editNote, activity]);

  const handleOnUserSelect = useCallback(
    (selectedUser: User) => {
      if (selectedUser.status === UserStatus.Active) {
        onAssigneeChange({ userId: selectedUser.id });
      }
      if (selectedUser.status === UserStatus.NotActive) {
        promptUserInvite({ activity, userToInvite: selectedUser });
      }
    },
    [promptUserInvite, activity, onAssigneeChange],
  );

  const tooltipContent = useMemo(
    () =>
      activity.isTerminal
        ? `${ACTIVITY_STATUS_LABELS[activity.status]} actions cannot be re-assigned.`
        : undefined,
    [activity.isTerminal, activity.status],
  );

  return (
    <Paper
      key={`sprint-activity-${activity.id}`}
      variant="outlined"
      elevation={0}
      sx={{ paddingTop: 3, paddingBottom: 2 }}
    >
      <Box sx={{ paddingX: 3, paddingBottom: 3 }}>
        <Typography variant="caption">{`ACTION ${index + 1}`}</Typography>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {activity.action.title}
        </Typography>
      </Box>
      <Box sx={{ height: '2px' }}>{loading ? <LinearProgress /> : <Divider />}</Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          paddingX: 3,
          paddingTop: 2,
          '& > :not(:last-child)': {
            marginRight: 4,
          },
        }}
      >
        <FormControl sx={{ minWidth: 180 }}>
          <ActivityStatusSelect
            activityId={activity.id}
            onStatusChange={onActivityChange}
            value={activity.status}
            disabled={loading}
          />
        </FormControl>
        <FormControl sx={{ minWidth: 180 }}>
          <Tooltip title={tooltipContent} followCursor>
            <Box sx={{ display: 'contents' }}>
              <UserAutocomplete
                disabled={loading || activity.isTerminal}
                dataUid={`activity-${activity.id}-user-autocomplete`}
                value={activity.assignee as User}
                handleOnUserSelect={handleOnUserSelect}
                getOptionDisabled={getOptionDisabled}
                filterOptions={filterOptions}
                renderInputParams={{
                  label: 'Assigned To',
                }}
              />
            </Box>
          </Tooltip>
        </FormControl>
        <FormControl sx={{ minWidth: 180 }}>
          <TextField
            fullWidth
            size="small"
            InputLabelProps={{ shrink: false }}
            label={
              <Box sx={{ display: 'flex' }}>
                <LinkIcon />
                <Box sx={{ ml: 1 }}>
                  {activity.action.links.length} Link{activity.action.links.length === 1 ? '' : 's'}
                </Box>
              </Box>
            }
            disabled={activity.action.links.length === 0}
            select={activity.action.links.length > 0}
          >
            {activity.action.links.map((link) => (
              <MenuItem
                key={link.id}
                component="a"
                href={link.url}
                target="_blank"
                sx={{ ':hover': { textDecoration: 'underline' } }}
              >
                {link.title} <OpenInNewIcon sx={{ ml: 'auto' }} fontSize="inherit" />
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl sx={{ flexGrow: 1 }}>
          <Tooltip
            data-uid={tooltip$.component}
            title={activity.note && <Typography variant="caption">{activity.note}</Typography>}
          >
            <TextField
              label="Notes"
              variant="outlined"
              size="small"
              value={activity.note ?? ''}
              onClick={clickNote}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Create
                      onClick={clickNote}
                      sx={{ cursor: 'pointer' }}
                      data-uid={sprint$.activityNoteDialog.openDialogBtn}
                    />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </Tooltip>
        </FormControl>
      </Stack>
      <SprintActivityNoteDialog {...noteDialogProps} />
      <SprintInviteUserDialog {...inviteDialogProps} />
    </Paper>
  );
};
