import { useMutation } from '@apollo/client';
import {
  Add as AddIcon,
  Build as BuildIcon,
  Delete as DeleteIcon,
  DragHandle as DragHandleIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import {
  SetActionPublishStatusDocument,
  UpdateActionRankDocument,
} from '../../../../graphql/generated';
import { Action, Objective } from '../../../../models/sprint';
import { UUID } from '../../../../models/uuid';
import { manage$ } from '../../../../selectors';
import { PlaybookActions } from './PlaybookActions';

interface DraggablePlaybookProps {
  /**
   * The objective this playbook maps to.
   */
  objective: Objective;
  /**
   * Disables reordering of playbooks when set; intended to prevent re-ranking issues when searching.
   */
  reorderDisabled: boolean;
  draggableIndex: number;
  /**
   * Called when the create action button is clicked.
   */
  onCreateAction(): void;
  /**
   * Called when the user clicks the "Edit playbook" button.
   */
  onEditPlaybook(): void;
  /**
   * Called when the delete playbook button is clicked.
   */
  onDeletePlaybook(): void;
  /**
   * Called when the menu button for an action is clicked.
   * @param target element where the menu is called
   * @param action action to open the menu for
   */
  onOpenActionMenu(target: EventTarget & HTMLButtonElement, action: Action): void;
}

export const DraggablePlaybook = ({
  objective,
  draggableIndex,
  reorderDisabled,
  onCreateAction,
  onEditPlaybook,
  onDeletePlaybook,
  onOpenActionMenu,
  ...rest
}: DraggablePlaybookProps) => {
  const [loadingAction, setLoadingAction] = useState<UUID>();
  const [setActionPublished, publishActionResult] = useMutation(SetActionPublishStatusDocument, {
    // this allows us to leave the switch in a "disabled" state while the refetch is still
    // retrieving the final published status
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (publishActionResult.called && !publishActionResult.loading) {
      setLoadingAction(undefined);
      publishActionResult.reset();
    }
  }, [publishActionResult]);

  const [reRankAction, reRankResult] = useMutation(UpdateActionRankDocument, {
    // this allows us to show the progress and put the dnd in a disabled state while the refetch
    // is still getting the order of the actions
    // we _don't_ need to refetch the objective node because the rank is a property of the
    // action itself
  });

  const [reRankLoading, setReRankLoading] = useState(false);

  useEffect(() => {
    if (reRankResult.called && !reRankLoading) {
      reRankResult.reset();
    }
  }, [reRankLoading, reRankResult]);

  return (
    <Draggable draggableId={objective.id} index={draggableIndex} isDragDisabled={reorderDisabled}>
      {(provided) => (
        <Accordion
          {...rest}
          {...provided.draggableProps}
          ref={provided.innerRef}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            {...provided.dragHandleProps}
            sx={{ bgcolor: (theme) => theme.palette.grey[100], cursor: 'grab' }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Box sx={{ display: 'flex', my: 'auto', mr: 3 }}>
              <IconButton size="small" disabled={reorderDisabled}>
                <DragHandleIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography data-rank={objective.rank} sx={{ my: 'auto' }}>
              {objective.title}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails sx={{ bgcolor: (theme) => theme.palette.grey[100] }}>
            <Card>
              {reRankResult.called && reRankLoading && <LinearProgress />}
              {!objective.hasActions ? (
                <Alert severity="info">
                  This objective will be unusable until you add at least one action.
                </Alert>
              ) : (
                <PlaybookActions
                  forObjective={objective.id}
                  publishActionDisabled={loadingAction}
                  onPublishAction={(actionId, published) => {
                    setLoadingAction(actionId);
                    setActionPublished({ variables: { actionId, published } });
                  }}
                  reorderDisabled={reRankResult.called && reRankLoading}
                  onReorderActions={async (order) => {
                    setReRankLoading(true);
                    // just update the rank for all of the actions at once
                    await Promise.all(
                      order.map((actionId, index) =>
                        reRankAction({
                          variables: { actionId, rank: index },
                        }),
                      ),
                    );

                    setReRankLoading(false);
                  }}
                  onOpenActionMenu={onOpenActionMenu}
                />
              )}
            </Card>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Box sx={{ flexGrow: 1, display: 'flex', ml: 1 }}>
              <Tooltip title={<Typography>Add an action to this Playbook</Typography>}>
                <Box sx={{ mr: 1 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    data-uid={manage$.playbooks.createActionBtn(draggableIndex)}
                    startIcon={<AddIcon />}
                    color="primary"
                    onClick={onCreateAction}
                  >
                    Create action
                  </Button>
                </Box>
              </Tooltip>
              <Tooltip title={<Typography>Change the name of this Playbook</Typography>}>
                <Box sx={{ mr: 1 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<BuildIcon />}
                    color="primary"
                    onClick={onEditPlaybook}
                  >
                    Edit playbook
                  </Button>
                </Box>
              </Tooltip>
              <Tooltip title={<Typography>Remove this Playbook from Reef</Typography>}>
                <Box sx={{ mr: 1 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={onDeletePlaybook}
                  >
                    Delete playbook
                  </Button>
                </Box>
              </Tooltip>
            </Box>
            <Box sx={{ mr: 2 }}>
              <FormControlLabel
                label={<Typography variant="caption">Published</Typography>}
                labelPlacement="start"
                control={<Switch disabled checked />}
              />
            </Box>
          </AccordionActions>
        </Accordion>
      )}
    </Draggable>
  );
};
