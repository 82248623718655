import { ApolloError } from '@apollo/client';
import { Alert, AlertProps } from '@mui/material';
import React, { useMemo } from 'react';

import { toLoggableErrorMessages } from '../../utils';

interface FailedResultProps extends AlertProps {
  resultType: string;
  error?: ApolloError;
}
export const FailedResult = ({ resultType, error, ...alertProps }: FailedResultProps) => {
  const message = useMemo(() => `Failed to retrieve user's ${resultType}`, [resultType]);
  console.warn(...toLoggableErrorMessages(message, error));

  return (
    <Alert severity="error" {...alertProps}>
      {message}
    </Alert>
  );
};
