import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import React, { PropsWithChildren, useRef } from 'react';

import type { Account } from '../graphql/generated';
import { useReefFlags } from '../hooks/flags';
import { AuthStatus, useReefAuthContext, useReefAuthService } from './reefAuthContext';

interface ReefApolloProviderProps {
  /**
   * The URI used to fetch graphql queries and mutations.
   */
  graphqlUri: string;
}

export const ReefApolloProvider = ({
  graphqlUri,
  children,
}: PropsWithChildren<ReefApolloProviderProps>) => {
  const authService = useReefAuthService();
  const [authCtx, { logout }] = useReefAuthContext();

  const { apolloDevtoolsEnabled } = useReefFlags();

  // pulled from
  // https://github.com/apollographql/apollo-client-devtools/issues/298#issuecomment-1469554061
  const apolloClient = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

  if (apolloClient.current == null) {
    const cache = new InMemoryCache({
      typePolicies: {
        // an account can exist in a "snapshot" state where it is loaded at a specific time
        // so we need to index it into the cache based on when it was last updated
        Account: { keyFields: ['id', 'lastUpdated'] satisfies Array<keyof Account> },
        // these types don't have id's and aren't merge-able
        Client: { merge: true },
        UserAssociations: { merge: true },
        RawAccountData: { merge: true },
        ReefAccountData: { merge: true },
        Filter: {
          fields: {
            nodes: {
              // throw out the old set of nodes
              merge(_, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    });

    apolloClient.current = new ApolloClient({
      cache,
      connectToDevTools: apolloDevtoolsEnabled,
      // we can't really guarantee that our data will be consistent, so we want users
      // to refetch queries _all_ the time
      defaultOptions: {
        // this will give us some caching benefits, but also prioritizing keeping the data in-sync
        // with the backend services
        watchQuery: { fetchPolicy: 'cache-and-network' },
        query: { fetchPolicy: 'network-only' },
      },
      // the user is logged in
      // define the list of middleware/apollo links
      // https://www.apollographql.com/docs/react/networking/advanced-http-networking/
      link: ApolloLink.from([
        setContext(async (_, ctx) => {
          // we need to get the current session on _every_ request so we can trigger
          // refreshing tokens. because this link gets built on every apollo request/connection
          // we want to ask for the token here and join that Observable with the `forward`
          // Observable result (by using a flatMap operation)
          //
          // Calling `getCurrentSession` won't necessarily trigger a cognito refresh call every time -
          // only when the access or id tokens in the auth session are expired
          const session = await authService.getCurrentSession();
          // if the user was previously signed-in, but we weren't able to refresh the
          // auth token then we should log them out (and kick them back to the login flow)
          if (session == null && authCtx.status === AuthStatus.SignedIn) {
            // TODO: [REEF-1176] navigate to "/login" when the user is auto-logged out
            await logout();
            return ctx;
          }

          // return new context with auth header
          return {
            ...ctx,
            headers: {
              Authorization: session?.getIdToken().getJwtToken(),
              Accept: 'charset=utf-8',
            },
          };
        }),
        new RetryLink({ attempts: { max: 3 } }),
        new HttpLink({ uri: graphqlUri }),
      ]),
    });
  }

  return <ApolloProvider client={apolloClient.current}>{children}</ApolloProvider>;
};
