import { Box } from '@mui/material';
import { GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-premium';
import React from 'react';

import {
  AccountXAxisMetric,
  AccountYAxisMetric,
  getAccountBucket,
} from '../../../../hooks/useAccountChartConfig';
import { CustomNumericalMetric } from '../../../../models/account';
import { CustomScoreChip, ScoreQualityChip } from '../../../ScoreChip';
import { NullScoreTooltip } from '../../../Tooltips';
import { CustomersGridRowModel } from '../../ListOfCustomersTable/types';
import { FieldWrapper } from './FieldWrapper';

// TODO(REEF-1627): unify number formatting with app util
const NUMBER_FORMATTER = new Intl.NumberFormat('en-US');

type ScoreCellProps = GridRenderCellParams<CustomersGridRowModel, number>;
export const LegacyScoreCell = ({ field, value, row }: ScoreCellProps) => {
  const scoreField = field as AccountXAxisMetric | AccountYAxisMetric;
  const level = getAccountBucket(value ?? null, scoreField);
  return (
    <FieldWrapper field={field}>
      {level != null ? (
        // don't show a score chip if the account isn't bucket-able because there is
        // no valid score to show
        <ScoreQualityChip
          level={level}
          description={<NullScoreTooltip field={scoreField} accountName={row.name} />}
          score={value}
          scoreVariant="single-score"
        />
      ) : (
        <Box
          sx={{
            // use default cursor because the &ndash; will force a "text" cursor which doesn't feel right
            // for a hover effect
            cursor: 'default',
          }}
          title={
            // if `value` is undefined, it will result in a NaN, but format's type expects a number or bigint
            // so if value is undefined, just coerce it to a NaN so it outputs a NaN title
            NUMBER_FORMATTER.format(value ?? NaN)
          }
        >
          &ndash;
        </Box>
      )}
    </FieldWrapper>
  );
};

export const CustomScoreCell = ({
  field,
  value,
  rowNode,
}: GridRenderCellParams<GridRowModel, CustomNumericalMetric>) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <CustomScoreChip value={value.value} label={value.category} color={value.color} />
      ) : (
        missingValue
      )}
    </FieldWrapper>
  );
};
