/** Filter Details */
import { kebabCase } from 'lodash';

import { ObjValues } from '../types/util';

const overview = {
  component: 'filters-page-header-component',
  filterBtn: 'header-filter-btn',
  clearChangesBtn: 'filter-header-clear-changes-btn',
  searchCustomersInput: 'filter-header-search-customers-input',
} as const;

const details = {
  customersByActionTable: 'filter-page-by-action-customer-table',
  customersByActionTableTitle: 'filter-page-by-action-customer-table-title',
  filterOperationChip: (type: string) => `${type}-operation-chip`,
  deleteFilterIcon: (type: string) => `${type}-operation-chip-delete-icon`,
  renewsWithinDaysTooltip: 'renews-within-n-days-tooltip',
} as const;

const select = {
  editBtn: 'filter-picker-edit-filter',
  input: 'filter-picker-select-filter',
  optionMenuClass: 'filter-picker-popover',
  option: (name: string) => `filter-picker-menu-option-${name}` as const,
} as const;
const edit = {
  renameInput: 'filter-picker-rename-filter',
  deleteBtn: 'filter-picker-delete-filter',
  saveBtn: 'filter-picker-save-filter',
} as const;

type FilterPicker$Values = ObjValues<typeof edit> | ObjValues<typeof select>;
const filterPicker = {
  select,
  edit,
} as const;

// filter edit operations
const upcomingRenewal = {
  saveBtn: 'upcoming-renewal-filter-save-button',
} as const;
const fiscalQuarter = {
  yearSelect: 'fiscal-quarter-filter-year-select',
  quarterSelect: 'fiscal-quarter-filter-quarter-select',
  saveBtn: 'fiscal-quarter-filter-save-button',
  yearOption: (year: number) => `fiscal-quarter-filter-year-option-${year}` as const,
  quarterOption: (quarter: 1 | 2 | 3 | 4) =>
    `fiscal-quarter-filter-quarter-option-${quarter}` as const,
} as const;
const ownership = {
  saveBtn: (type: string) => `${type}-filter-save-button` as const,
  searchUsersInput: (type: string) => `${type}-filter-search-users-input` as const,
  userOption: (type: string) => `${type}-filter-user-option` as const,
};
const product = {
  saveBtn: 'product-filter-save-button',
  overageInput: 'product-filter-overage-input',
  nameInput: 'product-filter-name-input',
} as const;
const stringMatch = {
  saveBtn: (type: string) => `${type}-string-filter-save-button` as const,
  searchInput: (type: string) => `${type}-string-filter-search-input` as const,
};
const numberBetween = {
  saveBtn: 'number-between-filter-save-button',
  minInput: 'number-between-filter-min-input',
  maxInput: 'number-between-filter-max-input',
} as const;

const drawer = {
  component: 'edit-filter-drawer',
} as const;
const alert = {
  component: 'edit-filter-alert',
} as const;
const accordion = {
  component: (type: string) => `edit-filter--accordion--${kebabCase(type)}` as const,
} as const;

// edit menu
const editFilter = {
  drawer,
  alert,
  accordion,
  fiscalQuarter,
  ownership,
  product,
  stringMatch,
  upcomingRenewal,
  numberBetween,
} as const;

export const filterCustomers$ = {
  details,
  overview,
  filterPicker,
  editFilter,
};

type EditFilter$Values =
  | ObjValues<typeof drawer>
  | ObjValues<typeof alert>
  | ObjValues<typeof accordion>
  | ObjValues<typeof fiscalQuarter>
  | ObjValues<typeof ownership>
  | ObjValues<typeof product>
  | ObjValues<typeof stringMatch>
  | ObjValues<typeof upcomingRenewal>
  | ObjValues<typeof numberBetween>;

export type FilterCustomers$Values =
  | ObjValues<typeof details>
  | FilterPicker$Values
  | EditFilter$Values;
