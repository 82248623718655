import React from 'react';

import { Renewal } from '../../../hooks/renewal';
import {
  GroupingRenewalField,
  useBreakdownByGroupChartConfig,
} from '../../../hooks/useBreakdownByGroupChartConfig';
import { BarChart } from './BarChart';

interface BreakdownByGroupChartProps {
  renewals: Renewal[];
  groupingField: GroupingRenewalField;
}
export const BreakdownByGroupChart = ({ renewals, groupingField }: BreakdownByGroupChartProps) => {
  const barChartProps = useBreakdownByGroupChartConfig({ renewals, groupingField });
  return <BarChart {...barChartProps} />;
};
