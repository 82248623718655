import { ObjValues } from '../types/util';

const page = {
  root: 'data--page--root',
  header: 'data--page--header',
  grid: 'data--page--grid',
} as const;

export const data$ = {
  page,
} as const;

export type Data$Values = ObjValues<typeof page>;
