import { FormControl, SelectChangeEvent } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';

import { ActivityStatus as ApiActivityStatus } from '../../../../graphql/generated';
import { ActivityStatus } from '../../../../models/sprint';
import { UUID } from '../../../../models/uuid';
import { ActivityStatusSelect } from '../../../Selects/ActivityStatusSelect/ActivityStatusSelect';
import { FieldWrapper } from '../Account/FieldWrapper';
import { ActivitiesGridRowModel } from './types';

interface StatusCellProps extends GridRenderCellParams<ActivitiesGridRowModel, ApiActivityStatus> {
  onChange: ({ activityId, status }: { activityId: UUID; status: ActivityStatus }) => void;
  disabled: boolean;
}

export const StatusCell = ({ disabled, field, value, row, onChange }: StatusCellProps) => {
  const onStatusChange = useCallback(
    (e: SelectChangeEvent) =>
      onChange({ activityId: row.id, status: e.target.value as ActivityStatus }),
    [onChange, row.id],
  );

  return (
    <FieldWrapper field={field}>
      <FormControl sx={{ minWidth: 130 }}>
        <ActivityStatusSelect
          activityId={row.id}
          disabled={disabled}
          onStatusChange={onStatusChange}
          value={value}
        />
      </FormControl>
    </FieldWrapper>
  );
};
