import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { RenameObjectiveDocument } from '../../../graphql/generated';
import { Objective } from '../../../models/sprint';
import { UUID } from '../../../models/uuid';

interface OpenEditObjectiveDialogOptions {
  open: true;
  objective: Objective;
}

interface ClosedEditObjectiveDialogOptions {
  open: false;
  objective: Objective | undefined;
}

export type EditObjectiveDialogOptions =
  | OpenEditObjectiveDialogOptions
  | ClosedEditObjectiveDialogOptions;

type EditObjectiveDialogProps = EditObjectiveDialogOptions & {
  /**
   * Called when the dialog is closed.
   */
  onClose(): void;
  /**
   * Called after the dialog has finished closing.
   */
  onExited(): void;
};

export const EditObjectiveDialog = ({
  open,
  onClose,
  onExited,
  objective,
}: EditObjectiveDialogProps) => {
  const [renameObjectiveApi, renameObjectiveResult] = useMutation(RenameObjectiveDocument);

  const [title, setTitle] = useState('');

  useEffect(() => setTitle(objective?.title ?? ''), [objective?.title]);

  useEffect(() => {
    // if we successfully created a client action, we can close this dialog
    if (renameObjectiveResult.data != null) {
      onClose();
    }
  }, [renameObjectiveResult, onClose]);

  const onAfterClose = useCallback(() => {
    // after the dialog is hidden, we want to reset component state
    setTitle('');
    if (renameObjectiveResult.called) {
      renameObjectiveResult.reset();
    }
    onExited();
  }, [renameObjectiveResult, onExited]);

  const renameObjective = useCallback(
    (objectiveId: UUID, title: string) =>
      renameObjectiveApi({
        variables: { objectiveId, title },
      }),
    [renameObjectiveApi],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth TransitionProps={{ onExited: onAfterClose }}>
      <DialogTitle>Edit Playbook</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <TextField
            fullWidth
            label="Playbook title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          // TODO [REEF-1119] we may want to add copy to describe this disabled state
          disabled={title.trim().length === 0 || !open}
          color="primary"
          variant="contained"
          onClick={() => open && renameObjective(objective.id, title)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
