import { ObjValues } from '../types/util';

/** New Objective */
const setObjectiveAndActionsBtn = 'sprint-new-objective-btn';
const objectiveTitle = 'new-sprint-objective-title';
const actionSubheading = 'new-sprint-action-subheading';
interface Objective$NewObjectiveButton {
  setObjectiveAndActionsBtn: typeof setObjectiveAndActionsBtn;
  objectiveTitle: typeof objectiveTitle;
  actionSubheading: typeof actionSubheading;
}
const newObjectiveButton: Objective$NewObjectiveButton = {
  setObjectiveAndActionsBtn,
  objectiveTitle,
  actionSubheading,
};

/** Objective Card */
const cardComponent = 'objective-card';
const title = 'objective-card-title';
// const description = 'objective-card-description';
const totalCustomers = 'objective-card-total-customer';
const totalArr = 'objective-card-total-arr';
const totalPipeline = 'objective-card-total-pipeline';
interface Objective$Card {
  component: typeof cardComponent;
  title: typeof title;
  totalCustomers: typeof totalCustomers;
  totalArr: typeof totalArr;
  totalPipeline: typeof totalPipeline;
}
const card: Objective$Card = {
  component: cardComponent,
  title,
  totalCustomers,
  totalArr,
  totalPipeline,
};

/** Objective List */
const listComponent = 'objectives-list';
const item = (index: number): `objective-selector-${number}` => `objective-selector-${index}`;
interface Objective$List {
  component: typeof listComponent;
  item: typeof item;
}
const list: Objective$List = {
  component: listComponent,
  item,
};

export const objective$ = {
  newObjectiveButton,
  card,
  list,
};
export type Objective$Values =
  | ObjValues<Objective$NewObjectiveButton>
  | ObjValues<Objective$Card>
  | ObjValues<Objective$List>;
