import { useMutation } from '@apollo/client';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LinkInput, UpdateActionDocument } from '../../../graphql/generated';
import { Action } from '../../../models/sprint';
import { UUID } from '../../../models/uuid';
import { ActionLinks } from '../../ActionLinks';

interface OpenEditActionDialogOptions {
  open: true;
  action: Action;
}

interface ClosedEditActionDialogOptions {
  open: false;
  action: Action | undefined;
}

export type EditActionDialogOptions = OpenEditActionDialogOptions | ClosedEditActionDialogOptions;

type EditActionDialogProps = EditActionDialogOptions & {
  /**
   * Called when the dialog is closed.
   */
  onClose(): void;
  /**
   * Called after the dialog has finished closing.
   */
  onExited(): void;
};

export const EditActionDialog = ({ open, onClose, onExited, action }: EditActionDialogProps) => {
  const [updateAction, updateActionResult] = useMutation(UpdateActionDocument);

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [linksToCreate, setLinksToCreate] = useState<LinkInput[]>([]);
  const [linksToDelete, setLinksToDelete] = useState<UUID[]>([]);
  const [linkInputs, setLinkInputs] = useState<LinkInput>();

  useEffect(() => {
    action?.id != null && setTitle(action.title);
    // we want to set the initial title in the rename field whenever
    // the dialog is closed and opened with a new action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action?.id]);

  const onAfterClose = useCallback(() => {
    // after the dialog is hidden, we want to reset component state
    setTitle('');
    setLinksToCreate([]);
    setLinksToDelete([]);
    if (updateActionResult.called) {
      updateActionResult.reset();
    }
    onExited();
  }, [updateActionResult, onExited]);

  const isLinkValid = useCallback(
    (linkInputs: LinkInput): boolean =>
      linkInputs.title.trim().length > 0 && linkInputs.url.trim().length > 0,
    [],
  );

  const titleToUpdate = useMemo(
    () => (title !== action?.title ? title : null),
    [action?.title, title],
  );
  const linksToUpdate = useMemo(() => {
    if (linksToCreate.length === 0 && linksToDelete.length === 0) {
      return null;
    }
    const linkIdsToDelete = new Set(linksToDelete);
    const updatedLinks = (action?.links ?? []).filter(
      (existingLink) => !linkIdsToDelete.has(existingLink.id),
    );
    return [...updatedLinks.map(({ title, url }) => ({ title, url })), ...linksToCreate];
  }, [action?.links, linksToCreate, linksToDelete]);

  const saveAction = useCallback(
    async (actionId: UUID, title: string | null, links: LinkInput[] | null) => {
      setLoading(true);
      await updateAction({ variables: { actionId, title, links } });
      setLoading(false);
      onClose();
    },
    [onClose, updateAction],
  );

  const remainingActionLinks = useMemo(
    () => (action != null ? action.links.filter((link) => !linksToDelete.includes(link.id)) : []),
    [action, linksToDelete],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth TransitionProps={{ onExited: onAfterClose }}>
      <DialogTitle>Edit Action</DialogTitle>
      <DialogContent>
        {loading && <LinearProgress />}
        <Box sx={{ mt: 1 }}>
          <TextField
            fullWidth
            label="Action title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        {remainingActionLinks.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <ActionLinks
              // links in the action
              links={remainingActionLinks}
              mode="edit"
              onDelete={(link) => setLinksToDelete([...linksToDelete, link.id])}
            />
          </Box>
        )}
        {linksToCreate.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <ActionLinks
              // links staged to be created
              links={linksToCreate}
              mode="edit"
              onDelete={(_, index) => {
                setLinksToCreate([
                  ...linksToCreate.slice(0, index),
                  ...linksToCreate.slice(index + 1),
                ]);
              }}
            />
          </Box>
        )}
        {linkInputs != null && (
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                autoFocus
                label="Link title"
                size="small"
                value={linkInputs?.title ?? ''}
                onChange={(e) => setLinkInputs({ ...linkInputs, title: e.target.value })}
              />
              <TextField
                label="Url"
                size="small"
                type="url"
                value={linkInputs?.url ?? ''}
                onChange={(e) => setLinkInputs({ ...linkInputs, url: e.target.value })}
              />
              <Box sx={{ my: 'auto' }}>
                <Button
                  variant="contained"
                  color="primary"
                  // TODO [REEF-1119] we may want to add copy to describe this disabled state
                  disabled={!isLinkValid(linkInputs)}
                  onClick={() => {
                    if (isLinkValid(linkInputs)) {
                      setLinksToCreate([...linksToCreate, linkInputs]);
                      setLinkInputs(undefined);
                    }
                  }}
                >
                  Save Link
                </Button>
              </Box>
            </Stack>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setLinkInputs({ title: '', url: '' })}
          >
            Add Link
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          // TODO [REEF-1119] we may want to add copy to describe this disabled state
          disabled={title.trim().length === 0 || !open}
          color="primary"
          variant="contained"
          onClick={() => open && saveAction(action.id, titleToUpdate, linksToUpdate)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
