import {
  DataGridPremiumProps,
  GridColDef,
  GridInitialState,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import { DateCell, NameCell, NumberCell, StringCell } from '../components/Tables/CustomGrid';
import { FavoriteCell, SprintStatusCell } from '../components/Tables/CustomGrid/Sprint';
import { SprintDashboardGridRowModel } from '../components/Tables/CustomGrid/Sprint/types';
import { SprintStatus } from '../graphql/generated';
import { SprintDashboardElement } from '../models/sprint';
import { useCommonGridHelpers } from './useCommonGridHelpers';

type SprintGridColDef = GridColDef<SprintDashboardGridRowModel>;

const getCreatorName = (params: GridValueGetterParams<SprintDashboardElement, unknown>): string =>
  params.row.createdBy.name;

const renderNameCell = (params: GridRenderCellParams<SprintDashboardGridRowModel, string>) => (
  <NameCell {...params} />
);
const renderStringCell = (params: GridRenderCellParams<SprintDashboardGridRowModel, string>) => (
  <StringCell {...params} />
);
const renderNumberCell = (params: GridRenderCellParams<SprintDashboardGridRowModel, number>) => (
  <NumberCell {...params} />
);

const renderDateCell = (params: GridRenderCellParams<SprintDashboardGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderStatusCell = (
  params: GridRenderCellParams<SprintDashboardGridRowModel, SprintStatus>,
) => <SprintStatusCell {...params} />;

const renderFavoriteCell = (params: GridRenderCellParams<SprintDashboardGridRowModel, boolean>) => (
  <FavoriteCell {...params} />
);

export interface UseSprintsGridProps {
  sprints: SprintDashboardElement[];
}
export type SprintsGridProps = Pick<
  DataGridPremiumProps<SprintDashboardElement>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'initialState'
  | 'localeText'
  | 'rows'
>;
export const useSprintsGrid = ({ sprints }: UseSprintsGridProps): SprintsGridProps => {
  const hideableColMap = useMemo(() => ({}), []);

  const { customNumberComparator: _unused, ...commonProps } = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'Sprint',
  });

  const columns = useMemo((): SprintGridColDef[] => {
    return [
      {
        field: 'name',
        headerName: 'Sprint Name',
        hideable: false,
        flex: 1.5,
        type: 'string',
        renderCell: renderNameCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'creatorName',
        headerName: 'Creator',
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        valueGetter: getCreatorName,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'playbookTitle',
        headerName: 'Playbook',
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'status',
        headerName: 'Sprint Status',
        flex: 1,
        type: 'string',
        renderCell: renderStatusCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'totalAccounts',
        headerName: 'Accounts',
        flex: 0.85,
        type: 'number',
        renderCell: renderNumberCell,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'totalActions',
        headerName: 'Actions',
        flex: 0.75,
        type: 'number',
        renderCell: renderNumberCell,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'start',
        headerName: 'Start',
        flex: 1,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'end',
        headerName: 'End',
        flex: 1,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'isFollowing',
        headerName: 'Following',
        flex: 1,
        type: 'boolean',
        renderCell: renderFavoriteCell,
        groupable: true,
        aggregable: false,
      },
    ];
  }, []);

  const initialState: GridInitialState = useMemo(
    () => ({
      sorting: {
        sortModel: [
          { field: 'status', sort: 'asc' },
          { field: 'end', sort: 'asc' },
        ],
      },
    }),
    [],
  );

  return {
    ...commonProps,
    initialState,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    columns,
    rows: sprints,
  };
};
