import { ApolloError } from '@apollo/client';
import { Card, Stack } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';

import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import { useMyRecommendations } from '../../../../hooks/recommendation';
import { useCurrentUser, useCurrentUserDetails } from '../../../../hooks/user';
import { isGrowth, isRisk, Recommendation } from '../../../../models/recommendation';
import { ResultType } from '../../../../models/result';
import { UUID } from '../../../../models/uuid';
import { userWork$ } from '../../../../selectors';
import { toLoggableErrorMessages } from '../../../../utils';
import { EmptyRecs } from './EmptyRecs';
import { ErrorRecs } from './ErrorRecs';
import { GrowthRecommendationItem } from './GrowthRecommendationItem';
import { LoadingRecs } from './LoadingRecs';
import { RiskRecommendationItem } from './RiskRecommendationItem';

/**
 * Async function to load mocked recommendations + load extra bundles.
 * @param cb dispatch function to update recommendations
 * @param currentUserId user id, to scope mocked recommendation by
 */
async function loadMockedRecommendations(
  cb: (recommendations: Recommendation[]) => void,
  currentUserId: UUID,
): Promise<void> {
  const { someRiskRecommendations, someGrowthRecommendations } = await import(
    '../../../../graphql/mocks'
  );
  const mockedRiskRecommendations = someRiskRecommendations({}, 25);
  const mockedGrowthRecommendations = someGrowthRecommendations({}, 25);
  const allMockedRecommendations = orderBy(
    [...mockedRiskRecommendations, ...mockedGrowthRecommendations].filter(
      (r) => r.owner?.id === currentUserId,
    ),
    'createdOn',
  );

  cb(allMockedRecommendations);
}

interface UseAllRecommendationsOptions {
  showDemoData: boolean;
  currentUserId: UUID;
}

/**
 * Hook to get all recommendations.
 * @param root0 options
 * @param root0.showDemoData flag to show mocked recommendations
 * @param root0.currentUserId user id,to scope mocked recommendations by
 * @returns list of recommendations (or empty)
 */
function useAllRecommendations({
  showDemoData,
  currentUserId,
}: UseAllRecommendationsOptions): [Recommendation[] | null, boolean, ApolloError | null] {
  const result = useMyRecommendations({ options: { skip: showDemoData } });

  const [allRecommendations, setAllRecommendations] = useState<Recommendation[] | null>(null);
  const loading = useMemo(() => result.state === ResultType.Loading, [result.state]);
  const error = useMemo(
    () => (result.state === ResultType.Error ? result.value : null),
    [result.state, result.value],
  );
  useEffect(() => {
    if (showDemoData) {
      loadMockedRecommendations(setAllRecommendations, currentUserId);
    } else if (result.state === ResultType.Value) {
      setAllRecommendations(result.value);
    }
  }, [currentUserId, result.state, result.value, showDemoData]);

  return [allRecommendations, loading, error];
}

export const MyRecommendationsOutlet = () => {
  const { showDemoData } = useUserSettingsContext();
  const currentUser = useCurrentUser();
  const currentUserDetails = useCurrentUserDetails();
  const [allRecommendations, allRecommendationsLoading, allRecommendationsError] =
    useAllRecommendations({
      showDemoData,
      currentUserId: currentUserDetails.user.id,
    });

  const loading = useMemo(
    () => currentUser.state === ResultType.Loading || allRecommendationsLoading,
    [currentUser.state, allRecommendationsLoading],
  );

  if (loading || allRecommendations == null) {
    return (
      <Card data-uid={userWork$.recommendations.section} elevation={0} variant="outlined">
        <LoadingRecs />
      </Card>
    );
  }

  if (allRecommendationsError != null) {
    console.warn(
      ...toLoggableErrorMessages('failed to retrieve recommendations', allRecommendationsError),
    );
    return (
      <Card data-uid={userWork$.recommendations.section} elevation={0} variant="outlined">
        <ErrorRecs />
      </Card>
    );
  }

  if (allRecommendations.length === 0) {
    return (
      <>
        <Card data-uid={userWork$.recommendations.section} elevation={0} variant="outlined">
          <EmptyRecs />
        </Card>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Stack
        direction="column"
        spacing={2}
        sx={{ maxHeight: 'calc(100vh - 6rem - 56px - 49px)', overflow: 'auto' }}
        data-uid={userWork$.recommendations.list}
      >
        {allRecommendations.map((r) => {
          if (isRisk(r)) {
            return <RiskRecommendationItem key={r.id} {...r} />;
          }
          if (isGrowth(r)) {
            return <GrowthRecommendationItem key={r.id} {...r} />;
          }
          return null;
        })}
      </Stack>
      <Outlet />
    </>
  );
};
