import React, { useEffect, useMemo } from 'react';
import { Outlet, OutletProps, useLocation, useMatch, useNavigate } from 'react-router-dom';

import { AuthStatus, useReefAuthContext } from '../../../contexts/reefAuthContext';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

export const ReefAuthNavigatorOutlet = ({ ...outletProps }: OutletProps) => {
  const [ctx] = useReefAuthContext();
  const [returnUrl, setReturnUrl, remove] = useLocalStorage<string>('returnUrl');

  const location = useLocation();
  const navigate = useNavigate();

  const loginMatch = useMatch('login/*');
  const signUpMatch = useMatch('sign-up/*');
  const skeletonMatch = useMatch('skeleton/*');

  const onSkeletonRoute = useMemo(() => skeletonMatch != null, [skeletonMatch]);
  const onAuthRoute = useMemo(
    () => loginMatch != null || signUpMatch != null || onSkeletonRoute,
    [loginMatch, onSkeletonRoute, signUpMatch],
  );

  useEffect(() => {
    switch (ctx.status) {
      case AuthStatus.SignedIn:
        // the user has signed in and returned to/or is still on the login page
        if (onAuthRoute) {
          // skeleton auth should always disregard returnUrl
          const destination = onSkeletonRoute ? '/' : (returnUrl ?? '/');
          navigate(destination);
        } else {
          // we've signed in and landed on a reef page
          remove();
        }
        break;
      case AuthStatus.SignedOut:
        if (location.pathname !== '/' && !onAuthRoute) {
          // save where we wanted to go and navigate to login
          setReturnUrl(location.pathname);
          navigate('/login');
        }
        break;
    }
  }, [
    ctx.status,
    location.pathname,
    navigate,
    onAuthRoute,
    onSkeletonRoute,
    remove,
    returnUrl,
    setReturnUrl,
  ]);

  return <Outlet {...outletProps} />;
};
