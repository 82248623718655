import {
  DataGridPremiumProps,
  GridColDef,
  GridInitialState,
  GridLocaleText,
  GridRenderCellParams,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useMemo } from 'react';

import {
  ContractCell,
  CustomerCell,
  DateCell,
  MultiCurrencyCell,
  OpportunityCell,
  StringCell,
} from '../components/Tables/CustomGrid';
import { RenewalScoreCell } from '../components/Tables/CustomGrid/Renewal';
import { UUID } from '../models/uuid';
import { Renewal } from './renewal';
import { useCommonGridHelpers } from './useCommonGridHelpers';

type RenewalsGridRowModel = Readonly<Renewal>;
type RenewalsGridColDef = GridColDef<RenewalsGridRowModel>;

const renderStringCell = (params: GridRenderCellParams<RenewalsGridRowModel, string>) => (
  <StringCell {...params} />
);
const renderScoreCell = (params: GridRenderCellParams<RenewalsGridRowModel, string>) => (
  <RenewalScoreCell {...params} />
);
const renderDateCell = (params: GridRenderCellParams<RenewalsGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderMultiCurrencyCell = (params: GridRenderCellParams<RenewalsGridRowModel, number>) => (
  <MultiCurrencyCell {...params} />
);
const renderContractCell = (params: GridRenderCellParams<RenewalsGridRowModel, string>) => (
  <ContractCell {...params} />
);
const renderOpportunityCell = (params: GridRenderCellParams<RenewalsGridRowModel, string>) => (
  <OpportunityCell {...params} />
);

export type UseRenewalsGridProps = {
  renewals: Renewal[];
} & Pick<DataGridPremiumProps<Renewal>, 'filterModel' | 'onFilterModelChange' | 'onStateChange'>;
type RenewalsGridProps = Pick<
  DataGridPremiumProps<Renewal>,
  | 'autoHeight'
  | 'apiRef'
  | 'checkboxSelection'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'rowSelectionModel'
  | 'onRowSelectionModelChange'
  | 'hideFooter'
  | 'localeText'
  | 'sx'
  | 'rowHeight'
  | 'rowGroupingColumnMode'
  | 'disableRowSelectionOnClick'
  | 'defaultGroupingExpansionDepth'
  | 'rows'
  | 'groupingColDef'
  | 'onRowClick'
  | 'filterModel'
  | 'onFilterModelChange'
  | 'onStateChange'
>;
export const useRenewalsGrid = ({
  renewals,
  filterModel,
  onFilterModelChange,
  onStateChange,
}: UseRenewalsGridProps): RenewalsGridProps => {
  const hideableColMap = useMemo(
    (): { [K in keyof Renewal]?: boolean } => ({
      primaryOwnerName: true,
      secondaryOwnerName: true,
      renewalOpportunityName: true,
      renewalOpportunityStage: true,
      baseContractExpirationQuarter: true,
      customHealthCategory3mo: true,
      customHealthCategory6mo: true,
      customHealthCategory9mo: true,
      reefRenewal3mo: true,
      reefRenewal6mo: true,
      reefRenewal9mo: true,
      productDownsell: true,
      productChurn: true,
      contractChurn: true,
      productCrossSell: true,
      productUpsell: true,
    }),
    [],
  );

  const {
    apiRef,
    columnVisibilityModel,
    groupingColDef: commonGroupingColDef,
    localeText: defaultLocaleText,
    ...restProps
  } = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'Renewal',
  });

  const renderCustomerCell = useCallback(
    (params: GridRenderCellParams<RenewalsGridRowModel, string>) => {
      const row = { id: params.row.accountId };

      if (params.rowNode.type === 'group' && params.rowNode.groupingField === 'accountName') {
        const ids = new Set<UUID>(
          apiRef.current
            .getRowGroupChildren({ groupId: params.id })
            .map(params.api.getRow)
            .map((r) => r.accountId),
        );
        if (ids.size === 1) {
          return (
            <CustomerCell
              {...params}
              row={{ ...params.row, id: Array.from(ids)[0] }}
              showProductDetailsLink
            />
          );
        }
      }

      return <CustomerCell {...params} row={row} showProductDetailsLink />;
    },
    [apiRef],
  );

  const columns: RenewalsGridColDef[] = useMemo(() => {
    return [
      {
        field: 'baseContractName',
        headerName: 'Contract',
        minWidth: 150,
        flex: 1.5,
        type: 'string',
        renderCell: renderContractCell,
        aggregable: false,
      },
      {
        field: 'accountName',
        headerName: 'Customer',
        minWidth: 150,
        flex: 1.5,
        type: 'string',
        renderCell: renderCustomerCell,
        aggregable: false,
      },
      {
        field: 'renewalOwnerName',
        headerName: 'Renewal Lead',
        minWidth: 160,
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'primaryOwnerName',
        headerName: 'Customer Lead',
        minWidth: 150,
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'secondaryOwnerName',
        headerName: 'Sales Lead',
        minWidth: 150,
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'accountIndustry',
        headerName: 'Industry',
        minWidth: 150,
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'renewalOpportunityName',
        headerName: 'Opportunity',
        minWidth: 150,
        flex: 1.5,
        type: 'string',
        renderCell: renderOpportunityCell,
        aggregable: false,
      },
      {
        field: 'renewalOpportunityStage',
        headerName: 'Opportunity Stage',
        minWidth: 150,
        flex: 1.5,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'baseContractExpirationDate',
        headerName: 'Contract Expiration',
        minWidth: 200,
        flex: 1,
        type: 'date',
        renderCell: renderDateCell,
      },
      {
        field: 'baseContractExpirationQuarter',
        headerName: 'Contract Exp. Quarter',
        minWidth: 150,
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'closeDate',
        headerName: 'Close Date',
        minWidth: 150,
        flex: 1,
        type: 'date',
        renderCell: renderDateCell,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1.25,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'onTimeStatus',
        headerName: 'On-Time Status',
        minWidth: 170,
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        aggregable: false,
      },
      {
        field: 'reefRenewalCurrent',
        headerName: 'Reef Renewal Score',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'reefRenewal3mo',
        headerName: 'Reef Renewal Score (3mo)',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'reefRenewal6mo',
        headerName: 'Reef Renewal Score (6mo)',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'reefRenewal9mo',
        headerName: 'Reef Renewal Score (9mo)',
        minWidth: 200,
        flex: 1,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'customHealthCategoryCurrent',
        headerName: 'Customer Healthscore',
        minWidth: 220,
        flex: 1.25,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'customHealthCategory3mo',
        headerName: 'Customer Healthscore (3mo)',
        minWidth: 220,
        flex: 1.25,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'customHealthCategory6mo',
        headerName: 'Customer Healthscore (6mo)',
        minWidth: 220,
        flex: 1.25,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'customHealthCategory9mo',
        headerName: 'Customer Healthscore (9mo)',
        minWidth: 220,
        flex: 1.25,
        type: 'string',
        renderCell: renderScoreCell,
        aggregable: false,
      },
      {
        field: 'arrExpiringTotal',
        headerName: 'Expiring ARR',
        minWidth: 160,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'arrNewContract',
        headerName: 'New Contract ARR',
        minWidth: 190,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'arrRenewed',
        headerName: 'Renewed ARR',
        minWidth: 160,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'productDownsell',
        headerName: 'Product Downsell',
        minWidth: 150,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'productChurn',
        headerName: 'Product Churn',
        minWidth: 150,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'contractChurn',
        headerName: 'Contract Churn',
        minWidth: 150,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'productCrossSell',
        headerName: 'Product Cross Sell',
        minWidth: 150,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'productUpsell',
        headerName: 'Product Upsell',
        minWidth: 150,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'netChurn',
        headerName: 'Net Churn',
        minWidth: 150,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
      {
        field: 'netUpsell',
        headerName: 'Net Upsell',
        minWidth: 150,
        flex: 1,
        type: 'number',
        groupable: false,
        renderCell: renderMultiCurrencyCell,
      },
    ];
  }, [renderCustomerCell]);

  const initialState: GridInitialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: [],
      },
      columns: {
        columnVisibilityModel,
      },
      sorting: {
        sortModel: [
          {
            field: 'baseContractExpirationDate',
            sort: 'desc',
          },
        ],
      },
      aggregation: {
        model: {},
      },
    },
  });

  const localeText: Partial<GridLocaleText> = useMemo(
    () => ({
      ...defaultLocaleText,
      noRowsLabel: "🤔 We're not seeing any Contract Renewals for you at the moment!",
    }),
    [defaultLocaleText],
  );

  const groupingColDef: RenewalsGridProps['groupingColDef'] = useMemo(
    () => ({
      ...commonGroupingColDef,
      flex: 1.5,
    }),
    [commonGroupingColDef],
  );

  return {
    ...restProps,
    apiRef,
    autoHeight: false,
    checkboxSelection: false,
    initialState,
    columns,
    hideFooter: false,
    localeText,
    rowHeight: 42,
    disableRowSelectionOnClick: true,
    defaultGroupingExpansionDepth: 1,
    rows: renewals,
    groupingColDef,
    filterModel,
    onFilterModelChange,
    onStateChange,
  };
};
