import { DefaultLink, DefaultNode, ResponsiveSankey, SankeySvgProps } from '@nivo/sankey';
import React from 'react';

type ResponsiveSankeyProps<N extends DefaultNode, L extends DefaultLink> = Omit<
  SankeySvgProps<N, L>,
  'width' | 'height'
>;
export const SankeyChart = <N extends DefaultNode, L extends DefaultLink>(
  props: ResponsiveSankeyProps<N, L>,
) => <ResponsiveSankey {...props} />;
