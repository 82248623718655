import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import React from 'react';

import { TITLE_CHAR_LIMIT } from '../../../constants';
import { usePersistenceContext } from '../../../contexts/persistenceContext';
import { useReefNavContext } from '../../../contexts/reefNavContext';
import { Filter } from '../../../models/filters';
import { UUID } from '../../../models/uuid';
import { filterCustomers$ } from '../../../selectors';
import { ClampedText } from '../../ClampedText';
import { FilterStack } from '../../ListMenu/FiltersMenu/FilterStack';
import { SearchCustomers } from '../../SearchCustomers';

interface FilterCustomersOverviewProps {
  filterId: UUID | undefined;
  filterName: string;
  filterOperations: Filter[];
  children: React.ReactNode;
}
export const FilterCustomersOverview = ({
  filterId,
  filterName,
  filterOperations,
  children,
}: FilterCustomersOverviewProps) => {
  const { clearAllChanges } = usePersistenceContext();

  const { openMenu } = useReefNavContext();

  return (
    <Box>
      <Stack data-uid={filterCustomers$.overview.component} spacing={2} sx={{ m: 2 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <ClampedText
            text={filterName || 'New Filter'}
            limit={TITLE_CHAR_LIMIT}
            TooltipProps={{ placement: 'bottom-end' }}
            TypographyProps={{ variant: 'h3' }}
          />
          <Stack direction="row" flexGrow={1} flexShrink={0} justifyContent="flex-end" spacing={2}>
            <Button
              sx={{ my: 'auto' }}
              data-uid={filterCustomers$.overview.filterBtn}
              variant="outlined"
              size="small"
              disableElevation
              onClick={async () => {
                openMenu({ menu: 'filter-edit', filterId, editName: filterName === '' });
              }}
              startIcon={<AddIcon />}
            >
              Filter
            </Button>
            <Button
              sx={{ my: 'auto' }}
              data-uid={filterCustomers$.overview.clearChangesBtn}
              disabled={clearAllChanges == null}
              size="small"
              onClick={clearAllChanges}
            >
              Clear Changes
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} useFlexGap>
          <SearchCustomers
            key={filterId ?? 'all-customers'}
            inputProps={{ 'data-uid': filterCustomers$.overview.searchCustomersInput }}
          />
          <FilterStack
            sx={{ my: 'auto' }}
            filters={filterOperations}
            onClickFilter={(editFilterType) =>
              openMenu({ menu: 'filter-edit', filterId, editFilterType })
            }
          />
        </Stack>
      </Stack>
      {children}
    </Box>
  );
};
