import { Box, Button, Card, Chip, Divider, Link, Stack, Typography } from '@mui/material';
import { without } from 'lodash';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import { ReefRecommendationStatus } from '../../../../graphql/generated';
import {
  GROWTH_TYPE_LABELS,
  GrowthRecommendation,
  GrowthRecommendationFields,
} from '../../../../models/recommendation';
import { userWork$ } from '../../../../selectors';
import { bottom } from '../../../../utils';
import { CreateSprintButton } from './CreateSprintButton';
import { GrowthRecommendationField } from './GrowthRecommendationField';

const ALL_GROWTH_REC_FIELDS: Array<keyof GrowthRecommendationFields> = [
  'currentPipeline',
  'score',
  'accountPrimaryOwnerId',
  'accountSecondaryOwnerId',
  'accountRenewalDate',
  'products',
  'currentPredictedOverage',
  'accountLastUpsellDate',
  'accountStartedOn',
  'createdOn',
];
interface GrowthRecommendationItemProps extends GrowthRecommendation {}
export const GrowthRecommendationItem = ({
  id,
  createdOn,
  title,
  metrics,
  playbook,
  sprint,
  status,
  type,
}: GrowthRecommendationItemProps) => {
  const { showDemoData } = useUserSettingsContext();
  const viewSprintLink = useMemo(() => (sprint != null ? `/sprint/${sprint.id}` : null), [sprint]);
  const SprintButton = useMemo(() => {
    switch (status) {
      case ReefRecommendationStatus.Accepted:
        return viewSprintLink != null ? (
          <Link component={RouterLink} to={viewSprintLink}>
            <Button
              variant="outlined"
              fullWidth
              data-uid={userWork$.recommendations.viewSprintBtn(id)}
            >
              View Sprint
            </Button>
          </Link>
        ) : (
          <Button
            variant="outlined"
            color="warning"
            fullWidth
            disabled
            data-uid={userWork$.recommendations.missingSprintBtn(id)}
          >
            Missing Sprint
          </Button>
        );
      case ReefRecommendationStatus.NoActionTaken:
        return (
          <CreateSprintButton
            accountId={metrics.accountId}
            accountName={metrics.accountName}
            recommendationId={id}
            playbookId={playbook.id}
            playbookTitle={playbook.title}
            showDemoData={showDemoData}
          />
        );
      case ReefRecommendationStatus.Rejected:
        return (
          <Button
            variant="outlined"
            fullWidth
            color="info"
            data-uid={userWork$.recommendations.rejectedSprintBtn(id)}
          >
            No Sprint
          </Button>
        );
      default:
        bottom(status);
    }
  }, [
    id,
    status,
    viewSprintLink,
    metrics.accountId,
    metrics.accountName,
    playbook.id,
    playbook.title,
    showDemoData,
  ]);
  const chipInfo = useMemo(
    () => ({
      label: GROWTH_TYPE_LABELS[type] ?? 'Growth',
      backgroundColor: 'rgba(25, 118, 210, 0.30)',
    }),
    [type],
  );
  const recFields = useMemo(() => {
    switch (type) {
      case 'PRODUCT_UPSELL':
        return ALL_GROWTH_REC_FIELDS;
      default:
        return without(ALL_GROWTH_REC_FIELDS, 'currentPredictedOverage');
    }
  }, [type]);
  const fieldsData = useMemo<GrowthRecommendationFields>(
    () => ({
      ...metrics,
      createdOn,
    }),
    [createdOn, metrics],
  );
  return (
    <Card
      sx={{
        maxWidth: '60rem',
        '& p': {
          fontFamily: 'Inter',
        },
        overflow: 'visible',
        borderColor: chipInfo.backgroundColor,
      }}
      variant="outlined"
      data-uid={userWork$.recommendations.item(id)}
      data-rec-id={id}
      data-rec-status={status}
      data-rec-sprint-id={sprint?.id}
      data-rec-created-on={createdOn}
      data-rec-growth-score={metrics.score}
    >
      <Box sx={{ p: '2rem' }}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1} sx={{ pb: 3 }} alignItems="center">
            <Stack direction="column" spacing={0.25} flexGrow={1} sx={{ mt: -1 }}>
              <Box pb={0.25}>
                <Chip label={chipInfo.label} sx={{ backgroundColor: chipInfo.backgroundColor }} />
              </Box>
              <Typography
                sx={{
                  fontSize: '1.375rem',
                  maxWidth: '45rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                data-uid={userWork$.recommendations.name}
                title={title}
              >
                {title}
              </Typography>
              <Link
                component={RouterLink}
                to={`/customer-details/${metrics.accountId}`}
                data-uid={userWork$.recommendations.accountLink(id)}
                sx={{
                  maxWidth: '45rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontSize: '1.125rem',
                    fontFamily: 'Inter',
                  }}
                  title={metrics.accountName}
                >
                  {metrics.accountName}
                </Typography>
              </Link>
            </Stack>
            {SprintButton}
          </Stack>
          <Stack>
            <Divider sx={{ mx: -4, borderColor: chipInfo.backgroundColor }} />
          </Stack>
          {/* FIXME: use a grid layout? */}
          <Stack direction="row" spacing={2.5} sx={{ pt: 1.5, pb: 0 }}>
            {recFields.slice(0, 5).map((rf) => (
              <Box key={`${id}-${rf}-field-wrapper`} sx={{ width: '10rem' }}>
                <GrowthRecommendationField
                  key={`${id}-${rf}-field`}
                  recId={id}
                  field={rf}
                  data={fieldsData}
                />
              </Box>
            ))}
          </Stack>
          <Stack direction="row" spacing={2.5} sx={{ pt: 1.5, pb: 0 }}>
            {recFields.slice(5).map((rf) => (
              <Box key={`${id}-${rf}-field-wrapper`} sx={{ width: '10rem' }}>
                <GrowthRecommendationField
                  key={`${id}-${rf}-field`}
                  recId={id}
                  field={rf}
                  data={fieldsData}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};
