import { Close, PersonSearch } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { useCallback } from 'react';

import { useSearchContext } from '../../contexts/searchContext';

type SearchUsersProps = TextFieldProps;
export const SearchUsers = (props: SearchUsersProps) => {
  const { InputProps = {}, ...rest } = props;
  const {
    startAdornment: startAdornmentOverride,
    endAdornment: endAdornmentOverride,
    ...inputRest
  } = InputProps;
  const { searchTerm, resetSearchTerm, setSearchTerm } = useSearchContext();
  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value),
    [setSearchTerm],
  );

  const endAdornment =
    endAdornmentOverride ||
    (searchTerm ? (
      <InputAdornment position="end">
        <IconButton aria-label="clear search" onClick={resetSearchTerm} edge="end" size="small">
          <Close fontSize="inherit" />
        </IconButton>
      </InputAdornment>
    ) : (
      <></>
    ));

  const startAdornment = startAdornmentOverride || (
    <InputAdornment position="start">
      <PersonSearch sx={{ color: 'action.active', my: 1 }} />
    </InputAdornment>
  );

  return (
    <TextField
      label="Search Users..."
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={onSearchChange}
      InputProps={{
        endAdornment,
        startAdornment,
        ...inputRest,
      }}
      {...rest}
    />
  );
};
