import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, ListItem, ListItemButton, ListItemProps, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import { LIST_MENU_ITEM_CHAR_LIMIT } from '../../../constants';
import { ClampedText } from '../../ClampedText';

interface SprintListMenuItemProps extends ListItemProps {
  /**
   * This is the current sprint the user is viewing.
   */
  selected: boolean;
  /**
   * The name of the sprint.
   */
  sprintName: string;
  /**
   * Called when the sprint is clicked.
   */
  onClick(): void;
  /**
   * This sprint can be un-followed and if called, will un-follow the sprint.
   */
  onUnfollowSprint?(): void;
}

export const SprintListMenuItem = ({
  selected,
  sprintName,
  onClick,
  onUnfollowSprint,
  ...rest
}: SprintListMenuItemProps) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();
  const menuOpen = Boolean(menuAnchor);
  return (
    <>
      <Menu open={menuOpen} onClose={() => setMenuAnchor(undefined)} anchorEl={menuAnchor}>
        <MenuItem
          disabled={onUnfollowSprint == null}
          onClick={() => {
            onUnfollowSprint?.();
            setMenuAnchor(undefined);
          }}
        >
          Unfollow
        </MenuItem>
      </Menu>
      <ListItem
        divider
        disablePadding
        sx={{
          backgroundColor: (theme) => (selected ? theme.palette.primary.dark : 'unset'),
          color: (theme) =>
            selected ? theme.palette.primary.contrastText : theme.palette.primary.dark,
          '&:hover': {
            bgcolor: (theme) => theme.palette.primary.s12p,
            color: (theme) => theme.palette.primary.dark,
            '.MuiIconButton-root': {
              color: (theme) => theme.palette.primary.dark,
            },
          },
        }}
        secondaryAction={
          <IconButton
            size="small"
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            sx={{
              color: (theme) =>
                selected ? theme.palette.primary.contrastText : theme.palette.primary.dark,
            }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        }
        {...rest}
      >
        <ListItemButton onClick={onClick} sx={{ py: 1.5 }}>
          <ClampedText
            text={sprintName}
            limit={LIST_MENU_ITEM_CHAR_LIMIT}
            BoxProps={{ sx: { width: '100%' } }}
            TooltipProps={{ placement: 'right' }}
          />
        </ListItemButton>
      </ListItem>
    </>
  );
};
