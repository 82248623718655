import { LoadingButton } from '@mui/lab';
import { Alert, Link, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  AuthActionType,
  AuthStatus,
  useAuthError,
  useReefAuthContext,
} from '../../../contexts/reefAuthContext';
import { sleep } from '../../../utils';

export const Challenge = () => {
  const [securityCode, setSecurityCode] = useState('');
  const [authCtx, { authenticate }] = useReefAuthContext();
  const [authError, clearAuthError] = useAuthError();

  const [codeExpired, setCodeExpired] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { username, password } = useMemo(
    () =>
      authCtx.status === AuthStatus.Confirming
        ? {
            username: authCtx.signUpOptions.username,
            password: authCtx.signUpOptions.password,
          }
        : {},
    [authCtx],
  );

  const signUp = useCallback(
    async (username: string | undefined, answer: string) => {
      setLoading(true);
      clearAuthError();

      // confirm user sign up
      if (username != null) {
        await authenticate({ type: AuthActionType.ConfirmSignUp, username, answer });
      }

      setLoading(false);
    },
    [clearAuthError, authenticate],
  );

  // the user's auth challenge expires after roughly an hour, but 30 minutes should be more than
  // enough time to respond
  useEffect(() => {
    const timer = setTimeout(
      () => setCodeExpired(true),
      // 30 minutes
      60 * 1000 * 30,
    );
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // we can't verify a user if we don't know the username
    // this can happen if the user force refreshes the page before submitting the auth challenge
    if (username == null) {
      navigate('/login');
    }
  }, [navigate, username]);

  useEffect(() => {
    const waitThenRedirect = async () => {
      await sleep(2000);
      navigate('/login');
    };

    const login = async (username: string, password: string) => {
      setLoading(true);
      await authenticate({ type: AuthActionType.UserPass, username, password });
      setLoading(false);
    };

    if (authCtx.status === AuthStatus.Confirming && authCtx.confirmed) {
      if (username != null && password != null) {
        login(username, password);
      } else {
        waitThenRedirect();
      }
    }
  }, [authCtx, authenticate, navigate, password, username]);

  return (
    <Stack spacing={2} maxWidth={(theme) => theme.spacing(42)}>
      {!codeExpired ? (
        <>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label="Security Code"
            required
            value={securityCode}
            onChange={(e) => setSecurityCode(e.target.value)}
          />
          <LoadingButton
            fullWidth
            variant="contained"
            disabled={securityCode.length === 0}
            loading={loading}
            onClick={() => signUp(username, securityCode)}
          >
            Submit
          </LoadingButton>
        </>
      ) : (
        <Typography variant="h2">The security code we sent you has expired.</Typography>
      )}
      <Typography variant="caption">
        I need a new{' '}
        <Link component={RouterLink} sx={{ cursor: 'pointer' }} to="/sign-up/resend-code">
          security code.
        </Link>
      </Typography>
      {authCtx.status === AuthStatus.Confirming && authCtx.confirmed && (
        <Alert severity="success">
          Your email has been confirmed. You are being redirected to{' '}
          <Link component={RouterLink} to="/login">
            login
          </Link>
          .
        </Alert>
      )}
      {authError != null && <Alert severity="error">{authError.message}</Alert>}
    </Stack>
  );
};
