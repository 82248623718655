import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

import { ACTION_DOUGHNUT_COLORS_MAP } from '../../../constants';
import { ActivityStatus as ApiActivityStatus } from '../../../graphql/generated';
import { ACTIVITY_STATUS_LABELS } from '../../../models/sprint';
import { UUID } from '../../../models/uuid';

interface ActivityStatusSelect {
  activityId: UUID;
  disabled: boolean;
  onStatusChange: (e: SelectChangeEvent) => void;
  value: ApiActivityStatus | undefined;
}

export const ActivityStatusSelect = ({
  activityId,
  disabled,
  onStatusChange,
  value,
}: ActivityStatusSelect) => {
  const enabledActivityStatusOptions: ApiActivityStatus[] = [
    ApiActivityStatus.NotStarted,
    ApiActivityStatus.InProcess,
    ApiActivityStatus.Complete,
    ApiActivityStatus.Rejected,
  ];

  return (
    <>
      <InputLabel id={`activity-${activityId}-label`}>Action Status</InputLabel>
      <Select
        id={`activity-${activityId}-select`}
        name={`activity-${activityId}-select`}
        labelId={`activity-${activityId}-label`}
        size="small"
        sx={{
          color: value ? `${ACTION_DOUGHNUT_COLORS_MAP[value]}` : undefined,
          '& .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: value ? `${ACTION_DOUGHNUT_COLORS_MAP[value]}` : undefined,
          },
        }}
        disabled={disabled}
        value={value}
        onChange={onStatusChange}
        label="Action Status"
      >
        {enabledActivityStatusOptions.map((activityStatus: ApiActivityStatus) => (
          <MenuItem
            sx={{ color: ACTION_DOUGHNUT_COLORS_MAP[activityStatus] ?? 'unset' }}
            key={`select-menu-item--${activityId}-${activityStatus}`}
            value={activityStatus}
          >
            {ACTIVITY_STATUS_LABELS[activityStatus]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
