import { ApolloError, QueryHookOptions, useQuery } from '@apollo/client';
import { differenceInSeconds } from 'date-fns';
import { useMemo } from 'react';

import {
  GetClientRecommendationsDocument,
  GetMyWorkRecommendationsDocument,
  GetMyWorkRecommendationsQuery,
  GetRecommendationDocument,
  RecommendationFieldsFragment,
  ReefRecommendationStatus,
} from '../graphql/generated';
import { RiskRecommendation } from '../models/recommendation';
import { Result, ResultType, useResultFromQuery } from '../models/result';
import { UUID } from '../models/uuid';
import { transformDate } from '../utils';

const transformUserRecommendation = <T extends GetMyWorkRecommendationsQuery['self']>(
  item: T extends {
    __typename?: 'User';
    associated: { recommendations: Array<infer U> };
  }
    ? U
    : never,
): RiskRecommendation => ({
  id: item.id,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  account: {
    id: item.account.id,
    name: item.account.name,
    ownerEmail: item.account.roles.primaryOwner?.email ?? null,
    ownerName: item.account.roles.primaryOwner?.name ?? null,
    renewalOwnerEmail: item.account.roles.renewalOwner?.email ?? null,
    renewalOwnerName: item.account.roles?.renewalOwner?.name ?? null,
    customerHealthscore: item.account.rawData.customHealthValue ?? null,
  },
  contract: {
    contractEndDate: transformDate(item.contract.endDate),
    contractEndFiscalQuarter: item.contract.endFiscalQuarter.split(' ')[1],
    expiringArr: item.contract.expiringArr,
    id: item.contract.id,
    // TODO: sub with crmLink when available
    link: `/customer-details/${item.account.id}/contracts`,
    name: item.contract.name,
    productNames: item.contract.servicePeriods.map((sp) => sp.productName),
    usageRating: item.contract.usageRating,
  },
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  title: item.playbook.title,
  sprint:
    item.sprint != null
      ? {
          id: item.sprint.id,
          end: transformDate(item.sprint.end),
          name: item.sprint.name,
          start: transformDate(item.sprint.start),
          createdAt: transformDate(item.sprint.createdAt),
          creatorEmail: item.sprint.createdBy.email,
          creatorId: item.sprint.createdBy.id,
          creatorName: item.sprint.createdBy.name,
        }
      : null,
  createdOn: transformDate(item.createdOn),
  metrics: null,
  type: 'RISK',
});
export const transformUserRecommendations = <T extends RecommendationFieldsFragment>(
  items: T[],
): RiskRecommendation[] => items.map(transformUserRecommendation);

export const useClientRecommendations = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<RiskRecommendation[], ApolloError> => {
  const clientRecommendationsResult = useResultFromQuery(
    useQuery(GetClientRecommendationsDocument, { skip }),
  );
  return useMemo((): Result<RiskRecommendation[], ApolloError> => {
    if (clientRecommendationsResult.state === ResultType.Value) {
      const { recommendations } = clientRecommendationsResult.value.client;
      const value = transformUserRecommendations(recommendations).sort((r1, r2) =>
        differenceInSeconds(r2.createdOn ?? 0, r1.createdOn ?? 0),
      );
      return { state: clientRecommendationsResult.state, value };
    }
    return clientRecommendationsResult;
  }, [clientRecommendationsResult]);
};

export const useMyRecommendations = ({
  options: { skip = false },
  ignoreRejected = true,
}: {
  options: Pick<QueryHookOptions, 'skip'>;
  ignoreRejected?: boolean;
}): Result<RiskRecommendation[], ApolloError> => {
  const myRecommendationsResult = useResultFromQuery(
    useQuery(GetMyWorkRecommendationsDocument, { skip }),
  );
  return useMemo((): Result<RiskRecommendation[], ApolloError> => {
    if (myRecommendationsResult.state === ResultType.Value) {
      const recommendations = myRecommendationsResult.value.self.associated.recommendations.filter(
        (r) => !ignoreRejected || r.status !== ReefRecommendationStatus.Rejected,
      );
      const value = transformUserRecommendations(recommendations).sort((r1, r2) =>
        differenceInSeconds(r2.createdOn ?? 0, r1.createdOn ?? 0),
      );
      return { state: myRecommendationsResult.state, value };
    }
    return myRecommendationsResult;
  }, [myRecommendationsResult, ignoreRejected]);
};

export const useRecommendation = (id: UUID | null): Result<RiskRecommendation, ApolloError> => {
  // TODO: [sc-10706] support demo data/faker
  const recommendationResult = useResultFromQuery(
    useQuery(GetRecommendationDocument, {
      variables: id != null ? { id } : undefined,
      skip: id == null,
    }),
  );
  return useMemo((): Result<RiskRecommendation, ApolloError> => {
    if (recommendationResult.state === ResultType.Value) {
      if (recommendationResult.value.node.__typename === 'ReefContractRecommendation') {
        const recommendation = recommendationResult.value.node;
        const value = transformUserRecommendation(recommendation);
        return { state: recommendationResult.state, value };
      }
      return { state: ResultType.NoValue, value: undefined };
    }
    return recommendationResult;
  }, [recommendationResult]);
};
