import React, { PropsWithChildren } from 'react';

import { NavMenuStateOverride, ReefNavContext, useNavMenuState } from './reefNavContext';

interface ReefNavProviderProps {
  initialState?: NavMenuStateOverride;
}
export const ReefNavProvider = ({
  initialState,
  children,
}: PropsWithChildren<ReefNavProviderProps>) => {
  const nav = useNavMenuState(initialState);
  return <ReefNavContext.Provider value={nav}>{children}</ReefNavContext.Provider>;
};
