import { Typography } from '@mui/material';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import React from 'react';

import { cells$ } from '../../../../selectors';
import { DynamicScoreChip } from '../../../ScoreChip/DynamicScoreChip';
import { FieldWrapper } from './FieldWrapper';

type MetricCellProps<T extends GridValidRowModel = GridValidRowModel> = GridRenderCellParams<
  T,
  string
>;
export const MetricCell = <T extends GridValidRowModel = GridValidRowModel>({
  field,
  value,
  rowNode,
}: MetricCellProps<T>) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? (
    <></>
  ) : (
    <Typography variant="body2">&ndash;</Typography>
  );
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <DynamicScoreChip data-uid={cells$.metric.chip} value={value} />
      ) : (
        missingValue
      )}
    </FieldWrapper>
  );
};
