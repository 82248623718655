import React from 'react';

import { useReefFlags } from '../hooks/flags';
import { usePersistence } from '../hooks/useLocalStorage';
import { HeatmapContext } from './heatmapContext';
import { PersistenceKeys } from './persistenceContext';

const getXAxisAccountField = (configOption: string) => {
  switch (configOption) {
    case 'engagement':
    case 'engagementScore':
    default:
      return 'engagement' as const;
    case 'customHealthscore':
      return 'customHealthscore' as const;
    case 'productEngagement':
      return 'productEngagement' as const;
    case 'supportEngagement':
      return 'supportEngagement' as const;
    case 'productConsumptionPercent':
      return 'productConsumptionPercent' as const;
  }
};

const getYAxisAccountField = (configOption: string) => {
  switch (configOption) {
    case 'potential':
    case 'potentialScore':
    default:
      return 'potential' as const;
    case 'customerRevenueBand':
      return 'customerRevenueBand' as const;
  }
};

export const HeatmapProvider = ({ children }: React.PropsWithChildren) => {
  const { heatmapDefaultMetrics } = useReefFlags();

  const [xAxisMetric, setXAxisMetric] = usePersistence(
    PersistenceKeys.HeatmapXAxisSelection,
    getXAxisAccountField(heatmapDefaultMetrics.x),
  );
  const [yAxisMetric, setYAxisMetric] = usePersistence(
    PersistenceKeys.HeatmapYAxisSelection,
    getYAxisAccountField(heatmapDefaultMetrics.y),
  );

  return (
    <HeatmapContext.Provider
      value={{
        xAxisMetric,
        setXAxisMetric,
        setYAxisMetric,
        yAxisMetric,
      }}
    >
      {children}
    </HeatmapContext.Provider>
  );
};
