import { FormControl, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { analytics$ } from '../../../../selectors/analytics';
import { bottom } from '../../../../utils';
import { DetailCharts, HeroCharts } from '../types';

type ChartTypeSelectProps<T extends string> =
  | {
      variant: 'hero';
      value: T;
      onChange: (newType: T) => void;
      selectProps?: Partial<SelectProps>;
    }
  | {
      variant: 'detail';
      value: T;
      onChange: (newType: T) => void;
      selectProps?: Partial<SelectProps>;
    };
type AllCharts = HeroCharts | DetailCharts;
const HERO_CHART_TYPES: HeroCharts[] = ['total-renewals-bars'];
const DETAIL_CHART_TYPES: DetailCharts[] = [
  'renewal-overview-waterfall',
  'gross-retention-breakdown-waterfall',
  'renewal-growth-breakdown-waterfall',
  'breakdown-by-group-bars',
  'outcomes-by-score-sankey',
];
const CHART_NAMES: Record<AllCharts, string> = {
  'total-renewals-bars': 'Total Renewal Status',
  'renewal-overview-waterfall': 'Renewal Overview',
  'gross-retention-breakdown-waterfall': 'Gross Retention Breakdown',
  'renewal-growth-breakdown-waterfall': 'Renewal Growth Breakdown',
  'breakdown-by-group-bars': 'Breakdown by Group',
  'outcomes-by-score-sankey': 'Outcomes by Score',
};
const buildChartOption = (
  variant: ChartTypeSelectProps<AllCharts>['variant'],
  chart: AllCharts,
) => (
  <MenuItem data-uid={analytics$.chartTypeSelect.item(variant, chart)} key={chart} value={chart}>
    {CHART_NAMES[chart]}
  </MenuItem>
);
const HERO_OPTIONS = HERO_CHART_TYPES.map((hct) => buildChartOption('hero', hct));
const DETAIL_OPTIONS = DETAIL_CHART_TYPES.map((dct) => buildChartOption('detail', dct));
export const ChartTypeSelect = <T extends AllCharts>({
  variant,
  value,
  onChange,
  selectProps,
}: ChartTypeSelectProps<T>) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => onChange(event.target.value as T),
    [onChange],
  );
  const options = useMemo(() => {
    switch (variant) {
      case 'hero':
        return HERO_OPTIONS;
      case 'detail':
        return DETAIL_OPTIONS;
      default:
        bottom(variant);
    }
  }, [variant]);
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        data-uid={analytics$.chartTypeSelect.select(variant)}
        id={`${variant}-select-small`}
        value={value}
        onChange={handleChange}
        {...selectProps}
      >
        {options}
      </Select>
    </FormControl>
  );
};
