import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { DeleteObjectiveDocument, GetClientObjectivesDocument } from '../../../graphql/generated';
import { Objective } from '../../../models/sprint';
import { DeleteConfirmationDialog } from '../DeleteConfirmationDialog';

interface Closeable {
  onClose(): void;
  onExited(): void;
}

interface OpenDeleteObjectiveDialog {
  open: true;
  objective: Objective;
}

interface ClosedDeleteObjectiveDialog {
  open: false;
  objective: Objective | undefined;
}

export type DeleteObjectiveDialogOptions = OpenDeleteObjectiveDialog | ClosedDeleteObjectiveDialog;

type DeleteObjectiveDialogProps = DeleteObjectiveDialogOptions & Closeable;

export const DeleteObjectiveDialog = ({
  open,
  onClose,
  onExited,
  objective,
}: DeleteObjectiveDialogProps) => {
  const [deleteObjective, result] = useMutation(DeleteObjectiveDocument);

  useEffect(() => {
    if (result.data != null) {
      onClose();
    }
  }, [onClose, result.data, result.error]);

  const afterClose = useCallback(() => {
    onExited();
    if (result.called) {
      result.reset();
    }
  }, [onExited, result]);

  return (
    <DeleteConfirmationDialog
      type="objective"
      open={open}
      onClose={onClose}
      onExited={afterClose}
      onDelete={() =>
        open &&
        deleteObjective({
          variables: { objectiveId: objective.id },
          refetchQueries: [{ query: GetClientObjectivesDocument }],
          // FIXME: evict objective.id from the cache (remove refetch)
        })
      }
      title="Delete Playbook"
      confirmationText={
        <>
          <Typography gutterBottom>
            Are you sure you want to delete{' '}
            {objective != null ? (
              <>
                the Playbook <strong>{objective.title}</strong>
              </>
            ) : (
              'this Playbook'
            )}
            ?
          </Typography>
          <Typography gutterBottom>
            This will delete the <strong>Playbook</strong> and <em>all</em> of it&apos;s associated{' '}
            <strong>Actions</strong>.
          </Typography>
        </>
      }
    />
  );
};
