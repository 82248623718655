import {
  DataGridPremiumProps,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridEventListener,
  GridLocaleText,
  gridNumberComparator,
  gridSortModelSelector,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { Dictionary, isEmpty, merge } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { pluralize } from '../utils';

interface UseCommonGridHelpersProps {
  initialApiRef?: CommonGridHelpers['apiRef'];
  /**
   * Map containing columns to hide; used to construct `columnVisibilityModel` prop
   */
  hideableColMap: Dictionary<boolean>;
  /**
   * Singular form of current grid locale entity; used in grid footer / selection contexts.
   */
  localeEntity: string;
  /**
   * Optional plural form of current grid locale entity; used in grid footer / selection contexts.
   */
  localeEntities?: string;
}
interface CommonGridHelpers
  extends Required<
    Pick<
      DataGridPremiumProps,
      | 'apiRef'
      | 'columnVisibilityModel'
      | 'onColumnVisibilityModelChange'
      | 'rowHeight'
      | 'defaultGroupingExpansionDepth'
      | 'rowGroupingColumnMode'
      | 'groupingColDef'
      | 'onRowClick'
      | 'localeText'
    >
  > {
  customNumberComparator: GridComparatorFn<number>;
}
export const useCommonGridHelpers = ({
  initialApiRef,
  hideableColMap,
  localeEntity,
  localeEntities,
}: UseCommonGridHelpersProps): CommonGridHelpers => {
  const baseApiRef = useGridApiRef();
  const apiRef = useMemo(() => initialApiRef ?? baseApiRef, [baseApiRef, initialApiRef]);

  const customNumberComparator: GridComparatorFn<number> = useCallback(
    (v1, v2, params1, params2) => {
      let defaultMax = Number.MAX_SAFE_INTEGER;
      if (apiRef?.current != null && !isEmpty(apiRef.current)) {
        const sortModel = gridSortModelSelector(apiRef);
        const existingSort = sortModel.find(({ field }) => field === params1.field);
        if (existingSort?.sort === 'desc') {
          defaultMax = Number.MIN_SAFE_INTEGER;
        }
      }
      return gridNumberComparator(v1 ?? defaultMax, v2 ?? defaultMax, params1, params2);
    },
    [apiRef],
  );

  const [userColumnOverrides, setUserColumnOverrides] = useState<GridColumnVisibilityModel>({});
  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(
    () =>
      merge(
        Object.keys(hideableColMap).reduce<GridColumnVisibilityModel>(
          (gcvModel, field) =>
            hideableColMap[field as keyof typeof hideableColMap]
              ? { ...gcvModel, [field]: false }
              : gcvModel,
          {},
        ),
        userColumnOverrides,
      ),
    [hideableColMap, userColumnOverrides],
  );

  const groupingColDef: CommonGridHelpers['groupingColDef'] = useMemo(
    () => ({
      hideDescendantCount: true,
    }),
    [],
  );

  const onRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      const rowNode = apiRef.current.getRowNode(params.id);
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
      }
    },
    [apiRef],
  );

  const localeText: Partial<GridLocaleText> = useMemo(() => {
    const singular = localeEntity;
    const plural = pluralize({ count: 2, singular, plural: localeEntities });
    return {
      noRowsLabel: `No ${plural}`,
      footerTotalRows: `Total ${plural}:`,
      footerRowSelected: (count) =>
        `${count.toLocaleString()} ${pluralize({
          count,
          singular,
          plural: localeEntities,
        })} selected`,
      checkboxSelectionSelectAllRows: `Select all ${plural}`,
      checkboxSelectionUnselectAllRows: `Unselect all ${plural}`,
      checkboxSelectionSelectRow: `Select ${singular}`,
      checkboxSelectionUnselectRow: `Unselect ${singular}`,
    };
  }, [localeEntities, localeEntity]);

  return {
    apiRef,
    columnVisibilityModel,
    customNumberComparator,
    onColumnVisibilityModelChange: setUserColumnOverrides,
    rowHeight: 60,
    groupingColDef,
    onRowClick,
    rowGroupingColumnMode: 'multiple',
    defaultGroupingExpansionDepth: 0,
    localeText,
  };
};
