import { noop } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { AccountFilterType } from '../models/filters';
import { UUID } from '../models/uuid';

interface OpenMenuState {
  open: true;
}

interface ClosedMenuState {
  open: false;
}

type MenuState = OpenMenuState | ClosedMenuState;

interface OpenEditFilterMenuState extends OpenMenuState {
  filterId: UUID | undefined;
  editName?: boolean;
  editFilterType?: AccountFilterType;
}

interface ClosedEditFilterMenuState extends ClosedMenuState {
  filterId: undefined;
}

type EditFilterMenuState = OpenEditFilterMenuState | ClosedEditFilterMenuState;

interface OpenMenuPayload {
  menu: 'list' | 'sprint' | 'analytics';
}

interface OpenEditFilterMenuPayload {
  menu: 'filter-edit';
  filterId: UUID | undefined;
  editName?: boolean;
  editFilterType?: AccountFilterType;
}

/**
 * Type used to override the initial state of the nav provider. For testing purposes.
 */
export type NavMenuStateOverride = Partial<
  Pick<
    UseNavMenuState,
    'filterEditMenuState' | 'sprintMenuState' | 'subNavExpanded' | 'analyticsMenuState'
  >
>;
export interface UseNavMenuState {
  /**
   * Menu state for the sprint sub-nav.
   */
  sprintMenuState: MenuState;
  /**
   * Menu state for the analytics sub-nav.
   */
  analyticsMenuState: MenuState;
  /**
   * `true` if the filter edit menu nav is open.
   */
  filterEditMenuState: EditFilterMenuState;
  /**
   * Callback to open a single menu in the nav. Closes other open navs.
   * @param payload menu context to open
   */
  openMenu(payload: OpenMenuPayload | OpenEditFilterMenuPayload): void;
  /**
   * Close all navs and the expanded sub-nav.
   */
  close(): void;
  /**
   * `true` if the sub-nav is expanded.
   */
  subNavExpanded: boolean;
  /**
   * Sets the sub-nav to expanded.
   */
  expandSubNav(): void;
  /**
   * Sets the sub-nav to collapsed.
   */
  collapseSubNav(): void;
  /**
   * Most recent Filter id that a user has navigated to; used in Account CDP customers link.
   */
  recentFilterId?: UUID;
  /**
   * Sets the recent Filter id that a user has navigated to.
   */
  setRecentFilterId: React.Dispatch<React.SetStateAction<UseNavMenuState['recentFilterId']>>;
}

/**
 * Hook to compartmentalize all logic for the nav menu states.
 * @param initialState initial state overrides for the menu
 * @returns nav menu state
 */
export function useNavMenuState(initialState?: NavMenuStateOverride): UseNavMenuState {
  const [isHovered, setIsHovered] = useState(false);
  const [subNavExpanded, setSubNavExpanded] = useState(initialState?.subNavExpanded ?? false);
  const [recentFilterId, setRecentFilterId] = useState<UUID>();

  const [sprintMenuState, setSprintMenuState] = useState<MenuState>(
    initialState?.sprintMenuState ?? { open: false },
  );
  const [analyticsMenuState, setAnalyticsMenuState] = useState<MenuState>(
    initialState?.analyticsMenuState ?? { open: false },
  );
  const [filterEditMenuState, setFilterEditMenuState] = useState<EditFilterMenuState>(
    initialState?.filterEditMenuState ?? {
      open: false,
      filterId: undefined,
    },
  );

  const openMenu = useCallback((payload: OpenMenuPayload | OpenEditFilterMenuPayload) => {
    setSprintMenuState({ open: payload.menu === 'sprint' });
    setAnalyticsMenuState({ open: payload.menu === 'analytics' });
    setFilterEditMenuState(
      payload.menu === 'filter-edit'
        ? {
            open: true,
            filterId: payload.filterId,
            editName: payload.editName,
            editFilterType: payload.editFilterType,
          }
        : { open: false, filterId: undefined },
    );
    setSubNavExpanded(payload.menu !== 'filter-edit');
  }, []);

  const close = useCallback(() => {
    setSprintMenuState({ open: false });
    setAnalyticsMenuState({ open: false });
    setFilterEditMenuState({ open: false, filterId: undefined });
    setSubNavExpanded(false);
  }, []);

  const expandSubNav = useCallback(() => setIsHovered(true), []);
  const collapseSubNav = useCallback(() => setIsHovered(false), []);

  useEffect(() => {
    if (subNavExpanded) {
      // we want to collapse the nav if it is expanded and not hovered on
      // but only if the other list menus are not open
      if (!sprintMenuState.open && !analyticsMenuState.open && !isHovered) {
        setSubNavExpanded(false);
      }
    } else if (isHovered) {
      // if the nav is _not_ expanded, then we want to expand it if it is hovered on
      setSubNavExpanded(true);
    }
  }, [isHovered, sprintMenuState, analyticsMenuState, subNavExpanded]);

  return {
    sprintMenuState,
    analyticsMenuState,
    filterEditMenuState,
    openMenu,
    subNavExpanded,
    expandSubNav,
    collapseSubNav,
    close,
    recentFilterId,
    setRecentFilterId,
  };
}

export const ReefNavContext = createContext<UseNavMenuState>({
  filterEditMenuState: { open: false, filterId: undefined },
  sprintMenuState: { open: false },
  analyticsMenuState: { open: false },
  openMenu: noop,
  subNavExpanded: false,
  expandSubNav: noop,
  collapseSubNav: noop,
  close: noop,
  setRecentFilterId: noop,
});

export const useReefNavContext = () => useContext(ReefNavContext);
