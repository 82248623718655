import { Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { DataConnectorSource } from '../../../../../graphql/generated';
import salesforceLogo from '../../../../../images/salesforce-logo.svg';
import { UUID } from '../../../../../models/uuid';
import { ConnectButton } from './ConnectButton';
import { ConnectedButton } from './ConnectedButton';
import { ProgressiveSpinner } from './ProgressiveSpinner';

export interface ConnectorProps {
  sourceType: DataConnectorSource;
  id: UUID | null | undefined;
  isPending: boolean;
}

const SUPPORTED_CONNECTORS = {
  SALESFORCE: { logo: salesforceLogo, isRequired: true },
};

export const DataConnector = ({ sourceType, id, isPending = false }: ConnectorProps) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isPending) {
      timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 90 ? 90 : prevProgress + 1));
      }, 210);
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress, isPending]);

  const renderActionButton = useMemo(() => {
    if (id) {
      return <ConnectedButton />;
    }

    if (isPending) {
      return <ProgressiveSpinner progress={progress} />;
    }

    return (
      <ConnectButton
        redirectUrl={`https://${window.location.hostname}/connectors/${sourceType}/create`}
        sourceType={sourceType}
      />
    );
  }, [id, isPending, progress, sourceType]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{
        padding: 3,
        borderBottom: '1px solid',
        '&:last-child': {
          borderBottom: 0,
        },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <img
          src={SUPPORTED_CONNECTORS[sourceType]?.logo}
          width="35"
          height="auto"
          alt={`${sourceType}-logo`}
        />
        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
          {sourceType.toLowerCase()}
        </Typography>
        <Typography variant="caption">
          {SUPPORTED_CONNECTORS[sourceType].isRequired && '*Required'}
        </Typography>
      </Stack>
      {renderActionButton}
    </Stack>
  );
};
