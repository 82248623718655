import { useMutation } from '@apollo/client';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { CreateObjectiveDocument, GetClientObjectivesDocument } from '../../../graphql/generated';
import { manage$, tooltip$ } from '../../../selectors';

interface CreateObjectiveDialogProps {
  open: boolean;
  rank: number;
  onClose: () => void;
}

export const CreateObjectiveDialog = ({ open, rank, onClose }: CreateObjectiveDialogProps) => {
  const [title, setTitle] = useState('');

  const [createObjective, createObjectiveResult] = useMutation(CreateObjectiveDocument, {
    refetchQueries: [{ query: GetClientObjectivesDocument }],
  });

  useEffect(() => {
    if (createObjectiveResult.data != null) {
      onClose();
    }
  }, [createObjectiveResult, onClose]);

  const onAfterClose = useCallback(() => {
    setTitle('');
    if (createObjectiveResult.called) {
      createObjectiveResult.reset();
    }
  }, [createObjectiveResult]);

  const saveObjective = useCallback(
    (title: string) => {
      if (title.length > 0) {
        createObjective({ variables: { title, rank } });
      }
    },
    [createObjective, rank],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth TransitionProps={{ onExited: onAfterClose }}>
      <DialogTitle>
        Create a new Playbook
        <Tooltip
          data-uid={tooltip$.component}
          title={
            <Typography>
              A <strong>Playbook</strong> is the target outcome of a collection of actions.
            </Typography>
          }
        >
          <IconButton sx={{ ml: 2 }}>
            <HelpOutlineIcon color="primary" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        {createObjectiveResult.error != null && (
          <Box sx={{ mt: 1 }}>
            <Alert severity="error" variant="outlined">
              <AlertTitle>Uh oh 😬</AlertTitle>
              Failed to create Playbook.
            </Alert>
          </Box>
        )}
        <Box sx={{ mt: 1 }}>
          <TextField
            fullWidth
            data-uid={manage$.playbooks.createPlaybookTitle}
            inputProps={{ 'data-uid': manage$.playbooks.createPlaybookTitleInput }}
            label="Playbook title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          data-uid={manage$.playbooks.createPlaybookCancelBtn}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          data-uid={manage$.playbooks.createPlaybookSaveBtn}
          disabled={title.length === 0}
          onClick={() => saveObjective(title)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
