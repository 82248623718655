import { ApolloError } from '@apollo/client';
import { GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD } from '@mui/x-data-grid-premium';
import { formatDistanceToNow, fromUnixTime, parse } from 'date-fns';
import { format, type FormatOptionsWithTZ, toZonedTime } from 'date-fns-tz';
import type { GraphQLFormattedError } from 'graphql';
import { xor } from 'lodash';

import { DATE_FORMAT_DATE_AND_TIME_EXPANDED } from './constants';

/**
 * Function to check if a value is not null.
 * @param value to check if it's null
 * @returns type guarded NonNullable<T>
 */
export function isNonNull<T>(value: T | undefined | null): value is NonNullable<T> {
  return value != null;
}

/**
 * Checks that a switch statement will not hit the default case.
 * @example
 * ```typescript
 * const myValue: 1 = 1;
 * switch (myValue) {
 *   case 1:
 *     return 'hello world';
 *   default:
 *     // myValue is of type `never` here and will fail
 *     // the type checker if a case is not handled
 *     bottom(myValue);
 * }
 * ```
 * @param c should be never - but ts will catch missed case statements
 * @param msg optional message
 */
export function bottom(c: never, msg?: string): never {
  console.error(`hit bottom of switch statement - value=${c} ${msg}`);
  throw new Error(`hit bottom of switch statement - ${msg}`);
}

/**
 * `number.toPrecision` will raise an error if we pass it a value outside of these bounds.
 * So, we just cause a type failure to make sure we don't get into that situation.
 */
export type SignificantDigits =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21;

/**
 * Function to format currency in a shorthand style.
 * @param amount a nullable, signed amount of currency
 * @param significantDigits number of significant digits to present
 * @returns shorthand formatted currency
 */
export function formatUSDCurrencyShort(
  amount: number | null,
  significantDigits: SignificantDigits = 3,
): string {
  if (amount == null) {
    return '–';
  }
  const usdFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    notation: 'compact',
    maximumSignificantDigits: significantDigits,
  });
  return usdFormat.format(amount);
}

/**
 * Computes the delta value for the given numerical inputs.
 * @param first a nullable number, to be subtracted from
 * @param second a nullable number, to subtract with
 * @returns `first-second` if available, `NaN` if any undefined, otherwise `null`
 */
export const computeDelta = (first?: number | null, second?: number | null): number | null => {
  if (first != null && second != null && Number.isFinite(first) && Number.isFinite(second)) {
    return first - second;
  }
  if (first === undefined || second === undefined) {
    return NaN;
  }
  return null;
};

/**
 * Truncates the given float value to the desired number of decimal places, without rounding.
 * @param v float value to truncate
 * @param digits the number of decimals to truncate to; defaults to 2
 * @returns truncated float
 */
export const truncateFloat = (v: number, digits = 2): number =>
  Math.trunc(v * Math.pow(10, digits)) / Math.pow(10, digits);

const LEGACY_ROUTE_MAPPING: Record<string, string> = {
  ALL: 'all',
  NEW: 'new',
  TOP_ARR: 'top-arr',
  LOWEST_ENGAGEMENT: 'lowest-engagement',
  UPCOMING_RENEWAL: 'upcoming-renewal',
};
/**
 * Detects if the given list identifier matches a legacy route; if so, a mapping to
 * the current route is provided.
 * @param sourceListId list identifier to check
 * @returns obj containing `isLegacy` flag and `listId` to be routed to (identical to
 *          input if non-legacy)
 */
export const getReroutedListId = (
  sourceListId?: string,
): { isLegacy: boolean; listId?: string } => ({
  isLegacy: LEGACY_ROUTE_MAPPING[sourceListId || ''] != null,
  listId: LEGACY_ROUTE_MAPPING[sourceListId || ''] || sourceListId,
});

export const NUMBER_FORMATTER = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0, // prefer whole numbers
});
export const AVERAGE_NUMBER_FORMATTER = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1, // no more than 1 decimal place
});
export const PERCENT_NUMBER_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 1, // no more than 1 decimal place
});
export const WHOLE_PERCENT_NUMBER_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0, // no decimal places
});

export function transformDate(dv: string | number, opts?: { localized: boolean }): Date;
export function transformDate(
  dv: string | number | null,
  opts?: { localized: boolean },
): Date | null;
/**
 * Converts given nullable number value to a Date object or null.
 * @param dv nullable date string or number representing Unix timestamp
 * @param opts transformation options when parsing given date values
 * @param opts.localized boolean indicator if date should be parsed into UTC or localized Date
 * @returns Date object or null
 */
export function transformDate(dv: string | number | null, { localized } = { localized: false }) {
  if (dv == null) {
    return dv;
  }
  if (typeof dv === 'string') {
    if (/\d{4}-\d{1,2}-\d{1,2}/.test(dv)) {
      const localDate = parse(dv, 'y-M-d', new Date(0));
      const utcDate = toZonedTime(localDate, 'UTC');
      return localized ? localDate : utcDate;
    }
    return null;
  }
  if (!Number.isFinite(dv) || dv < 0) {
    return null;
  }
  const localDate = fromUnixTime(dv);
  const utcDate = toZonedTime(localDate, 'UTC');
  return localized ? localDate : utcDate;
}

/**
 * Regex test for SF links from a given string
 * @param str string that may contain an SF domain
 * @returns boolean presence flag
 */
export const isSalesforceLink = (str: string): boolean =>
  /(my|lightning)?\.(salesforce|force|site|salesforce-sites)\.com/i.test(str);

/**
 * Helper to construct a MUI X multiple-grouped column field name, given a field in a DataPremiumGrid.
 * @param field field of a column in a DataPremiumGrid
 * @returns multi-grouped column field value
 */
export const getMUIMultiGroupedColumnField = (field: string) =>
  field
    ? GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD.replace(/group__$/, `group_${field}__`)
    : GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD;

/**
 * Lifted from https://github.com/HubSpot/humanize/blob/ee0e597f7bf73c42f6841b2f60647b7f1f787cac/src/humanize.js#L268
 * @param params params for pluralizing
 * @param params.count count context
 * @param params.singular singular form
 * @param params.plural plural form, defaults to appended `s`
 * @returns pluralized string, when applicable
 */
export const pluralize = ({
  count,
  singular,
  plural = `${singular}s`,
}: {
  count: number;
  singular: string;
  plural?: string;
}): string => {
  if (!Number.isFinite(count)) {
    return singular;
  }
  return Math.abs(count) === 1 ? singular : plural;
};

/**
 * Formats a given Date as the string value that a MUI X Date filter input expects.
 * @param date Date object to format
 * @param options additional options to format with
 * @returns string format of a date timestamp
 */
export const getMUIDateValue = (date: Date, options?: FormatOptionsWithTZ) =>
  format(date, "yyyy-MM-dd'T'HH:mm", options);

/**
 * Sleep function.
 * @param ms milliseconds to sleep
 * @returns promise that resolves after ms delay
 */
export const sleep = async (ms: number): Promise<number> =>
  new Promise((res) => {
    const data = window.setTimeout(() => res(data), ms);
  });

/**
 * Stringifies a given nullable value, if defined
 * @param v nullable primitive value
 * @returns stringified value or undefined
 */
export const toOptionalString = <T extends string | number | Date>(
  v: T | null | undefined,
): string | undefined => (v != null ? v.toString() : undefined);

const toRelativeTimeDescription = <T extends Date>(v: T): string =>
  formatDistanceToNow(v, { addSuffix: true });

interface ToDescriptiveDateTitleOptions extends FormatOptionsWithTZ {
  prefix?: string;
}

export const toDescriptiveDateTitle = <T extends Date>(
  v: T | null | undefined,
  { prefix = '', ...formatOptions }: ToDescriptiveDateTitleOptions = { prefix: '' },
): string | undefined => {
  if (v) {
    return `${prefix}${toRelativeTimeDescription(v)}\n${format(v, DATE_FORMAT_DATE_AND_TIME_EXPANDED, formatOptions)}`;
  }
  return toOptionalString(v);
};

/**
 * Coalesce all of the NaN values of an object to 0.
 * @param obj an object to override
 * @returns object with NaN number values coalesced to null
 */
export function coalesceDeepNaNFields<T extends object>(obj: T): T {
  return Object.entries(obj).reduce((o, [field, value]) => {
    switch (typeof value) {
      case 'number':
        if (isNaN(value)) {
          value = 0;
        }
        break;
      case 'object':
        if (value != null) {
          value = coalesceDeepNaNFields(value);
        }
        break;
    }
    return {
      ...o,
      // we need to clean out all of the `NaN` values from the data
      [field]: value,
    };
  }, obj);
}

// lifted below from https://github.com/lodash/lodash/issues/4852#issuecomment-666366511
/**
 * Extracts an array of only unique items from a given array of items.
 * @param arr array of items
 * @returns array of unique items
 */
export const uniques = <T>(arr: Array<T>): Array<T> => xor(...arr.map((a) => [a]));
/**
 * Extracts an array of only duplicate items from a given array of items.
 * @param arr array of items
 * @returns array of duplicate items
 */
export const duplicates = <T>(arr: Array<T>): Array<T> => xor(arr, uniques(arr));

/**
 * Collapses given error message alongside Apollo error messages for simple console warnings.
 * @param message context-specific error messaging
 * @param apolloError possible Apollo errors
 * @returns list of strings or Errors
 */
export const toLoggableErrorMessages = <T extends ApolloError>(
  message: string,
  apolloError: T | undefined,
): (string | Error | GraphQLFormattedError)[] => {
  if (apolloError != null) {
    const { clientErrors, graphQLErrors } = apolloError;
    return [message, ...clientErrors, ...graphQLErrors];
  }

  return [message, 'no error provided'];
};
