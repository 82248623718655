import { ObjValues } from '../types/util';

export const login$ = {
  username: 'login-username-field',
  password: 'login-password-field',
  signInBtn: 'login-sign-in-button',
  fedSignInBtn: 'login-fed-sign-in-button',
} as const;

export type Login$Values = ObjValues<typeof login$>;
