import { useMutation } from '@apollo/client';
import { CircularProgress, Paper } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CreateDataConnectorDocument, DataConnectorSource } from '../../../../graphql/generated';
import { useDataConnectors } from '../../../../hooks/dataConnectors';
import { PartialDataConnector } from '../../../../models/dataConnector';
import { ResultType } from '../../../../models/result';
import { DataConnector } from './DataConnector/DataConnector';
import { dataConnectorReducer, initialState } from './dataConnectorReducer';
import { getDataConnectorSourceEnumKeyByValue } from './utils';

export const DataConnectorList = () => {
  const [dataConnectors, setDataConnectors] = useState<Array<PartialDataConnector>>([]);
  const connectorsResult = useDataConnectors();
  const [createDataConnection, createDataConnectionResult] = useMutation(
    CreateDataConnectorDocument,
  );
  const [queryParameters] = useSearchParams();
  const { sourceType: sourceTypeParam } = useParams();
  const nav = useNavigate();
  const [state, dispatch] = useReducer(dataConnectorReducer, initialState);

  // Effect logic for getting data after successful oAuth login
  useEffect(() => {
    const secretId = queryParameters.get('secret_id');
    if (secretId && createDataConnectionResult.called === false && sourceTypeParam) {
      dispatch({
        type: 'set-payload',
        payload: {
          airbyteSecretId: secretId,
          sourceType: getDataConnectorSourceEnumKeyByValue(sourceTypeParam),
        },
      });
      nav('/connectors');
    }
  }, [nav, queryParameters, sourceTypeParam, dataConnectors, createDataConnectionResult.called]);

  // Prepare the payload for creating a data connector
  const prepareConnectorPayload = useCallback(() => {
    const { airbyteSecretId, sourceType } = state;
    return {
      airbyteSecretId,
      sourceType: getDataConnectorSourceEnumKeyByValue(sourceType),
    };
  }, [state]);

  // Update the state of connectors to pending based on the current source type
  const updateConnectorsPendingState = useCallback(
    (sourceType: string) => {
      setDataConnectors(
        dataConnectors.map((conn) => ({
          ...conn,
          isPending: conn.sourceType === sourceType,
        })),
      );
    },
    [dataConnectors],
  );

  // Effect logic to create a data connector
  useEffect(() => {
    const doesConnectorExist = () =>
      dataConnectors.some((conn) => conn.sourceType === state.sourceType && conn.id);

    if (
      state.airbyteSecretId &&
      state.sourceType &&
      !createDataConnectionResult.called &&
      dataConnectors.length > 0 &&
      !doesConnectorExist()
    ) {
      const connCreatePayload = prepareConnectorPayload();
      updateConnectorsPendingState(state.sourceType);
      dispatch({
        type: 'clear',
      });
      createDataConnection({ variables: { dataConnectorCreateInput: connCreatePayload } });
    }
  }, [
    createDataConnection,
    createDataConnectionResult.called,
    dataConnectors,
    state,
    dispatch,
    prepareConnectorPayload,
    updateConnectorsPendingState,
  ]);

  // Check if the mutation has been called and data is available
  const shouldUpdateConnectors = useMemo((): boolean => {
    return (
      createDataConnectionResult.called && !!createDataConnectionResult.data?.payload.dataConnector
    );
  }, [createDataConnectionResult.called, createDataConnectionResult.data]);

  // Function to update connectors with new data
  const updateDataConnectors = useCallback(
    (newDataConnector: PartialDataConnector) => {
      const updatedConnectors = dataConnectors.map((conn) => {
        if (conn.sourceType === newDataConnector.sourceType) {
          return {
            ...conn,
            id: newDataConnector.id,
            sourceType: newDataConnector.sourceType,
            isPending: false,
          };
        }
        return conn;
      });
      setDataConnectors(updatedConnectors);
    },
    [dataConnectors],
  );

  // Effect logic to update connectors after a new one was created
  useEffect(() => {
    if (shouldUpdateConnectors) {
      const newDataConnector = createDataConnectionResult?.data?.payload.dataConnector;
      if (newDataConnector) {
        updateDataConnectors(newDataConnector);
        createDataConnectionResult.reset();
        nav('/connectors');
      }
    }
  }, [
    createDataConnectionResult,
    dataConnectors,
    setDataConnectors,
    nav,
    shouldUpdateConnectors,
    updateDataConnectors,
  ]);

  // Effect logic to generate a list of sources and correspondent active connectors to display in the list
  useEffect(() => {
    if (connectorsResult.state === ResultType.Value) {
      const connectorList: Array<PartialDataConnector> = [];

      Object.values(DataConnectorSource).forEach((source) => {
        const foundConn = connectorsResult.value.find((ac) => ac.sourceType === source);
        const activeConn: PartialDataConnector = foundConn || { sourceType: source };

        connectorList.push(activeConn);
      });

      setDataConnectors(connectorList);
    }
  }, [connectorsResult]);

  const renderConnectors = useMemo(() => {
    if (dataConnectors.length > 0) {
      return dataConnectors?.map((conn) => (
        <DataConnector
          key={conn.sourceType}
          sourceType={conn.sourceType}
          id={conn.id}
          isPending={conn?.isPending ?? false}
        />
      ));
    }

    return null;
  }, [dataConnectors]);

  if (connectorsResult.state === ResultType.Loading) {
    return <CircularProgress data-uid="loader" />;
  }

  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.magic.connectorListBackground.main,
        borderRadius: '10px',
        maxWidth: '60%',
        minWidth: '60%',
      }}
    >
      {renderConnectors}
    </Paper>
  );
};
