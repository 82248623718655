import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { noop } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MINIMUM_HEATMAP_GRID_SIZE } from '../../../constants';
import { HeatmapProvider } from '../../../contexts';
import { usePersistenceContext } from '../../../contexts/persistenceContext';
import { useReefNavContext } from '../../../contexts/reefNavContext';
import { PersistedAccountSearchProvider } from '../../../contexts/SearchProvider';
import { useClientAccounts } from '../../../hooks/client';
import { useAccountFilter } from '../../../hooks/filters';
import { useListTable } from '../../../hooks/useListTable';
import { Account } from '../../../models/account';
import { Filter } from '../../../models/filters';
import { ResultType } from '../../../models/result';
import { asUUID, UUID } from '../../../models/uuid';
import { filterCustomers$ } from '../../../selectors';
import { FailedResult } from '../../Alerts';
import { BuildSprintDrawer } from '../../BuildSprintDrawer';
import { CreateDraftSprintDialog } from '../../Dialogs/CreateDraftSprintDialog';
import { ListOfCustomersTable } from '../../Tables/ListOfCustomersTable';
import { FilterCustomersOverview } from './FilterCustomersOverview';

const drawerResponsiveWidths = [350, 380, 400, 440, 500];

export const AllCustomers = () => {
  const { setRecentFilterId } = useReefNavContext();
  const allCustomers = useClientAccounts();

  // reset recent filter id when viewing all customers
  useEffect(() => setRecentFilterId(undefined), [setRecentFilterId]);

  if (allCustomers.state === ResultType.Loading) {
    return <LinearProgress />;
  }

  if (allCustomers.state === ResultType.Error || allCustomers.state === ResultType.NoValue) {
    return <FailedResult resultType="all accounts filter" error={allCustomers.value} />;
  }

  return (
    <HeatmapProvider>
      <FilterCustomersTable
        filterId={undefined}
        filterName="All Customers"
        accounts={allCustomers.value}
        filters={[]}
      />
    </HeatmapProvider>
  );
};

export const FilterCustomers = () => {
  const params = useParams();
  const { setFilterId } = usePersistenceContext();
  const { setRecentFilterId } = useReefNavContext();

  const filterId = useMemo(() => {
    if ('filterId' in params && params.filterId != null) {
      return asUUID(params.filterId);
    }
    return undefined;
  }, [params]);

  // attach filter id to persistence keys
  useEffect(() => {
    setFilterId(filterId);
    setRecentFilterId(filterId);
    return () => setFilterId(undefined);
  }, [filterId, setFilterId, setRecentFilterId]);

  const filterResult = useAccountFilter(filterId);

  if (filterResult.state === ResultType.Loading) {
    return <LinearProgress />;
  }

  if (filterId == null) {
    return <FailedResult resultType="no filter specified" />;
  }

  if (filterResult.state === ResultType.Error || filterResult.state === ResultType.NoValue) {
    return (
      <FailedResult resultType={`accounts for filter ${filterId}`} error={filterResult.value} />
    );
  }

  return (
    <HeatmapProvider>
      <FilterCustomersTable
        filterId={asUUID(filterId)}
        filterName={filterResult.value.name}
        accounts={filterResult.value.accounts}
        filters={filterResult.value.filters}
      />
    </HeatmapProvider>
  );
};

interface FilterCustomersTableProps {
  filterId: UUID | undefined;
  filterName: string;
  accounts: Account[];
  filters: Filter[];
}
const FilterCustomersTable = ({
  filterId,
  filterName,
  accounts,
  filters,
}: FilterCustomersTableProps) => {
  const listWrapperStyles = useMemo(
    (): SxProps<Theme> => ({
      width: drawerResponsiveWidths.map((w) => `calc(100% - ${w}px)`),
      mr: drawerResponsiveWidths.map((w) => `${w}px`),
    }),
    [],
  );

  const {
    newSprintAccounts,
    selectedHeatmapAccounts,
    sprintDialogOpen,
    draftSprintResult,
    activityGroups,

    onToggleHeatmapTile,
    onToggleAllHeatmapTiles,

    onHighlightAccount,
    onUnhighlightAccount,

    heatmapConfig,
    heatmapVariation,
    setHeatmapVariation,

    createSprintDisabled,
    startSprint,
    closeSprintDialog,

    newSprintAccountIds,
    setNewSprintAccountIds,
  } = useListTable({
    id: filterId ?? asUUID('all-customers'),
    accounts: accounts,
    heatmapSize: MINIMUM_HEATMAP_GRID_SIZE,
  });

  return (
    <>
      <BuildSprintDrawer
        key={filterId}
        width={drawerResponsiveWidths}
        accounts={accounts}
        heatmapConfig={heatmapConfig}
        heatmapVariation={heatmapVariation}
        onSelectHeatmapVariation={setHeatmapVariation}
        onSelectHeatmapTile={onToggleHeatmapTile}
        onToggleAllHeatmapTiles={onToggleAllHeatmapTiles}
        createSprintDisabled={createSprintDisabled}
        onStartSprint={startSprint}
      />
      <PersistedAccountSearchProvider>
        <Box sx={listWrapperStyles}>
          <FilterCustomersOverview
            filterId={filterId}
            filterName={filterName}
            filterOperations={filters}
          >
            {draftSprintResult.state === ResultType.Loading && <LinearProgress />}
            {activityGroups.length > 0 ? (
              activityGroups.map(({ element, grouped }) => (
                <Accordion
                  data-uid={filterCustomers$.details.customersByActionTable}
                  key={`${element.id}-list-group`}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id={`${element.id}-list-header`}
                    sx={{ backgroundColor: 'ButtonShadow' }}
                  >
                    <Typography data-uid={filterCustomers$.details.customersByActionTableTitle}>
                      {element.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ListOfCustomersTable
                      accounts={grouped}
                      disableSelection
                      rowSelectionModel={[]}
                      setRowSelectionModel={noop}
                      onMouseEnterAccount={onHighlightAccount}
                      onMouseLeaveAccount={onUnhighlightAccount}
                      offsetHeight="9rem"
                      disableFiltering
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <ListOfCustomersTable
                key={filterId ?? asUUID('all-customers')}
                accounts={selectedHeatmapAccounts}
                rowSelectionModel={newSprintAccountIds}
                setRowSelectionModel={setNewSprintAccountIds}
                onMouseEnterAccount={onHighlightAccount}
                onMouseLeaveAccount={onUnhighlightAccount}
                offsetHeight="9rem"
                disableFiltering
              />
            )}
          </FilterCustomersOverview>
        </Box>
      </PersistedAccountSearchProvider>
      <CreateDraftSprintDialog
        open={sprintDialogOpen}
        onClose={closeSprintDialog}
        accounts={newSprintAccounts}
      />
    </>
  );
};
