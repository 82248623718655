import { Box, Card, Snackbar } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useMySprints } from '../../../../hooks/sprint';
import { useUserSprintsGrid } from '../../../../hooks/useUserSprintsGrid';
import { ResultType } from '../../../../models/result';
import { userWork$ } from '../../../../selectors';
import { pluralize } from '../../../../utils';
import { FailedResult } from '../../../Alerts';
import { CustomGrid } from '../../../Tables/CustomGrid';

export const MySprints = () => {
  const mySprintsResult = useMySprints();
  const location = useLocation();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(
    location.state?.startSprintSnackbarOpen,
  );

  const loading = useMemo(
    () => mySprintsResult.state === ResultType.Loading,
    [mySprintsResult.state],
  );
  const sprints = useMemo(
    () => (mySprintsResult.state === ResultType.Value ? mySprintsResult.value : []),
    [mySprintsResult.state, mySprintsResult.value],
  );
  const sprintsGridProps = useUserSprintsGrid({ sprints });

  if (mySprintsResult.state === ResultType.Error || mySprintsResult.state === ResultType.NoValue) {
    return <FailedResult resultType="account sprint details" error={mySprintsResult.value} />;
  }

  return (
    <Card data-uid={userWork$.sprints.section} elevation={0}>
      <Box
        sx={{
          '& .MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="true"]': {
            color: (theme: Theme) => theme.palette.success.main,
          },
        }}
      >
        <CustomGrid
          loading={loading}
          GridWrapperBoxProps={{ outer: { sx: { height: 'calc(100vh - 12.5rem)' } } }}
          {...sprintsGridProps}
        />
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        message={`Successfully started ${location.state?.numOfSprints ?? 1} new ${pluralize({
          count: location.state?.numOfSprints ?? 1,
          singular: 'sprint',
        })}!`}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Card>
  );
};
