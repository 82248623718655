import { Info as InfoIcon } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';

import { Objective } from '../../../models/sprint';
import { UUID } from '../../../models/uuid';
import { action$, objective$ } from '../../../selectors';
import { ActionLinks } from '../../ActionLinks';
import { ClampedText } from '../../ClampedText';

export interface PlaybookPickerProps {
  /**
   * The selected playbook.
   */
  selectedPlaybookId: UUID | undefined;
  /**
   * The list of playbooks.
   */
  playbooks: Objective[] | undefined;
  /**
   * Called when an objective is selected from the picker.
   * @param selected the playbook to select
   */
  onChange(selected: Objective): void;
}

export const PlaybookPicker = ({
  selectedPlaybookId,
  playbooks,
  onChange,
}: PlaybookPickerProps) => {
  const ActionList = useCallback(
    ({ playbook }: { playbook: Objective }) => {
      if (playbook.hasActions && playbook.actions) {
        return playbook.actions.map((action, actionIndex) => (
          <ListItem
            key={`playbook-${selectedPlaybookId}-action-${actionIndex}`}
            disableGutters
            disablePadding
            component="li"
            sx={{
              fontFamily: (theme) => theme.typography.fontFamily,
              fontWeight: 300,
            }}
          >
            <ListItemText
              sx={{ paddingRight: 3 }}
              data-uid={action$.card.component}
              disableTypography
              primary={
                <ClampedText
                  text={action.title}
                  limit={32}
                  maxLines={3}
                  data-uid={action$.card.title}
                />
              }
              secondary={
                action.links.length > 0 ? (
                  <Box sx={{ mt: 1 }}>
                    <ActionLinks links={action.links} stopPropagation />
                  </Box>
                ) : undefined
              }
            />
          </ListItem>
        ));
      }

      return [];
    },
    [selectedPlaybookId],
  );

  if (!playbooks) {
    return <LinearProgress />;
  }

  return (
    <List data-uid={objective$.list.component} sx={{ margin: 0, padding: 0 }}>
      {playbooks.map((playbook, index) => (
        <div key={playbook.id}>
          <ListItemButton
            data-uid={objective$.list.item(index)}
            disabled={!playbook.hasActions}
            onClick={() => onChange(playbook)}
          >
            <ListItemIcon>
              <Radio edge="end" checked={selectedPlaybookId === playbook.id} />
            </ListItemIcon>
            <ListItemText
              data-uid={objective$.card.component}
              primary={<Typography data-uid={objective$.card.title}>{playbook.title}</Typography>}
              secondary={
                !playbook.hasActions ? (
                  <>
                    <InfoIcon fontSize="inherit" /> No actions in this playbook.
                  </>
                ) : undefined
              }
            />
          </ListItemButton>
          <Collapse in={selectedPlaybookId === playbook.id}>
            <List
              component="ol"
              sx={{
                listStyleType: 'decimal',
                marginLeft: 11,
                '& li': {
                  display: 'list-item',
                },
              }}
            >
              <Typography variant="caption" sx={{ fontWeight: 700, marginLeft: -2 }}>
                ACTIONS
              </Typography>
              <ActionList playbook={playbook} />
            </List>
          </Collapse>
          <Divider sx={{ marginX: -10 }} />
        </div>
      ))}
    </List>
  );
};
