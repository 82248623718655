import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { EMAIL_REGEX } from '../../../constants';
import {
  AuthAction,
  AuthActionType,
  AuthStatus,
  ImpersonationError,
  useAuthError,
  useReefAuthContext,
} from '../../../contexts/reefAuthContext';
import googleLogo from '../../../images/googleLogo.svg';
import { login$ } from '../../../selectors';

/**
 * A component that exposes form fields to log a user into the app.
 * @returns element that logs the user into the app
 */
export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loggingIn, setLoggingIn] = useState(false);

  const [authCtx, { authenticate }] = useReefAuthContext();
  const [authError, clearAuthError] = useAuthError();

  const navigate = useNavigate();
  useEffect(() => {
    if (authCtx.status === AuthStatus.Confirming && !authCtx.confirmed) {
      navigate('/sign-up/challenge');
    }
  }, [authCtx, navigate]);

  // login callback used to get and validate the session - then navigate to the app
  const handleLogin = useCallback(
    async (opts: AuthAction) => {
      clearAuthError();

      setLoggingIn(true);
      await authenticate(opts);
      setLoggingIn(false);
    },
    [clearAuthError, authenticate],
  );

  return (
    <Stack spacing={2} maxWidth={(theme) => theme.spacing(48)}>
      <Button
        data-uid={login$.fedSignInBtn}
        disabled={authCtx.status !== AuthStatus.SignedOut}
        variant="contained"
        color="inherit"
        onClick={() => handleLogin({ type: AuthActionType.Federated })}
      >
        <Box sx={{ my: 1, display: 'flex' }}>
          <img src={googleLogo} />
          <Box sx={{ pl: 2 }}>Continue with Google</Box>
        </Box>
      </Button>
      <Divider textAlign="center" role="presentation">
        <Typography>OR</Typography>
      </Divider>
      <Stack spacing={1}>
        <TextField
          data-uid={login$.username}
          fullWidth
          variant="outlined"
          label="Email"
          name="username"
          type="email"
          required
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
        <TextField
          data-uid={login$.password}
          fullWidth
          variant="outlined"
          label="Password"
          name="password"
          required
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <LoadingButton
          data-uid={login$.signInBtn}
          sx={{ my: 'auto' }}
          variant="contained"
          color="primary"
          size="large"
          disabled={!EMAIL_REGEX.test(username)}
          onClick={() => handleLogin({ type: AuthActionType.UserPass, username, password })}
          loading={loggingIn}
        >
          Sign in
        </LoadingButton>
        <Typography variant="caption">
          Don&apos;t have an account?{' '}
          <Link component={RouterLink} sx={{ cursor: 'pointer' }} to="/sign-up">
            Sign up
          </Link>
        </Typography>
      </Stack>
      {authError != null ? (
        authError instanceof ImpersonationError ? (
          <Alert severity="error">
            <AlertTitle>Failed to impersonate user.</AlertTitle>
            Check to make sure that you entered the impersonating email correctly.
          </Alert>
        ) : (
          <Alert severity="error">{authError.message}</Alert>
        )
      ) : null}
    </Stack>
  );
};
