import { useMutation } from '@apollo/client';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
  CreateActionDocument,
  GetClientObjectivesDocument,
  GetObjectiveActionsDocument,
  LinkInput,
} from '../../../graphql/generated';
import { useCurrentUser } from '../../../hooks/user';
import { ResultType } from '../../../models/result';
import { UUID } from '../../../models/uuid';
import { manage$ } from '../../../selectors';
import { ActionLinks } from '../../ActionLinks';

interface LinkInputs {
  title: string;
  url: string;
  index?: number;
}

interface OpenCreateActionDialogOptions {
  open: true;
  /**
   * The objective that the action is associated with for this link.
   */
  objectiveId: UUID;
  /**
   * The rank of the action to be created.
   */
  rank: number;
}

interface ClosedCreateActionDialogOptions {
  open: false;
  objectiveId: undefined;
  rank: undefined;
}

export type CreateActionDialogOptions =
  | OpenCreateActionDialogOptions
  | ClosedCreateActionDialogOptions;

// use a type alias here because TS doesn't support `extends` with non-statically typed
// properties with the following error.
// `An interface can only extend an object type or intersection of object types with statically known members`
type CreateActionDialogProps = CreateActionDialogOptions & {
  /**
   * Called when the dialog is closed.
   */
  onClose: () => void;
};

export const CreateActionDialog = ({
  open,
  onClose,
  objectiveId,
  rank,
}: CreateActionDialogProps) => {
  const [createActionApi, createActionResult] = useMutation(CreateActionDocument, {
    refetchQueries: [
      { query: GetClientObjectivesDocument },
      { query: GetObjectiveActionsDocument, variables: { id: objectiveId } },
    ],
  });

  const [linkInputs, setLinkInputs] = useState<LinkInputs>();

  const userResult = useCurrentUser();

  const [title, setTitle] = useState('');
  const [links, setLinks] = useState<LinkInput[]>([]);

  useEffect(() => {
    // if we successfully created a client action, we can close this dialog
    if (createActionResult.data?.payload.action != null) {
      onClose();
    }
  }, [createActionResult, onClose]);

  const onAfterClose = useCallback(() => {
    // after the dialog is hidden, we want to reset component state
    setTitle('');
    setLinks([]);
    setLinkInputs(undefined);
    if (createActionResult.called) {
      createActionResult.reset();
    }
  }, [createActionResult]);

  const isLinkValid = useCallback(
    (linkInputs: LinkInputs): boolean =>
      linkInputs.title.trim().length > 0 && linkInputs.url.trim().length > 0,
    [],
  );

  const addLinkToLinksState = useCallback(
    (linkInputs: LinkInputs) => {
      if (isLinkValid(linkInputs)) {
        if (linkInputs.index != null) {
          // update the link at the specified index
          setLinks([
            ...links.slice(0, linkInputs.index),
            { title: linkInputs.title, url: linkInputs.url },
            ...links.slice(linkInputs.index + 1),
          ]);
        } else {
          setLinks([...links, { title: linkInputs.title, url: linkInputs.url }]);
        }
        setLinkInputs(undefined);
      }
    },
    [isLinkValid, links],
  );

  const createAction = useCallback(
    (author: string, objectiveId: UUID, title: string, rank: number) =>
      createActionApi({
        variables: {
          objectiveId,
          action: {
            author,
            title,
            links,
            isPublished: true,
            rank,
          },
        },
      }),

    [createActionApi, links],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth TransitionProps={{ onExited: onAfterClose }}>
      <DialogTitle>Create a new Action</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <TextField
            fullWidth
            data-uid={manage$.playbooks.createActionTitle}
            inputProps={{ 'data-uid': manage$.playbooks.createActionTitleInput }}
            label="Action title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <ActionLinks
            links={links}
            mode="edit"
            onClick={(link, index) => setLinkInputs({ ...link, index })}
            onDelete={(_, index) => setLinks([...links.slice(0, index), ...links.slice(index + 1)])}
          />
        </Box>
        {linkInputs != null && (
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                autoFocus
                label="Link title"
                size="small"
                value={linkInputs?.title ?? ''}
                onChange={(e) => setLinkInputs({ ...linkInputs, title: e.target.value })}
              />
              <TextField
                label="Url"
                size="small"
                type="url"
                value={linkInputs?.url ?? ''}
                onChange={(e) => setLinkInputs({ ...linkInputs, url: e.target.value })}
              />
              <Box sx={{ my: 'auto' }}>
                <Button
                  variant="contained"
                  color="primary"
                  // TODO [REEF-1119] we may want to add copy to describe this disabled state
                  disabled={!isLinkValid(linkInputs)}
                  onClick={() => addLinkToLinksState(linkInputs)}
                >
                  Save Link
                </Button>
              </Box>
            </Stack>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setLinkInputs({ title: '', url: '' })}
          >
            Add Link
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          data-uid={manage$.playbooks.createActionCancelBtn}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          // TODO [REEF-1119] we may want to add copy to describe this disabled state
          disabled={
            userResult.state !== ResultType.Value || title.trim().length < 3 || objectiveId == null
          }
          color="primary"
          variant="contained"
          data-uid={manage$.playbooks.createActionSaveBtn}
          onClick={() =>
            userResult.state === ResultType.Value &&
            open &&
            createAction(userResult.value.name, objectiveId, title, rank)
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
