import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { UUID } from '../../../../models/uuid';
import { FieldWrapper } from '../Account/FieldWrapper';

// name cell needs id at a minimum to navigate
type SprintNameCellProps = GridRenderCellParams<{ sprint: { id: UUID; name: string } }, string>;

export const SprintNameCell = ({ field, value, row }: SprintNameCellProps) => {
  // clicking on the link should not grid row change selection value
  const onLinkClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <Link component={RouterLink} to={`/sprint/${row.sprint.id}`} onClick={onLinkClick}>
          {value}
        </Link>
      ) : (
        <>&ndash;</>
      )}
    </FieldWrapper>
  );
};
