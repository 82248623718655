import { ApolloError, LazyQueryExecFunction, useLazyQuery, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  DataConnectorSource,
  Exact,
  GetClientDataConnectorsDocument,
  GetDataConnectorLoginUrlDocument,
  GetDataConnectorLoginUrlQuery,
} from '../graphql/generated';
import { PartialDataConnector } from '../models/dataConnector';
import { Result, ResultType, useResultFromQuery } from '../models/result';

export const useDataConnectors = (): Result<Array<PartialDataConnector>, ApolloError> => {
  const connectorsResult = useResultFromQuery(useQuery(GetClientDataConnectorsDocument));

  return useMemo((): Result<Array<PartialDataConnector>, ApolloError> => {
    if (connectorsResult.state === ResultType.Value) {
      return {
        ...connectorsResult,
        value: connectorsResult.value.client.dataConnectors,
      };
    }

    return connectorsResult;
  }, [connectorsResult]);
};

export interface UseDataConnectorLoginUrlProps {
  sourceType: DataConnectorSource;
  redirectUrl: string;
}

type DataConnectorLoginUrlType = [
  LazyQueryExecFunction<
    GetDataConnectorLoginUrlQuery,
    Exact<{
      sourceType: DataConnectorSource;
      redirectUrl: string;
    }>
  >,
  Result<string, ApolloError>,
];

export const useDataConnectorLoginUrl = ({
  sourceType,
  redirectUrl,
}: UseDataConnectorLoginUrlProps): DataConnectorLoginUrlType => {
  const [getDataConnectorLoginUrl, queryResult] = useLazyQuery(GetDataConnectorLoginUrlDocument, {
    variables: { sourceType: sourceType, redirectUrl: redirectUrl },
  });
  const result = useResultFromQuery(queryResult);

  return useMemo((): DataConnectorLoginUrlType => {
    if (result.state === ResultType.Value) {
      return [
        getDataConnectorLoginUrl,
        {
          ...result,
          value: result.value.dataConnectorLoginUrl,
        },
      ];
    }

    return [getDataConnectorLoginUrl, result];
  }, [getDataConnectorLoginUrl, result]);
};
