import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Fade,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useCallback, useMemo, useState } from 'react';

import { EMAIL_REGEX } from '../../../constants';
import {
  AuthActionType,
  AuthStatus,
  ImpersonationError,
  useAuthError,
  useReefAuthContext,
} from '../../../contexts/reefAuthContext';

/**
 * A component that exposes form fields to log a user into the app as another user.
 * @returns element that logs the user into the app
 */
export const Skeleton = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [impersonationUser, setImpersonationUser] = useState('');

  const [loggingIn, setLoggingIn] = useState(false);

  const [authCtx, { authenticate, logout }] = useReefAuthContext();
  const [authError, clearAuthError] = useAuthError();

  const clientMetadata = useMemo(
    () => ({ impersonation_user: impersonationUser }),
    [impersonationUser],
  );

  // login callback used to get and validate the session - then navigate to the app
  const handleLogin = useCallback(
    async (username: string, password: string) => {
      clearAuthError();
      if (authCtx.status === AuthStatus.SignedOut) {
        setLoggingIn(true);
        await authenticate({ type: AuthActionType.Skeleton, username, password, clientMetadata });
        setLoggingIn(false);
      }
    },
    [authCtx.status, authenticate, clearAuthError, clientMetadata],
  );

  const handleLogout = useCallback(async () => {
    clearAuthError();
    if (authCtx.status === AuthStatus.SignedIn) {
      await logout();
    }
  }, [authCtx.status, clearAuthError, logout]);

  const hasEmailError = useMemo(
    () => impersonationUser.length > 0 && impersonationUser.match(EMAIL_REGEX) == null,
    [impersonationUser],
  );
  const emailError = useMemo(
    (): Pick<TextFieldProps, 'error' | 'helperText'> => ({
      error: hasEmailError,
      helperText: hasEmailError ? 'Invalid email' : undefined,
    }),
    [hasEmailError],
  );

  return (
    <>
      {authCtx.status === AuthStatus.SignedIn && (
        <Stack direction="column">
          <Typography>In order to 💀, you must first sign-out.</Typography>
          <Box sx={{ mt: 3 }}>
            <Button variant="contained" onClick={handleLogout}>
              Sign out
            </Button>
          </Box>
        </Stack>
      )}
      {authCtx.status === AuthStatus.SignedOut && (
        <Stack spacing={2} alignItems="flex-start">
          <Grid2 container spacing={2}>
            <Grid2 xs={4}>
              <TextField
                variant="outlined"
                label="Username"
                name="username"
                autoFocus
                fullWidth
                required
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                disabled={loggingIn}
              />
            </Grid2>
            <Grid2 xs={4}>
              <TextField
                variant="outlined"
                label="Password"
                name="password"
                fullWidth
                required
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                disabled={loggingIn}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Fade in={!!username && !!password}>
                <TextField
                  variant="outlined"
                  label="Impersonate Email"
                  name="impersonate email"
                  fullWidth
                  required
                  onChange={(e) => setImpersonationUser(e.target.value)}
                  value={impersonationUser}
                  disabled={loggingIn}
                  {...emailError}
                />
              </Fade>
            </Grid2>
          </Grid2>
          <Fade in={!!username && !!password && !!impersonationUser}>
            <LoadingButton
              disabled={emailError.error}
              variant="outlined"
              onClick={() => handleLogin(username, password)}
              loading={loggingIn}
            >
              Sign in
            </LoadingButton>
          </Fade>
          {authError != null ? (
            authError instanceof ImpersonationError ? (
              <Alert severity="error">
                <AlertTitle>Failed to impersonate user.</AlertTitle>
                Check to make sure that you entered the impersonating email correctly.
              </Alert>
            ) : (
              <Alert severity="error">{authError.message}</Alert>
            )
          ) : null}
        </Stack>
      )}
    </>
  );
};
