import React from 'react';

import { Renewal } from '../../../hooks/renewal';
import { useRenewalOverviewChartConfig } from '../../../hooks/useRenewalOverviewChartConfig';
import { WaterfallChart } from './WaterfallChart';

interface RenewalOverviewChartProps {
  renewals: Renewal[];
}
export const RenewalOverviewChart = ({ renewals }: RenewalOverviewChartProps) => {
  const barChartProps = useRenewalOverviewChartConfig({ renewals });
  return <WaterfallChart {...barChartProps} />;
};
