import { Close, Settings } from '@mui/icons-material';
import {
  Box,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';

import { useHeatmapContext } from '../../contexts/heatmapContext';
import {
  AccountXAxisMetric,
  AccountYAxisMetric,
  metricLabel,
} from '../../hooks/useAccountChartConfig';
import settingsTurtle from '../../images/settingsTurtle.min.svg';
import { heatmap$ } from '../../selectors';

const heatmapDrawerResponsiveWidths = [300, 350, 400, 400, 400];

const X_AXIS_FIELDS: AccountXAxisMetric[] = [
  'engagement',
  'customHealthscore',
  'productEngagement',
  'productConsumptionPercent',
];
const Y_AXIS_FIELDS: AccountYAxisMetric[] = ['potential', 'customerRevenueBand'];
const getMenuItem = <T extends AccountXAxisMetric | AccountYAxisMetric>(
  field: T,
  index: number,
): ReactNode => (
  <MenuItem
    key={`${field}-${index}`}
    data-uid={
      field === 'potential'
        ? heatmap$.settingsYAxisSelectOptions
        : heatmap$.settingsXAxisSelectOptions
    }
    value={field}
  >
    {metricLabel(field)}
  </MenuItem>
);
const X_AXIS_OPTIONS = X_AXIS_FIELDS.map(getMenuItem);
const Y_AXIS_OPTIONS = Y_AXIS_FIELDS.map(getMenuItem);

export const HeatmapSettingsDrawer = () => {
  const [open, setOpen] = useState(false);
  const { xAxisMetric, setXAxisMetric, setYAxisMetric, yAxisMetric } = useHeatmapContext();
  return (
    <>
      <IconButton data-uid={heatmap$.settingsOpen} size="small" onClick={() => setOpen(true)}>
        <Settings fontSize="inherit" color="primary" />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          width: heatmapDrawerResponsiveWidths,
          '& .MuiDrawer-paper': {
            width: heatmapDrawerResponsiveWidths,
            backgroundImage: `url(${settingsTurtle})`,
            backgroundColor: theme.palette.common.white,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
          },
          // this drawer is one above the ReefNavDrawer for overlay rendering
          zIndex: (theme) => theme.zIndex.drawer + 2,
        })}
      >
        <Box sx={{ p: 4 }}>
          <Stack direction="column" spacing={4}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">Heatmap Filters</Typography>
              <IconButton
                size="small"
                data-uid={heatmap$.settingsClose}
                onClick={() => setOpen(false)}
              >
                <Close color="action" />
              </IconButton>
            </Stack>
            <FormControl fullWidth>
              <InputLabel id="x-axis-metric-input-label">X-Axis</InputLabel>
              <Select
                labelId="x-axis-metric-input-label"
                id="x-axis-select"
                data-uid={heatmap$.settingsXAxisSelect}
                value={xAxisMetric}
                label="X-Axis"
                onChange={(e) => setXAxisMetric(e.target.value as AccountXAxisMetric)}
              >
                {X_AXIS_OPTIONS}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="y-axis-metric-input-label">Y-Axis</InputLabel>
              <Select
                labelId="y-axis-metric-input-label"
                id="y-axis-select"
                data-uid={heatmap$.settingsYAxisSelect}
                value={yAxisMetric}
                label="Y-Axis"
                onChange={(e) => setYAxisMetric(e.target.value as AccountYAxisMetric)}
              >
                {Y_AXIS_OPTIONS}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
