import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SendUserSettingsInviteDocument, UserStatus } from '../../../graphql/generated';
import { User } from '../../../hooks/user';
import { sprint$ } from '../../../selectors';
import { UserAutocomplete } from '../../UserAutocomplete';

export interface SettingsInviteUserDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SettingsInviteUserDialog = ({ open, onClose }: SettingsInviteUserDialogProps) => {
  const [sent, setSent] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [userToInvite, setUserToInvite] = useState<User | null>(null);

  const [sendInvite] = useMutation(SendUserSettingsInviteDocument);
  const sendUserInvite = useCallback(
    async (userToInvite: User) => {
      await sendInvite({
        variables: {
          inviteeId: userToInvite.id,
        },
      });
    },
    [sendInvite],
  );

  const disableInvite = useMemo(() => userToInvite == null || sent, [sent, userToInvite]);

  const handleUserInvite = useCallback(async () => {
    if (userToInvite) {
      setSent(true);
      await sendUserInvite(userToInvite);
      setSent(false);
      setSnackbarOpen(true);
      onClose();
    }
  }, [onClose, sendUserInvite, userToInvite]);

  const handleOnUserSelect = useCallback((selectedUser: User) => {
    if (selectedUser.status === UserStatus.NotActive) {
      setUserToInvite(selectedUser);
    }
  }, []);

  const filterOptions = useCallback(
    (users: User[]) =>
      users.filter(
        (user) => user.status !== UserStatus.Deactivated && user.status !== UserStatus.Active,
      ),
    [],
  );

  const getOptionDisabled = useCallback((user: User) => user.status !== UserStatus.NotActive, []);

  // reset state on invitee change
  useEffect(() => {
    setSent(false);
    setSnackbarOpen(false);
    setUserToInvite(null);
  }, [setUserToInvite]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Invite User to Reef</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ maxWidth: '50%' }}>
            <UserAutocomplete
              dataUid="settings-user-invite-autocomplete"
              value={userToInvite}
              handleOnUserSelect={handleOnUserSelect}
              filterOptions={filterOptions}
              getOptionDisabled={getOptionDisabled}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-uid={sprint$.inviteUserDialog.cancelBtn}
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-uid={sprint$.inviteUserDialog.inviteBtn}
            disabled={disableInvite}
            variant="contained"
            color="primary"
            endIcon={sent ? <CircularProgress size={24} /> : undefined}
            onClick={handleUserInvite}
          >
            {sent ? 'Inviting' : 'Invite'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        data-uid={sprint$.inviteUserDialog.success}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        message={`Invite sent to ${userToInvite?.name}!`}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};
