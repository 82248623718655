import { ApolloError, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useMemo } from 'react';

import { useUserSettingsContext } from '../contexts/userSettingsContext';
import {
  Account as APIAccount,
  Contract as APIContract,
  GetCustomerContractsPageDocument,
  ServicePeriod as APIServicePeriod,
} from '../graphql/generated';
import { useDemoAugmenters } from '../graphql/hooks';
import { AccountContractDetails, ServicePeriodDetails } from '../models/account';
import { ContractDetails } from '../models/contract';
import { getModelMoneyFields } from '../models/currency';
import { Result, ResultType, useResultFromQuery } from '../models/result';
import { asUUID, UUID } from '../models/uuid';
import { toLoggableErrorMessages, transformDate } from '../utils';

const transformServicePeriod = (
  sp: APIServicePeriod,
  currencyCode: string,
): ServicePeriodDetails => {
  const servicePeriodMoneyFields = getModelMoneyFields(
    {
      contractedDollarsConversions: sp.contractedDollarsConversions,
      projectedUsageDollarsConversions: sp.projectedUsageDollarsConversions,
      projectedOverageDollarsConversions: sp.projectedOverageDollarsConversions,
    },
    [
      'contractedDollarsConversions',
      'projectedUsageDollarsConversions',
      'projectedOverageDollarsConversions',
    ],
  );
  return {
    contractedDollars: get(
      servicePeriodMoneyFields.contractedDollarsConversions,
      currencyCode,
      null,
    ),
    contractedUnits: sp.contractedUnits,
    currentlyUsedUnits: sp.currentlyUsedUnits,
    id: sp.id,
    isFinalServicePeriod: !!sp.isFinalServicePeriod,
    parentId: asUUID(sp.parentId),
    productId: asUUID(sp.productId),
    productName: sp.productName,
    projectedOverageDollars: get(
      servicePeriodMoneyFields.projectedOverageDollarsConversions,
      currencyCode,
      null,
    ),
    projectedUnits: sp.projectedUnits,
    projectedUsageDollars: get(
      servicePeriodMoneyFields.projectedUsageDollarsConversions,
      currencyCode,
      null,
    ),
    serviceEndDate: transformDate(sp.endDate),
    serviceStartDate: transformDate(sp.startDate),
  };
};
/**
 * Transforms given nullable APIServicePeriod[], using supplied currencyCode, into ServicePeriodDetails[]
 * @param servicePeriods APIServicePeriod[] to transform
 * @param currencyCode string currency code to extract conversions during transform
 * @returns ServicePeriodDetails[] transformed
 */
export function transformServicePeriods(
  servicePeriods: APIServicePeriod[] | null,
  currencyCode: string,
): ServicePeriodDetails[] {
  return servicePeriods != null
    ? servicePeriods.map((sp) => transformServicePeriod(sp, currencyCode))
    : [];
}
const transformContract = (c: APIContract, currencyCode: string): ContractDetails => {
  const contractMoneyFields = getModelMoneyFields(
    {
      currentArrConversions: c.currentArrConversions,
      expiringArrConversions: c.expiringArrConversions,
      valueTotalConversions: c.valueTotalConversions,
    },
    ['currentArrConversions', 'expiringArrConversions', 'valueTotalConversions'],
  );
  return {
    contractEndDate: transformDate(c.endDate),
    contractEndFiscalQuarter: c.endFiscalQuarter,
    contractEndFiscalYear: `${c.endFiscalYear}`,
    contractStartDate: transformDate(c.startDate),
    contractValueTotal: get(contractMoneyFields.valueTotalConversions, currencyCode, null),
    currentArr: get(contractMoneyFields.currentArrConversions, currencyCode, null),
    expiringArr: get(contractMoneyFields.expiringArrConversions, currencyCode, null),
    id: c.id,
    isActive: c.isActive,
    lastModifiedAt: transformDate(c.lastModifiedAt),
    servicePeriods: transformServicePeriods(c.servicePeriods, currencyCode),
    usageRating: 'usageRating' in c ? c.usageRating : null,
    name: c.name,
  };
};
/**
 * Transforms given nullable APIContract[], using supplied currencyCode, into ContractDetails[]
 * @param contracts APIContract[] to transform
 * @param currencyCode string currency code to extract conversions during transform
 * @returns ContractDetails[] transformed
 */
export function transformContracts(
  contracts: APIContract[] | null,
  currencyCode: string,
): ContractDetails[] | null {
  return contracts != null ? contracts.map((c) => transformContract(c, currencyCode)) : null;
}

export type AccountContractsFields = Pick<APIAccount, 'id' | 'name' | 'lastUpdated' | 'contracts'>;
/**
 * Builds Account contracts from query.
 * @param fields query fields
 * @param currencyCode current user's currency display
 * @returns AccountContractDetails
 */
function getAccountContractDetailsFromAPI(fields: AccountContractsFields, currencyCode: string) {
  return {
    id: fields.id,
    name: fields.name,
    reefUpdatedOn: transformDate(fields.lastUpdated),
    contracts: transformContracts(fields.contracts, currencyCode),
  } satisfies AccountContractDetails;
}

export const useCustomerContracts = (
  accountId?: UUID,
): Result<AccountContractDetails, ApolloError> => {
  const { currencyCode } = useUserSettingsContext();
  const variables = accountId != null ? { id: accountId } : undefined;
  const apiContractsResult = useResultFromQuery(
    useQuery(GetCustomerContractsPageDocument, { variables, skip: accountId == null }),
  );

  const { augmentAPIContractFieldsWithDemoData, shouldAugmentData } = useDemoAugmenters();

  return useMemo((): Result<AccountContractDetails, ApolloError> => {
    if (accountId == null) {
      return { state: ResultType.NoValue, value: undefined };
    }

    if (apiContractsResult.state === ResultType.Value) {
      if (apiContractsResult.value.node.__typename === 'Account') {
        const contractsResult = shouldAugmentData
          ? augmentAPIContractFieldsWithDemoData(apiContractsResult.value.node)
          : apiContractsResult.value.node;
        return {
          ...apiContractsResult,
          value: getAccountContractDetailsFromAPI(contractsResult, currencyCode),
        };
      }

      return { state: ResultType.NoValue, value: undefined };
    }

    if (apiContractsResult.state === ResultType.Error) {
      console.warn(
        ...toLoggableErrorMessages(
          'useCustomerContracts failed to fetch',
          apiContractsResult.value,
        ),
      );
    }

    return apiContractsResult;
  }, [
    accountId,
    apiContractsResult,
    augmentAPIContractFieldsWithDemoData,
    currencyCode,
    shouldAugmentData,
  ]);
};
