import {
  DataGridPremiumProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColTypeDef,
  GridComparatorFn,
  GridFilterInitialState,
  GridGroupingValueGetterParams,
  GridInitialState,
  GridRenderCellParams,
  GridRowParams,
  GridSortingInitialState,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import {
  CRMLinkCell,
  CustomerCell,
  CustomScoreCell,
  DateCell,
  LegacyScoreCell,
  MetricCell,
  MultiCurrencyCell,
  NumberCell,
  PercentCell,
  RowActionsCell,
  StringCell,
} from '../components/Tables/CustomGrid';
import { ListOfCustomersProps } from '../components/Tables/ListOfCustomersTable';
import { CustomersGridRowModel } from '../components/Tables/ListOfCustomersTable/types';
import { DEFAULT_CUSTOMERS_GRID_FILTERING, DEFAULT_CUSTOMERS_GRID_SORTING } from '../constants';
import { Account, CustomNumericalMetric, Metric, Score } from '../models/account';
import { UUID } from '../models/uuid';
import { useReefFlags } from './flags';
import { useCommonGridHelpers } from './useCommonGridHelpers';
export interface CustomerGridColDef extends GridColTypeDef {
  field: keyof CustomersGridRowModel | typeof GRID_CHECKBOX_SELECTION_FIELD;
}

const getLegacyScoreValue = <T extends Score>(
  params: GridValueGetterParams<CustomersGridRowModel, T>,
): number | undefined => (params.value != null ? params.value.value : undefined);

const getMetricCategory = <T extends Metric>(
  params: GridValueGetterParams<CustomersGridRowModel, T>,
): string | undefined => (params.value != null ? (params.value.category ?? undefined) : undefined);

const getGroupingMetricCategory = <T extends Metric>(
  params: GridGroupingValueGetterParams<CustomersGridRowModel, T>,
) => (params.value != null ? (params.value.category ?? undefined) : undefined);

const renderMetricCell = (params: GridRenderCellParams<CustomersGridRowModel, string>) => (
  <MetricCell {...params} />
);

const renderMultiCurrencyCell = (params: GridRenderCellParams<CustomersGridRowModel, number>) => (
  <MultiCurrencyCell {...params} />
);
const renderDateCell = (params: GridRenderCellParams<CustomersGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderLegacyScoreCell = (params: GridRenderCellParams<CustomersGridRowModel, number>) => (
  <LegacyScoreCell {...params} />
);
const renderScoreCell = (
  params: GridRenderCellParams<CustomersGridRowModel, CustomNumericalMetric>,
) => <CustomScoreCell {...params} />;
const renderNumberCell = (params: GridRenderCellParams<CustomersGridRowModel, number>) => (
  <NumberCell {...params} />
);
const renderPercentCell = (params: GridRenderCellParams<CustomersGridRowModel, number>) => (
  <PercentCell {...params} />
);
const renderStringCell = (params: GridRenderCellParams<CustomersGridRowModel, string>) => (
  <StringCell {...params} />
);
const renderLinkCell = (params: GridRenderCellParams<CustomersGridRowModel, string>) => (
  <CRMLinkCell onHover="hide-icon" {...params} />
);

type UseCustomersGridProps = Required<
  Pick<
    ListOfCustomersProps,
    | 'accounts'
    | 'showProductDetailsLink'
    | 'customerMenuHidden'
    | 'rowSelectionModel'
    | 'setRowSelectionModel'
    | 'disableSelection'
  >
> &
  Pick<
    ListOfCustomersProps,
    'onRemoveCustomer' | 'onMouseEnterAccount' | 'onMouseLeaveAccount' | 'disabledAccountIds'
  > & {
    initialFilter?: GridFilterInitialState;
    setInitialFilter?: React.Dispatch<React.SetStateAction<GridFilterInitialState>>;
    initialSorting?: GridSortingInitialState;
    setInitialSorting?: React.Dispatch<React.SetStateAction<GridSortingInitialState>>;
    crmLinkHidden?: boolean;
    disableFiltering?: boolean;
  };
type CustomersGridProps = Pick<
  DataGridPremiumProps<Account>,
  | 'autoHeight'
  | 'apiRef'
  | 'checkboxSelection'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'rowSelectionModel'
  | 'isRowSelectable'
  | 'onRowSelectionModelChange'
  | 'hideFooter'
  | 'localeText'
  | 'sx'
  | 'onSortModelChange'
  | 'onFilterModelChange'
  | 'sortModel'
  | 'filterModel'
  | 'keepNonExistentRowsSelected'
  | 'disableColumnFilter'
>;
export const useCustomersGrid = ({
  initialFilter = DEFAULT_CUSTOMERS_GRID_FILTERING,
  setInitialFilter = noop,
  initialSorting = DEFAULT_CUSTOMERS_GRID_SORTING,
  setInitialSorting = noop,
  accounts,
  customerMenuHidden,
  crmLinkHidden = true,
  disableFiltering = false,
  onRemoveCustomer,
  showProductDetailsLink,
  rowSelectionModel,
  setRowSelectionModel,
  disabledAccountIds,
  disableSelection,
  onMouseEnterAccount,
  onMouseLeaveAccount,
}: UseCustomersGridProps): CustomersGridProps => {
  const {
    hideAdvancedUsersDrilldownField,
    hideUniqueUsersDrilldownField,
    hideOverageDollarsDrilldownField,
    hideOverageProductsDrilldownField,
  } = useReefFlags();
  const renderCustomerCell = useCallback(
    (params: GridRenderCellParams<CustomersGridRowModel, string>) => (
      <CustomerCell {...{ ...params, showProductDetailsLink }} />
    ),
    [showProductDetailsLink],
  );

  const renderRowActionsCell = useCallback(
    (params: GridRenderCellParams<CustomersGridRowModel, UUID>) => (
      <RowActionsCell {...{ ...params, onRemoveCustomer }} />
    ),
    [onRemoveCustomer],
  );

  const hideableColMap = useMemo(
    (): { [K in keyof Account]?: boolean } => ({
      advancedProductUsers: hideAdvancedUsersDrilldownField,
      uniqueProductUsers: hideUniqueUsersDrilldownField,
      totalOverageDollars: hideOverageDollarsDrilldownField,
      totalOverageProducts: hideOverageProductsDrilldownField,
      implementedOn: true,
      hasBeenImplemented: true,
      daysSinceImplemented: true,
      daysUntilRenewal: true,
      onboardedOn: true,
      hasBeenOnboarded: true,
      daysSinceOnboarded: true,
      crmLink: crmLinkHidden,
      id: customerMenuHidden,
      sixMoWeightedPipeline: true, // always hidden per REEF-1118
      region: true,
    }),
    [
      hideAdvancedUsersDrilldownField,
      hideUniqueUsersDrilldownField,
      hideOverageDollarsDrilldownField,
      hideOverageProductsDrilldownField,
      crmLinkHidden,
      customerMenuHidden,
    ],
  );

  const { customNumberComparator, ...commonProps } = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'Customer',
  });

  const customScoreComparator: GridComparatorFn<CustomNumericalMetric | null> = useCallback(
    (score1, score2, cell1, cell2) =>
      customNumberComparator(
        score1?.value ?? Number.MIN_SAFE_INTEGER,
        score2?.value ?? Number.MIN_SAFE_INTEGER,
        { ...cell1, value: cell1.value?.value ?? Number.MIN_SAFE_INTEGER },
        { ...cell2, value: cell2.value?.value ?? Number.MIN_SAFE_INTEGER },
      ),
    [customNumberComparator],
  );

  // effect to subscribe to mouseenter for a row
  useEffect(() => {
    if (onMouseEnterAccount != null) {
      const unmountMouseEnter = commonProps.apiRef?.current?.subscribeEvent?.(
        'rowMouseEnter',
        (params) => onMouseEnterAccount(params.row),
      );
      return unmountMouseEnter;
    }
    return undefined;
  }, [commonProps.apiRef, onMouseEnterAccount, onMouseLeaveAccount]);

  // effect to subscribe to mouseleave for a row
  useEffect(() => {
    if (onMouseLeaveAccount != null) {
      const unmountMouseLeave = commonProps.apiRef?.current?.subscribeEvent?.('rowMouseLeave', () =>
        onMouseLeaveAccount(),
      );
      return unmountMouseLeave;
    }
    return undefined;
  }, [commonProps.apiRef, onMouseLeaveAccount]);

  const columns = useMemo((): CustomerGridColDef[] => {
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        field: GRID_CHECKBOX_SELECTION_FIELD,
        hideable: false,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'name',
        headerName: 'Customer',
        groupable: false,
        aggregable: false,
        minWidth: 150,
        type: 'string',
        renderCell: renderCustomerCell,
        filterable: false,
      },
      {
        field: 'crmLink',
        headerName: 'CRM Link',
        type: 'string',
        renderCell: renderLinkCell,
        minWidth: 125,
        disableReorder: true,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'ownerName',
        headerName: 'Customer Lead',
        minWidth: 125,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'secondaryOwnerName',
        headerName: 'Sales Lead',
        minWidth: 135,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'renewalOwnerName',
        headerName: 'Renewal Lead',
        minWidth: 135,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'currentArr',
        headerName: 'ARR',
        minWidth: 100,
        type: 'number',
        renderCell: renderMultiCurrencyCell,
        description: 'Reef defines ARR as all reoccurring revenue over a fiscal year period.',
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'rawPipeline',
        headerName: 'Pipeline',
        minWidth: 100,
        type: 'number',
        renderCell: renderMultiCurrencyCell,
        description: 'Reef defines Pipeline as the sum of all open upsell opportunities.',
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'sixMoWeightedPipeline',
        headerName: 'Pipeline (Weighted)',
        minWidth: 150,
        type: 'number',
        renderCell: renderMultiCurrencyCell,
        description:
          'Reef defines Pipeline as upsell opportunities that are scheduled for the next 6 months, weighted by stage.',
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
        filterable: false,
      },
      {
        field: 'customerRevenue',
        headerName: 'Revenue',
        minWidth: 150,
        type: 'number',
        renderCell: renderMultiCurrencyCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'customerRevenueBand',
        headerName: 'Revenue Band',
        minWidth: 150,
        type: 'string',
        valueGetter: getMetricCategory,
        renderCell: renderMetricCell,
        groupingValueGetter: getGroupingMetricCategory,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'renewalDate',
        headerName: 'Renewal Date',
        minWidth: 125,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'daysUntilRenewal',
        headerName: 'Days Until Renewal',
        minWidth: 175,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'implementedOn',
        headerName: 'Implemented On',
        minWidth: 125,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'hasBeenImplemented',
        headerName: 'Has Been Implemented?',
        minWidth: 175,
        type: 'boolean',
        groupable: true,
        aggregable: false,
      },
      {
        field: 'daysSinceImplemented',
        headerName: 'Days Since Implemented',
        minWidth: 125,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'onboardedOn',
        headerName: 'Onboarded On',
        minWidth: 125,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'hasBeenOnboarded',
        headerName: 'Has Been Onboarded?',
        minWidth: 175,
        type: 'boolean',
        groupable: true,
        aggregable: false,
      },
      {
        field: 'daysSinceOnboarded',
        headerName: 'Days Since Onboarded',
        minWidth: 125,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'industry',
        headerName: 'Industry',
        minWidth: 150,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'region',
        headerName: 'Region',
        minWidth: 150,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'potential',
        headerName: 'Potential',
        minWidth: 150,
        type: 'number',
        valueGetter: getLegacyScoreValue,
        renderCell: renderLegacyScoreCell,
        sortComparator: customNumberComparator,
        description: `Potential Score reflects Reef's opinion on the total possible annual revenue,
                      both realized and unrealized. This is calculated by making revenue projections 
                      based off of similarly segmented customers.`,
        groupable: false,
        aggregable: true,
        filterable: false,
      },
      {
        field: 'engagement',
        headerName: 'Engagement',
        minWidth: 150,
        type: 'number',
        valueGetter: getLegacyScoreValue,
        renderCell: renderLegacyScoreCell,
        sortComparator: customNumberComparator,
        description: `Reef's Engagement Score is calculated using data from
                      your interactions with your customers and your customers interactions with you
                      and your product.`,
        groupable: false,
        aggregable: true,
        filterable: false,
      },
      {
        field: 'customHealthscore',
        headerName: 'Healthscore',
        minWidth: 150,
        valueGetter: getMetricCategory,
        renderCell: renderMetricCell,
        groupingValueGetter: getGroupingMetricCategory,
        headerAlign: 'right',
        align: 'right',
        type: 'string',
        groupable: true,
        aggregable: false,
      },
      {
        field: 'risk',
        headerName: 'Risk Score',
        minWidth: 150,
        type: 'number',
        renderCell: renderScoreCell,
        sortComparator: customScoreComparator,
        description: `Risk Score reflects Reef's model opinion on the possibility of a full customer
                      churn.`,
        groupable: false,
        aggregable: false,
        filterable: false,
      },
      {
        field: 'growth',
        headerName: 'Growth Score',
        minWidth: 150,
        type: 'number',
        renderCell: renderScoreCell,
        sortComparator: customScoreComparator,
        description: `Growth Score reflects Reef's model opinion on the possibility of customer growth.`,
        groupable: false,
        aggregable: false,
        filterable: false,
      },
      {
        field: 'productEngagement',
        headerName: 'Product Score',
        minWidth: 150,
        type: 'number',
        renderCell: renderLegacyScoreCell,
        valueGetter: getLegacyScoreValue,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'productConsumptionPercent',
        headerName: 'Consumption %',
        minWidth: 125,
        type: 'number',
        renderCell: renderPercentCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'uniqueProductUsers',
        headerName: 'Weekly Active Users',
        minWidth: 150,
        type: 'number',
        renderCell: renderNumberCell,
        valueGetter: getLegacyScoreValue,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'advancedProductUsers',
        headerName: 'Frequent Users',
        minWidth: 125,
        type: 'number',
        renderCell: renderNumberCell,
        valueGetter: getLegacyScoreValue,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'totalOverageDollars',
        headerName: 'Expected Overage Amount',
        minWidth: 200,
        type: 'number',
        renderCell: renderMultiCurrencyCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'totalOverageProducts',
        headerName: 'Product Lines w/ Overage',
        minWidth: 200,
        type: 'number',
        renderCell: renderNumberCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'supportCaseCount',
        headerName: 'Support Case Count',
        minWidth: 150,
        type: 'number',
        renderCell: renderNumberCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      // FIXME: drop Actions call if no action
      {
        field: 'id',
        type: 'actions',
        renderCell: renderRowActionsCell,
        disableReorder: true,
        groupable: false,
        aggregable: false,
      },
    ];
  }, [customScoreComparator, customNumberComparator, renderCustomerCell, renderRowActionsCell]);

  const handleSelectionModelChange = useCallback<
    Required<DataGridPremiumProps>['onRowSelectionModelChange']
  >((model) => setRowSelectionModel(model as UUID[]), [setRowSelectionModel]);

  const initialState: GridInitialState = useMemo(
    () => ({
      pinnedColumns: disableSelection ? { left: ['name'] } : { left: ['__check__', 'name'] },
      sorting: initialSorting,
      filter: initialFilter,
    }),
    [disableSelection, initialFilter, initialSorting],
  );

  // only resize for 10 or fewer items for draft sprints; larger lists will likely need virtualization
  const autoHeight: boolean = useMemo(
    () => disableSelection && accounts.length <= 10,
    [accounts.length, disableSelection],
  );

  const sx = useMemo(() => ({ borderRadius: 'unset' }), []);

  const handleFilterModelChange = useCallback<
    Required<DataGridPremiumProps>['onFilterModelChange']
  >(
    (filterModel) => {
      setInitialFilter({
        filterModel,
      });
    },
    [setInitialFilter],
  );
  const handleSortModelChange = useCallback<Required<DataGridPremiumProps>['onSortModelChange']>(
    (sortModel) => {
      setInitialSorting({
        sortModel,
      });
    },
    [setInitialSorting],
  );

  /**
   * Construct optional persistence props, since some usages of this hook aren't on
   * the List pages.
   */
  const maybePersistedProps = useMemo((): Pick<
    DataGridPremiumProps,
    'onFilterModelChange' | 'onSortModelChange' | 'sortModel' | 'filterModel'
  > => {
    const hasPersistedFilters = setInitialFilter !== noop;
    const hasPersistedSorting = setInitialSorting !== noop;
    const props = {};
    if (hasPersistedFilters) {
      Object.assign(props, {
        onFilterModelChange: handleFilterModelChange,
        filterModel: initialFilter.filterModel,
      });
    }
    if (hasPersistedSorting) {
      Object.assign(props, {
        onSortModelChange: handleSortModelChange,
        sortModel: initialSorting.sortModel,
      });
    }
    return props;
  }, [
    handleFilterModelChange,
    handleSortModelChange,
    initialFilter.filterModel,
    initialSorting.sortModel,
    setInitialFilter,
    setInitialSorting,
  ]);

  const checkboxSelection = useMemo(() => !disableSelection, [disableSelection]);

  return {
    ...commonProps,
    autoHeight,
    checkboxSelection,
    initialState,
    columns,
    rowSelectionModel,
    isRowSelectable: useMemo(
      () =>
        disabledAccountIds != null
          ? (params: GridRowParams<Account>) => !disabledAccountIds.includes(params.row.id)
          : undefined,
      [disabledAccountIds],
    ),
    onRowSelectionModelChange: handleSelectionModelChange,
    hideFooter: disableSelection,
    sx,
    ...maybePersistedProps,
    keepNonExistentRowsSelected: checkboxSelection, // retain for list selection persistence
    disableColumnFilter: disableFiltering,
  };
};
