import { Box, Stack } from '@mui/material';
import React, { useMemo } from 'react';

import { TITLE_CHAR_LIMIT } from '../../../constants';
import { useGetClientSprintsList } from '../../../hooks/client';
import { useSprintsGrid } from '../../../hooks/useSprintsGrid';
import { ResultType } from '../../../models/result';
import { ClampedText } from '../../ClampedText';
import { CustomGrid } from '../../Tables/CustomGrid';
export const SprintDashboard = () => {
  const clientSprintsResult = useGetClientSprintsList();
  const loading = useMemo(
    () => clientSprintsResult.state === ResultType.Loading,
    [clientSprintsResult.state],
  );
  const sprints = useMemo(
    () => (clientSprintsResult.state === ResultType.Value ? clientSprintsResult.value : []),
    [clientSprintsResult],
  );
  const sprintsGridProps = useSprintsGrid({ sprints });
  return (
    <Box sx={{ mx: 2 }}>
      <Stack direction="row" spacing={2} sx={{ mt: 2, mb: 5 }}>
        <ClampedText
          text="Sprint Dashboard"
          limit={TITLE_CHAR_LIMIT}
          TypographyProps={{ variant: 'h3' }}
        />
      </Stack>
      <CustomGrid
        GridWrapperBoxProps={{
          outer: {
            sx: {
              height: 'calc(100vh - 8rem)',
            },
          },
        }}
        loading={loading}
        {...sprintsGridProps}
      />
    </Box>
  );
};
