import { Alert, Box } from '@mui/material';
import React from 'react';

interface PDFNoDataProps {
  assetName?: string;
}
export const PDFNoData = ({ assetName = 'PDF' }: PDFNoDataProps) => (
  <Box
    sx={{
      height: 'calc(100vh - 8rem)',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
      justifyContent: 'center',
    }}
  >
    <Alert severity="info">No {assetName} file specified.</Alert>
  </Box>
);
