import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { ReactNode } from 'react';

interface NavListItemProps {
  ['data-uid']?: string;
  /**
   * The minimum width the icon in the list can be.
   * Useful for setting the icon as the width of the collapsed nav.
   */
  iconWidth: number;
  /**
   * The icon in the list.
   */
  icon: ReactNode;
  /**
   * The text to show in the expanded nav.
   */
  name: React.ReactNode;
  /**
   * If this item is the active selected item.
   */
  active: boolean;
  /**
   * Called when the user clicks on the nav item.
   */
  onClick(): void;
}

export const NavListItem = ({
  icon,
  iconWidth,
  name,
  active,
  onClick,
  ...rest
}: NavListItemProps) => (
  <ListItem disablePadding>
    <ListItemButton
      data-uid={rest['data-uid']}
      disableGutters
      selected={active}
      onClick={onClick}
      sx={{
        '&.Mui-selected': {
          backgroundColor: (theme) => theme.palette.primary.s30p,
        },
      }}
    >
      <ListItemIcon sx={{ minWidth: iconWidth, justifyContent: 'center' }}>{icon}</ListItemIcon>
      <ListItemText primary={name} sx={{ mr: 2, color: (theme) => theme.palette.primary.main }} />
    </ListItemButton>
  </ListItem>
);
