import {
  DateWithinNDaysFilterFieldsFragment,
  NumberGreaterThanFilterFieldsFragment,
  NumberLessThanFilterFieldsFragment,
  ProductAttributeAtLeastFilterFieldsFragment,
  ProductAttributeIsNullFilterFieldsFragment,
  StringContainsFilterFieldsFragment,
  StringInSetFilterFieldsFragment,
  UserRelation,
  UserRelationshipFilterFieldsFragment,
} from '../graphql/generated';
import { bottom } from '../utils';
import { UUID } from './uuid';

export enum AccountFilterType {
  AccountNameContains = 'account-name-string-contains',
  IndustryContains = 'industry-string-contains',
  NotHasProduct = 'not-has-product',
  ProductOverageAtLeast = 'product-overage-at-least',
  PrimaryOwner = 'primary-owner',
  SecondaryOwner = 'secondary-owner',
  RenewalOwner = 'renewal-owner',
  DaysUntilRenewal = 'days-until-renewal',
  RenewalFiscalQuarter = 'renewal-fiscal-quarter',
  PipelineAtLeast = 'pipeline-at-least',
  PipelineAtMost = 'pipeline-at-most',
  ArrAtLeast = 'arr-at-least',
  ArrAtMost = 'arr-at-most',
  RegionContains = 'region-string-contains',
}

export interface AccountNameFilter {
  type: AccountFilterType.AccountNameContains;
  id: UUID;
  value: string[];
}

export interface IndustryFilter {
  type: AccountFilterType.IndustryContains;
  id: UUID;
  value: string[];
}

export interface NotHasProductFilter {
  type: AccountFilterType.NotHasProduct;
  id: UUID;
  productName: string;
}

export interface ProductOverageAtLeastFilter {
  type: AccountFilterType.ProductOverageAtLeast;
  id: UUID;
  productName: string;
  value: number;
}

export interface PrimaryOwnerFilter {
  type: AccountFilterType.PrimaryOwner;
  id: UUID;
  owners: string[];
}

export interface SecondaryOwnerFilter {
  type: AccountFilterType.SecondaryOwner;
  id: UUID;
  owners: string[];
}

export interface RenewalOwnerFilter {
  type: AccountFilterType.RenewalOwner;
  id: UUID;
  owners: string[];
}

export interface DaysUntilRenewalFilter {
  type: AccountFilterType.DaysUntilRenewal;
  id: UUID;
  days: number;
}

export interface RenewalFiscalQuarterFilter {
  type: AccountFilterType.RenewalFiscalQuarter;
  id: UUID;
  year: number;
  quarter: 1 | 2 | 3 | 4 | undefined;
}

export interface PipelineAtLeastFilter {
  type: AccountFilterType.PipelineAtLeast;
  id: UUID;
  value: number;
}

export interface PipelineAtMostFilter {
  type: AccountFilterType.PipelineAtMost;
  id: UUID;
  value: number;
}

export interface ArrAtLeastFilter {
  type: AccountFilterType.ArrAtLeast;
  id: UUID;
  value: number;
}

export interface ArrAtMostFilter {
  type: AccountFilterType.ArrAtMost;
  id: UUID;
  value: number;
}

export interface RegionFilter {
  type: AccountFilterType.RegionContains;
  id: UUID;
  value: string[];
}

export type Filter =
  | AccountNameFilter
  | IndustryFilter
  | NotHasProductFilter
  | ProductOverageAtLeastFilter
  | PrimaryOwnerFilter
  | SecondaryOwnerFilter
  | RenewalOwnerFilter
  | DaysUntilRenewalFilter
  | RenewalFiscalQuarterFilter
  | PipelineAtLeastFilter
  | PipelineAtMostFilter
  | ArrAtLeastFilter
  | ArrAtMostFilter
  | RegionFilter;

/**
 * Function to convert a quarter string into the quarter number.
 * @param quarter string in a `Q{1 | 2 | 3 | 4}` format
 * @returns quarter or undefined
 */
function getFiscalQuarter(quarter: string | undefined): 1 | 2 | 3 | 4 | undefined {
  switch (quarter) {
    case 'Q1':
      return 1;
    case 'Q2':
      return 2;
    case 'Q3':
      return 3;
    case 'Q4':
      return 4;
    default:
      return undefined;
  }
}

/**
 * Get the fiscal quarter options from a fiscal quarter search string.
 * @param fiscalQuarter the full fiscal quarter search string
 * @returns quarter filter options
 */
function getQuarterFromQuarterString(fiscalQuarter: string): {
  year: number;
  quarter: 1 | 2 | 3 | 4 | undefined;
} {
  const options = fiscalQuarter.split(' ');
  const year = Number(options.at(0));
  const quarter = getFiscalQuarter(options.at(1));
  return { year, quarter };
}

type filterOperation =
  | ((
      | StringContainsFilterFieldsFragment
      | StringInSetFilterFieldsFragment
      | ProductAttributeIsNullFilterFieldsFragment
      | ProductAttributeAtLeastFilterFieldsFragment
      | UserRelationshipFilterFieldsFragment
      | DateWithinNDaysFilterFieldsFragment
      | NumberGreaterThanFilterFieldsFragment
      | NumberLessThanFilterFieldsFragment
    ) & { id: UUID })
  // we aren't querying for filter junction here and don't get an id - but the
  // resolver can _technically_ resolve a filter junction, so it needs to be handled
  // but lets just error in that case
  | { __typename?: 'FilterJunction' | undefined };

/**
 * Turn an api filter into a ui filter.
 * @param filter api filter model
 * @returns ui filter model
 */
export function toFilter<F extends filterOperation>(filter: F): Filter {
  switch (filter.__typename) {
    case 'StringContainsFilterOperation':
      switch (filter.attribute) {
        case 'rawData.industry':
          return {
            type: AccountFilterType.IndustryContains,
            id: filter.id,
            value: [filter.stringValue],
          } satisfies IndustryFilter;
        case 'name':
          return {
            type: AccountFilterType.AccountNameContains,
            id: filter.id,
            value: [filter.stringValue],
          } satisfies AccountNameFilter;
        case 'reefData.renewalFiscalQuarter':
          return {
            type: AccountFilterType.RenewalFiscalQuarter,
            id: filter.id,
            ...getQuarterFromQuarterString(filter.stringValue),
          } satisfies RenewalFiscalQuarterFilter;
        default:
          throw new Error(`unsupported string filter type ${filter}`);
      }
    case 'StringInSetFilterOperation':
      switch (filter.attribute) {
        case 'rawData.industry':
          return {
            type: AccountFilterType.IndustryContains,
            id: filter.id,
            value: filter.stringListValue,
          } satisfies IndustryFilter;
        case 'name':
          return {
            type: AccountFilterType.AccountNameContains,
            id: filter.id,
            value: filter.stringListValue,
          } satisfies AccountNameFilter;
        case 'roles.primaryOwner.name':
          return {
            type: AccountFilterType.PrimaryOwner,
            id: filter.id,
            owners: filter.stringListValue,
          } satisfies PrimaryOwnerFilter;
        case 'roles.secondaryOwner.name':
          return {
            type: AccountFilterType.SecondaryOwner,
            id: filter.id,
            owners: filter.stringListValue,
          } satisfies SecondaryOwnerFilter;
        case 'roles.renewalOwner.name':
          return {
            type: AccountFilterType.RenewalOwner,
            id: filter.id,
            owners: filter.stringListValue,
          } satisfies RenewalOwnerFilter;
        case 'rawData.region':
          return {
            type: AccountFilterType.RegionContains,
            id: filter.id,
            value: filter.stringListValue,
          } satisfies RegionFilter;
        default:
          throw new Error(`unsupported string filter type ${filter}`);
      }
    case 'ProductAttributeIsNullFilterOperation':
      // TODO: ensure dynamo products are retained for this filter
      if (filter.attribute === 'contract.unitsContracted') {
        return {
          type: AccountFilterType.NotHasProduct,
          id: filter.id,
          productName: filter.productName,
        } satisfies NotHasProductFilter;
      }
      throw new Error(`unsupported null product attr filter ${filter}`);
    case 'ProductAttributeAtLeastFilterOperation':
      // TODO: ensure dynamo products are retained for this filter
      if (filter.attribute === 'consumption.dollarsOverageTotal') {
        return {
          type: AccountFilterType.ProductOverageAtLeast,
          id: filter.id,
          productName: filter.productName,
          value: filter.productValue,
        } satisfies ProductOverageAtLeastFilter;
      }
      throw new Error(`unsupported at least product attr filter ${filter}`);
    case 'UserRelationshipFilterOperation':
      switch (filter.relationType) {
        case UserRelation.Primary:
          return {
            type: AccountFilterType.PrimaryOwner,
            id: filter.id,
            owners: [filter.userName],
          } satisfies PrimaryOwnerFilter;
        case UserRelation.Secondary:
          return {
            type: AccountFilterType.SecondaryOwner,
            id: filter.id,
            owners: [filter.userName],
          } satisfies SecondaryOwnerFilter;
        case UserRelation.Renewal:
          return {
            type: AccountFilterType.RenewalOwner,
            id: filter.id,
            owners: [filter.userName],
          } satisfies RenewalOwnerFilter;
        default:
          throw new Error(`unsupported ownership filter type ${filter}`);
      }
    case 'DateWithinNDaysFilterOperation':
      switch (filter.attribute) {
        case 'rawData.renewalDate':
          return {
            type: AccountFilterType.DaysUntilRenewal,
            id: filter.id,
            days: filter.days,
          } satisfies DaysUntilRenewalFilter;
        default:
          throw new Error(`unsupported upcoming renewal filter ${filter}`);
      }
    case 'NumberGreaterThanEqualFilterOperation':
      switch (filter.attribute) {
        case 'rawData.rawPipeline':
          return {
            type: AccountFilterType.PipelineAtLeast,
            id: filter.id,
            value: filter.lowerValue,
          } satisfies PipelineAtLeastFilter;
        case 'reefData.currentArr':
          return {
            type: AccountFilterType.ArrAtLeast,
            id: filter.id,
            value: filter.lowerValue,
          } satisfies ArrAtLeastFilter;
        default:
          throw new Error(`unsupported at least filter ${filter}`);
      }
    case 'NumberLessThanEqualFilterOperation':
      switch (filter.attribute) {
        case 'rawData.rawPipeline':
          return {
            type: AccountFilterType.PipelineAtMost,
            id: filter.id,
            value: filter.upperValue,
          } satisfies PipelineAtMostFilter;
        case 'reefData.currentArr':
          return {
            type: AccountFilterType.ArrAtMost,
            id: filter.id,
            value: filter.upperValue,
          } satisfies ArrAtMostFilter;
        default:
          throw new Error(`unsupported at most filter ${filter}`);
      }
    case 'FilterJunction':
      throw new Error('filter sub-junctions not implemented');
    case undefined:
      throw new Error(`got undefined filter type ${filter}`);
    default:
      bottom(filter);
  }
}
