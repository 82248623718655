import { LinearProgress } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { AuthStatus, useReefAuthContext } from '../../../contexts/reefAuthContext';

export const AuthRequiredOutlet = () => {
  const [ctx] = useReefAuthContext();

  if (ctx.status === AuthStatus.Loading) {
    // TODO: add a fancier "building your reef" loading state
    return <LinearProgress />;
  }

  if (ctx.status === AuthStatus.SignedOut) {
    // TODO: add a fancier you aren't logged in state
    return <React.Fragment />;
  }

  return <Outlet />;
};
