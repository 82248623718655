import { HelpOutline } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import React from 'react';

import { cells$ } from '../../selectors';
import { bottom } from '../../utils';
import { ScoreLevel } from './scoreChipUtils';

type ScoreVariant = 'single-score' | 'aggregate-score';

const getLevelProps = (
  level: ScoreLevel,
  scoreVariant: ScoreQualityChipProps['scoreVariant'],
  description: ScoreQualityChipProps['description'],
): Pick<ChipProps, 'color' | 'label' | 'sx' | 'deleteIcon' | 'onDelete'> => {
  const showHelpIcon = scoreVariant === 'single-score';
  switch (level) {
    case ScoreLevel.VeryHigh:
      return { color: 'success', label: `Very High` };
    case ScoreLevel.High:
      return { color: 'secondary', label: 'High' };
    case ScoreLevel.Med:
      return { color: 'info', label: 'Medium' };
    case ScoreLevel.Low:
      return { color: 'warning', label: 'Low' };
    case ScoreLevel.VeryLow:
      return { color: 'error', label: 'Very Low' };
    case ScoreLevel.Null:
      return {
        color: 'default',
        label: 'Null',
        sx: {
          color: (theme) => theme.palette.grey[500],
          '.MuiChip-deleteIcon': {
            // adjust null icon up one
            mt: '-1px',
          },
        },
        // overload delete icon for help icon
        deleteIcon: showHelpIcon ? description : undefined,
        // overload delete handler to show help icon
        onDelete: showHelpIcon ? () => null : undefined,
      };
    default:
      bottom(level);
  }
};

interface ScoreQualityChipProps extends Omit<ChipProps, 'label'> {
  level: ScoreLevel;
  score?: number;
  description?: React.ReactElement;
  scoreVariant: ScoreVariant;
}

export const ScoreQualityChip = ({
  level,
  score,
  scoreVariant,
  description,
  ...rest
}: ScoreQualityChipProps) => {
  const { label, ...levelProps } = getLevelProps(level, scoreVariant, description);
  return (
    <Chip
      data-uid={cells$.score.chip}
      {...rest}
      {...levelProps}
      sx={{
        // adjust chip sizing for consistent font scale
        height: '23px',
        fontWeight: 300,
        ...levelProps.sx,
      }}
      label={score != null ? `${label} ${score}/100` : label}
      variant="outlined"
      size="small"
    />
  );
};

interface CustomScoreChipProps {
  value: number | null;
  color: string;
  label: string;
}

export const CustomScoreChip = ({ value, color, label }: CustomScoreChipProps) => (
  <Chip
    label={
      value != null ? (
        <>
          {label} {value}/100
        </>
      ) : (
        <>{label}</>
      )
    }
    sx={{
      color,
      borderColor: color,
      // don't allow "text" cursor
      cursor: 'default',
      textTransform: 'capitalize',
      fontWeight: 300,
      minWidth: (theme) => theme.spacing(7.5),
      '& .MuiChip-deleteIcon': {
        // the delete icon functions as a button, but this chip doesn't do anything if you click it
        // TODO: there is a slight problem where the `onClick` handler of the delete icon button is
        // stopping propagation of the event (which means clicking the icon won't bubble up to the
        // whole row, we currently have this problem for other chips too)
        cursor: 'default',
      },
    }}
    size="small"
    variant="outlined"
    // show the null icon if the value is null & make sure the color is correct
    onDelete={value == null ? () => null : undefined}
    deleteIcon={
      value == null ? (
        <HelpOutline data-uid={cells$.score.nullIcon} sx={{ fill: color }} fontSize="small" />
      ) : undefined
    }
  />
);
