import { CornerVariant, TileVariant } from './HeatmapTile';

/**
 * Helper to get the variant for a specified tile.
 * @param root0 tile
 * @param root0.hasNullValue `true` if the tile has a null value
 * @param root0.selected `true` if the tile is selected
 * @param root0.highlighted `true` if the tile is highlighted
 * @returns the tile variant
 */
export function getTileVariantFromTile({
  hasNullValue,
  selected,
  highlighted,
}: {
  hasNullValue: boolean;
  selected: boolean;
  highlighted: boolean;
}): TileVariant {
  const EMPTY = [] as const;

  const nullValue = hasNullValue ? (['null'] as const) : EMPTY;
  const selectedValue = selected ? (['selected'] as const) : EMPTY;
  const highlightedValue = highlighted ? (['highlighted'] as const) : EMPTY;

  // this type results in all of the various ways that we can build a tile variant
  // the only special one is that if none of these options are true, then the array will
  // be empty and we should use 'default' as our variant
  const variantMapping = [...nullValue, ...selectedValue, ...highlightedValue] as const;

  // join the different options together. if it's an empty string, use 'default'
  // ignore type assertion here because `join` returns a `string`
  // building a type-safe function that can do this without ignoring the type is kind of
  // difficult, but we know that this asserts correctly
  return (variantMapping.join('-') || 'default') as TileVariant;
}

/**
 * Helper to get the rounded corner variant, given a tile's position and map limits.
 * @param param0 tile & map info
 * @param param0.col tile X position
 * @param param0.maxCol map X limit
 * @param param0.row tile Y position
 * @param param0.maxRow map Y limit
 * @returns corner variant
 */
export const getCornerVariantFromMap = ({
  col,
  maxCol,
  row,
  maxRow,
}: {
  col: number;
  maxCol: number;
  row: number;
  maxRow: number;
}): CornerVariant => {
  if (col === 0) {
    if (row === maxRow) {
      return 'top-left';
    } else if (row === 0) {
      return 'bottom-left';
    }
  }
  if (col === maxCol) {
    if (row === maxRow) {
      return 'top-right';
    } else if (row === 0) {
      return 'bottom-right';
    }
  }
  return 'none';
};
