import { GridInitialState } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';

import { SprintsGridProps, useSprintsGrid, UseSprintsGridProps } from './useSprintsGrid';

export const useCustomerSprintsGrid = (sprintGridProps: UseSprintsGridProps): SprintsGridProps => {
  const gridProps = useSprintsGrid(sprintGridProps);

  const columns = useMemo(
    () =>
      gridProps.columns.map((sprintCol) => {
        const customerSprintCol = { ...sprintCol, flex: undefined };
        switch (sprintCol.field) {
          case 'name':
            return {
              ...customerSprintCol,
              minWidth: 175,
            };
          case 'creatorName':
            return {
              ...customerSprintCol,
              minWidth: 150,
            };
          case 'playbookTitle':
            return {
              ...customerSprintCol,
              minWidth: 175,
            };
          case 'status':
            return {
              ...customerSprintCol,
              minWidth: 160,
            };
          case 'totalAccounts':
            return {
              ...customerSprintCol,
              minWidth: 135,
            };
          case 'totalActions':
            return {
              ...customerSprintCol,
              minWidth: 125,
            };
          case 'start':
            return {
              ...customerSprintCol,
              minWidth: 100,
            };
          case 'end':
            return {
              ...customerSprintCol,
              minWidth: 100,
            };
          case 'isFollowing':
            return {
              ...customerSprintCol,
              minWidth: 135,
            };
          default:
            return customerSprintCol;
        }
      }),
    [gridProps.columns],
  );

  const initialState: GridInitialState = useMemo(
    () => ({
      ...gridProps.initialState,
      pinnedColumns: {
        left: ['name'],
      },
    }),
    [gridProps.initialState],
  );

  return {
    ...gridProps,
    initialState,
    columns,
  };
};
