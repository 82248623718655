import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { createContext, useContext } from 'react';

export type AnalyticsContext = {
  /**
   * Callback to identify current user session with analytics services.
   */
  identify: (session: CognitoUserSession) => void;
  /**
   * Callback to reset the analytics services when a user logs out.
   */
  reset: () => void;
};
export const ReefAnalyticsContext = createContext<AnalyticsContext>({
  identify: () => null,
  reset: () => null,
});

export const useReefAnalyticsContext = () => useContext(ReefAnalyticsContext);
