import React, { createContext, useContext } from 'react';

import { User } from '../hooks/user';
import { Account } from '../models/account';
import { Activity, Objective } from '../models/sprint';

type SearchContextType = {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchActivities: (activities: Activity[]) => Activity[];
  searchAccounts: (accounts: Account[]) => Account[];
  searchPlaybooks: (playbooks: Objective[]) => Objective[];
  searchUsers: (users: User[]) => User[];
  resetSearchTerm: () => void;
};
export const SearchContext = createContext<SearchContextType>({
  searchTerm: '',
  resetSearchTerm: () => null,
  setSearchTerm: () => null,
  searchActivities: () => [],
  searchAccounts: () => [],
  searchPlaybooks: () => [],
  searchUsers: () => [],
});

export const useSearchContext = () => useContext(SearchContext);
