import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import {
  ReefRecommendationStatus,
  UpdateRecommendationStatusDocument,
} from '../../../../graphql/generated';
import { useRecommendation } from '../../../../hooks/recommendation';
import { ResultType } from '../../../../models/result';
import { asUUID } from '../../../../models/uuid';
import { DismissRecommendationDialog } from './DismissRecommendationDialog';

export const DismissMyRecommendation = () => {
  const { recId } = useParams();
  const nav = useNavigate();
  const { showDemoData } = useUserSettingsContext();
  const [updateRecommendationStatus, updateRecommendationStatusResult] = useMutation(
    UpdateRecommendationStatusDocument,
  );

  const recommendationId = useMemo(() => (recId != null ? asUUID(recId) : null), [recId]);
  const recommendationResult = useRecommendation(recommendationId);

  const [hasUpdateStatusError, setHasUpdateStatusError] = useState<boolean>(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [isDismissDialogOpen, setIsDismissDialogOpen] = useState<boolean>(true);

  const handleDialogOnClose = useCallback(() => setIsDismissDialogOpen(false), []);
  const handleDialogOnExited = useCallback(() => nav('/work/my/recommendations'), [nav]);

  const handleOnDismissRecommendation = useCallback(() => {
    if (recommendationId != null && !showDemoData) {
      setHasUpdateStatusError(false);
      updateRecommendationStatus({
        variables: {
          recommendationId,
          recommendationStatus: ReefRecommendationStatus.Rejected,
        },
      });
    }

    // skips mutation of demo data is displayed but still takes care of closing the dialog
    if (showDemoData) {
      handleDialogOnClose();
    }
  }, [handleDialogOnClose, recommendationId, showDemoData, updateRecommendationStatus]);

  useEffect(() => {
    if (updateRecommendationStatusResult.loading) {
      setIsUpdatingStatus(true);
    }

    if (updateRecommendationStatusResult.error) {
      console.log(
        'Error dismissing recommendation: ',
        updateRecommendationStatusResult.error.message,
      );
      setHasUpdateStatusError(true);
      setIsUpdatingStatus(false);
    }

    if (updateRecommendationStatusResult.called && updateRecommendationStatusResult.data !== null) {
      handleDialogOnClose();
      setIsUpdatingStatus(false);
      updateRecommendationStatusResult.reset();
    }
  }, [updateRecommendationStatusResult, handleDialogOnClose]);

  return (
    <DismissRecommendationDialog
      open={isDismissDialogOpen}
      recommendation={
        recommendationResult.state === ResultType.Value ? recommendationResult.value : null
      }
      onClose={handleDialogOnClose}
      onDismiss={handleOnDismissRecommendation}
      onExited={handleDialogOnExited}
      hasError={hasUpdateStatusError}
      loading={isUpdatingStatus}
    />
  );
};
