import { Link, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { UUID } from '../../../../models/uuid';
import { FieldWrapper } from '../Account/FieldWrapper';

// name cell needs id at a minimum to navigate
type AccountNameCellProps = GridRenderCellParams<{ account: { id: UUID; name: string } }, string>;

export const AccountNameCell = ({ field, value, row }: AccountNameCellProps) => {
  // clicking on the link should not grid row change selection value
  const onLinkClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <Link
          component={RouterLink}
          to={`/customer-details/${row.account.id}`}
          onClick={onLinkClick}
        >
          {value}
        </Link>
      ) : (
        <Typography variant="body2">&ndash;</Typography>
      )}
    </FieldWrapper>
  );
};
