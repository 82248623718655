import { Box, Container, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';

import reef from '../../../images/reef.min.svg';

export const ManageIndex = () => <Navigate to="/manage/profile" replace />;
export const Manage = () => {
  const nav = useNavigate();

  const isProfileActive = useMatch({ path: '/manage/profile' }) != null;
  const isUserManagementActive = useMatch({ path: '/manage/users' }) != null;
  const isPlaybooksActive = useMatch({ path: '/manage/playbooks' }) != null;

  const tabsValue = useMemo(() => {
    if (isProfileActive) {
      return 'profile';
    }
    if (isUserManagementActive) {
      return 'users';
    }
    if (isPlaybooksActive) {
      return 'playbooks';
    }
    return 'profile';
  }, [isPlaybooksActive, isProfileActive, isUserManagementActive]);

  const onClickNav = useCallback((path: string) => nav(path), [nav]);
  const onTabsChange = useCallback(
    (_: React.SyntheticEvent, value: typeof tabsValue) => onClickNav(`/manage/${value}`),
    [onClickNav],
  );

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.magic.drawerBackground.main,
        backgroundImage: `url(${reef})`,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Container sx={{ mt: 4, mx: 3 }}>
        <Stack spacing={4}>
          <Typography variant="h4">Settings</Typography>
          <Tabs value={tabsValue} onChange={onTabsChange}>
            <Tab value="profile" label="Profile" />
            <Tab value="users" label="Invite Users" />
            <Tab value="playbooks" label="Actions / Playbooks" />
          </Tabs>
        </Stack>
        <Divider />
      </Container>
      <Outlet />
    </Box>
  );
};
