import { Check } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import { UserStatus } from '../../../../graphql/generated';
import { FieldWrapper } from '../Account/FieldWrapper';
import { UsersGridRowModel } from './types';

type UserStatusCellProps = GridRenderCellParams<UsersGridRowModel, UserStatus>;
export const UserStatusCell = ({ field, value }: UserStatusCellProps) => {
  const statusLabel = useMemo(() => {
    switch (value) {
      case UserStatus.Active:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: (theme) => theme.palette.success.dark }}
          >
            <Check />
            <Typography variant="body2">Activated</Typography>
          </Stack>
        );
      case UserStatus.Deactivated:
        return <Typography variant="body2">Deactivated</Typography>;
      case UserStatus.Invited:
        return <Typography variant="body2">Invite Sent</Typography>;
      case UserStatus.NotActive:
        return <Typography variant="body2">Not Active</Typography>;
      default:
        return <Typography variant="caption">Unknown Status: {value}</Typography>;
    }
  }, [value]);
  return (
    <FieldWrapper field={field}>
      {value != null ? statusLabel : <Typography variant="body2">&ndash;</Typography>}
    </FieldWrapper>
  );
};
