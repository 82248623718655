import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import {
  DEFAULT_CUSTOMERS_GRID_FILTERING,
  DEFAULT_CUSTOMERS_GRID_SORTING,
} from '../../../constants';
import { PersistenceKeys } from '../../../contexts/persistenceContext';
import { useSearchContext } from '../../../contexts/searchContext';
import { useCustomersGrid } from '../../../hooks/useCustomersGrid';
import { usePersistence } from '../../../hooks/useLocalStorage';
import { Account } from '../../../models/account';
import { UUID } from '../../../models/uuid';
import { listOfCustomers$ } from '../../../selectors';
import { CustomGrid } from '../CustomGrid';

export interface ListOfCustomersProps {
  /**
   * List of accounts to show in the table.
   */
  accounts: Account[];
  /**
   * List of selected account UUIDs used to identify accounts flagged for the new draft sprint.
   */
  rowSelectionModel: UUID[];
  disabledAccountIds?: UUID[];

  /**
   * If `true`, a link to the product details page will be rendered on the customer's name.
   * @default false
   */
  showProductDetailsLink?: boolean;

  /**
   * If `true`, customer rows will not be selectable. Also implies `customerMenuHidden` is `true`
   * @default false
   */
  disableSelection?: boolean;

  /**
   * If `true`, the customer menu on each row will not be present.
   * @default false
   */
  customerMenuHidden?: boolean;
  /**
   * Called when the "remove customer" menu option is selected for an account.
   */
  onRemoveCustomer?: (account: Account) => void;
  /**
   * Called when table row selection should be updated
   */
  setRowSelectionModel: React.Dispatch<UUID[]>;
  /**
   * Called when the customer row is hovered.
   * @param account the account that is hovered
   */
  onMouseEnterAccount?(account: Account): void;
  /**
   * Called when the user's mouse exits the account row.
   */
  onMouseLeaveAccount?(): void;
  /**
   * Vertical offset, calculated against 100vh.
   */
  offsetHeight?: `${number}rem`;
  /**
   * Flag to disable all column filtering, intended for Filters routes.
   */
  disableFiltering?: boolean;
}

export const ListOfCustomersTable = ({
  accounts,
  showProductDetailsLink = true,
  customerMenuHidden = false,
  disableSelection = false,
  onRemoveCustomer,
  rowSelectionModel,
  disabledAccountIds,
  setRowSelectionModel,
  onMouseEnterAccount,
  onMouseLeaveAccount,
  offsetHeight = '10.5rem',
  disableFiltering = false,
}: ListOfCustomersProps) => {
  const { searchAccounts } = useSearchContext();
  const filteredAccounts = useMemo(() => searchAccounts(accounts), [accounts, searchAccounts]);

  const [initialFilter, setInitialFilter] = usePersistence(
    PersistenceKeys.CustomersGridColumnFiltering,
    DEFAULT_CUSTOMERS_GRID_FILTERING,
  );
  const [initialSorting, setInitialSorting] = usePersistence(
    PersistenceKeys.CustomersGridColumnSorting,
    DEFAULT_CUSTOMERS_GRID_SORTING,
  );

  const customersGridProps = useCustomersGrid({
    initialFilter,
    setInitialFilter,
    initialSorting,
    setInitialSorting,
    accounts: filteredAccounts,
    rowSelectionModel,
    disabledAccountIds,
    setRowSelectionModel,
    customerMenuHidden,
    disableSelection,
    onRemoveCustomer,
    showProductDetailsLink,
    onMouseEnterAccount,
    onMouseLeaveAccount,
    disableFiltering,
  });

  const wrapperBoxProps = useMemo(
    () => ({ outer: { sx: { height: `calc(100vh - ${offsetHeight})` } } }),
    [offsetHeight],
  );

  return (
    <Box data-uid={listOfCustomers$.details.customersTable}>
      <CustomGrid
        {...customersGridProps}
        rows={filteredAccounts}
        GridWrapperBoxProps={wrapperBoxProps}
      />
    </Box>
  );
};
