import { Money as APIMoney } from '../graphql/generated';
export interface Money {
  amount: number;
  currency: string;
}
interface Model extends Record<string, APIMoney[] | null> {}
interface MoneyField extends Record<string, number> {}
interface MoneyFields extends Record<string, MoneyField> {}

const getMoneyField = (values: APIMoney[] | null): MoneyField =>
  (values ?? []).reduce(
    (acc, apiMoney) => ({ ...acc, [apiMoney.currencyCode]: apiMoney.amount }),
    {},
  );
export const getModelMoneyFields = <M extends Model>(
  model: M,
  fields: Array<keyof M>,
): MoneyFields =>
  fields.reduce(
    (acc, field) => ({
      ...acc,
      [field]: getMoneyField(model[field]),
    }),
    {},
  );
