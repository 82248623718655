import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { PropsWithChildren, ReactNode, useCallback, useMemo, useState } from 'react';
import { Navigate, Outlet, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';

import { CUSTOMER_NAME_CHAR_LIMIT } from '../../../constants';
import { useReefNavContext } from '../../../contexts/reefNavContext';
import { AccountStatus } from '../../../graphql/generated';
import { useCustomerDetails } from '../../../hooks/account';
import { useReefFlags } from '../../../hooks/flags';
import { ResultType } from '../../../models/result';
import { UUID } from '../../../models/uuid';
import { sprint$ } from '../../../selectors';
import { customerDetails$ } from '../../../selectors/customerDetails';
import { NeutralAlert } from '../../Alerts/NeutralAlert';
import { Breadcrumbs } from '../../Breadcrumbs';
import { ClampedText } from '../../ClampedText';
import { ComingSoonSection } from '../../ComingSoon';
import { CreateDraftSprintDialog } from '../../Dialogs/CreateDraftSprintDialog';
import { OverviewFieldItem } from '../../OverviewField';
import { CDP_TAB_VALUES } from './types';

export const CustomerDetailsIndex = () => {
  const { accountId } = useParams();
  const navigateTo = useMemo(() => `/customer-details/${accountId}/products`, [accountId]);
  return <Navigate to={navigateTo} replace />;
};
export const CustomerDetails = () => {
  const { accountId } = useParams();
  const nav = useNavigate();
  const location = useLocation();
  const { recentFilterId } = useReefNavContext();
  const { showLegacyProducts } = useReefFlags();

  const [tabToHighlight, setTabToHighlight] = useState<CDP_TAB_VALUES>();
  const [draftSprintDialogOpen, setDraftSprintDialogOpen] = useState(false);
  const [createSprintLoading, setCreateSprintLoading] = useState(false);

  const customerDetailsResult = useCustomerDetails(accountId as UUID | undefined);
  const loading = useMemo(
    () => customerDetailsResult.state === ResultType.Loading,
    [customerDetailsResult.state],
  );
  const selectedAccount = useMemo(
    () => (customerDetailsResult.state === ResultType.Value ? customerDetailsResult.value : null),
    [customerDetailsResult.state, customerDetailsResult.value],
  );
  const sprintAccounts = useMemo(
    () => (selectedAccount != null ? [selectedAccount] : []),
    [selectedAccount],
  );

  const isProductsActive =
    useMatch({ path: `/customer-details/${accountId}/${CDP_TAB_VALUES.Products}` }) != null;
  const isContractsActive =
    useMatch({ path: `/customer-details/${accountId}/${CDP_TAB_VALUES.Contracts}` }) != null;
  const isPipelineActive =
    useMatch({ path: `/customer-details/${accountId}/${CDP_TAB_VALUES.Pipeline}` }) != null;
  const isSupportCasesActive =
    useMatch({ path: `/customer-details/${accountId}/${CDP_TAB_VALUES.SupportCases}` }) != null;
  const isMarketingActive =
    useMatch({ path: `/customer-details/${accountId}/${CDP_TAB_VALUES.Marketing}` }) != null;
  const isSprintsActive =
    useMatch({ path: `/customer-details/${accountId}/${CDP_TAB_VALUES.Sprints}` }) != null;
  const isCustomReportsActive =
    useMatch({ path: `/customer-details/${accountId}/${CDP_TAB_VALUES.CustomReports}` }) != null;

  const tabsValue = useMemo(() => {
    if (isProductsActive) {
      return CDP_TAB_VALUES.Products;
    }
    if (isContractsActive) {
      return CDP_TAB_VALUES.Contracts;
    }
    if (isPipelineActive) {
      return CDP_TAB_VALUES.Pipeline;
    }
    if (isSupportCasesActive) {
      return CDP_TAB_VALUES.SupportCases;
    }
    if (isMarketingActive) {
      return CDP_TAB_VALUES.Marketing;
    }
    if (isSprintsActive) {
      return CDP_TAB_VALUES.Sprints;
    }
    if (isCustomReportsActive) {
      return CDP_TAB_VALUES.CustomReports;
    }
    return CDP_TAB_VALUES.Products;
  }, [
    isProductsActive,
    isContractsActive,
    isPipelineActive,
    isSupportCasesActive,
    isMarketingActive,
    isSprintsActive,
    isCustomReportsActive,
  ]);

  const onClickNav = useCallback((path: string) => nav(path), [nav]);
  const onTabsChange = useCallback(
    (_: React.SyntheticEvent, value: typeof tabsValue) =>
      onClickNav(`/customer-details/${accountId}/${value}`),
    [accountId, onClickNav],
  );

  const closeSprintDialog = useCallback(() => {
    setDraftSprintDialogOpen(false);
    setCreateSprintLoading(false);
  }, []);

  const onCreateSprintClick = useCallback(() => {
    setCreateSprintLoading(true);
    setDraftSprintDialogOpen(true);
  }, []);

  const onFieldMouseLeave = useCallback(() => setTabToHighlight(undefined), []);

  const FieldGrid = ({ children, ...props }: PropsWithChildren<Grid2Props>) => (
    <Grid
      display="flex"
      justifyContent="left"
      alignItems="center"
      xs={3}
      sm={4}
      md={6}
      onMouseLeave={onFieldMouseLeave}
      {...props}
    >
      {children}
    </Grid>
  );

  const customersPathname = useMemo(
    () => `/filter-customers${recentFilterId != null ? `/${recentFilterId}` : ''}`,
    [recentFilterId],
  );
  const crumbs = useMemo(
    (): [string, ReactNode][] => [
      [customersPathname, 'Customers'],
      [
        location.pathname,
        selectedAccount ? (
          selectedAccount.name
        ) : (
          <Skeleton sx={{ minWidth: '4rem' }} variant="text" />
        ),
      ],
    ],
    [customersPathname, location.pathname, selectedAccount],
  );

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Stack
        spacing={3}
        direction="row"
        height="calc(100vh - 2.75rem)"
        width="calc(100vw - 7rem)"
        margin={2.75}
      >
        <Stack spacing={3} maxWidth="23rem">
          <Stack spacing={1} style={{ marginBottom: '-11px' }}>
            <Breadcrumbs style={{ marginTop: '-0.5rem' }} crumbs={crumbs} />
            {selectedAccount ? (
              <ClampedText
                text={selectedAccount.name}
                limit={CUSTOMER_NAME_CHAR_LIMIT}
                TooltipProps={{ placement: 'bottom-end' }}
                TypographyProps={{ variant: 'h4' }}
              />
            ) : (
              <Skeleton variant="text" sx={{ minHeight: (theme) => theme.spacing(5.28) }} />
            )}
          </Stack>

          {selectedAccount?.status === AccountStatus.Churned ? (
            <NeutralAlert severity="warning">Customer Churned</NeutralAlert>
          ) : null}

          <Card
            variant="outlined"
            data-uid={customerDetails$.overview.fields}
            sx={{ minHeight: '33.25rem' }}
          >
            <CardHeader
              title="Company Overview"
              titleTypographyProps={{ variant: 'body1', fontWeight: 500, fontSize: 14 }}
              sx={{ py: 1 }}
            />
            <Divider />
            <CardContent sx={{ minWidth: '21rem' }}>
              <Grid
                container
                rowSpacing={{ xs: 1.5, md: 2 }}
                spacing={{ xs: 2, md: 2.5 }}
                columns={{ xs: 6, sm: 8, md: 12 }}
              >
                <FieldGrid>
                  <OverviewFieldItem
                    field="ownerName"
                    onHover="hide-icon"
                    account={selectedAccount}
                  />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem
                    field="secondaryOwnerName"
                    onHover="hide-icon"
                    account={selectedAccount}
                  />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem
                    field="crmLink"
                    onHover="hide-icon"
                    account={selectedAccount}
                  />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem field="executiveSponsor" loading={loading} />
                </FieldGrid>
                <FieldGrid
                  onMouseEnter={() => setTabToHighlight(CDP_TAB_VALUES.Contracts)}
                  onMouseLeave={onFieldMouseLeave}
                >
                  <OverviewFieldItem
                    field="renewalOwnerName"
                    onHover="hide-icon"
                    account={selectedAccount}
                  />
                </FieldGrid>

                <FieldGrid>
                  <OverviewFieldItem field="engagement" account={selectedAccount} />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem field="productEngagement" account={selectedAccount} />
                </FieldGrid>
                <FieldGrid
                  onMouseEnter={() => setTabToHighlight(CDP_TAB_VALUES.SupportCases)}
                  onMouseLeave={onFieldMouseLeave}
                >
                  <OverviewFieldItem field="supportEngagement" account={selectedAccount} />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem field="customHealthscore" account={selectedAccount} />
                </FieldGrid>

                <FieldGrid>
                  <OverviewFieldItem field="potential" account={selectedAccount} />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem field="customerRevenueBand" account={selectedAccount} />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem field="start" loading={loading} />
                </FieldGrid>
                <FieldGrid
                  onMouseEnter={() => setTabToHighlight(CDP_TAB_VALUES.Contracts)}
                  onMouseLeave={onFieldMouseLeave}
                >
                  <OverviewFieldItem field="renewalDate" account={selectedAccount} />
                </FieldGrid>
                <FieldGrid>
                  <OverviewFieldItem field="currentArr" account={selectedAccount} />
                </FieldGrid>
                <FieldGrid
                  onMouseEnter={() => setTabToHighlight(CDP_TAB_VALUES.Pipeline)}
                  onMouseLeave={onFieldMouseLeave}
                >
                  <OverviewFieldItem field="rawPipeline" account={selectedAccount} />
                </FieldGrid>

                <FieldGrid
                  onMouseEnter={() => setTabToHighlight(CDP_TAB_VALUES.Sprints)}
                  onMouseLeave={onFieldMouseLeave}
                >
                  <OverviewFieldItem field="activeSprints" loading={loading} />
                </FieldGrid>
              </Grid>
            </CardContent>
          </Card>
          <ComingSoonSection sectionKey="notes" />
        </Stack>
        <Stack spacing={3} width="100%">
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Tabs value={tabsValue} onChange={onTabsChange}>
                <Tab
                  data-uid={
                    showLegacyProducts
                      ? customerDetails$.legacyProducts.tab
                      : customerDetails$.products.tab
                  }
                  value={CDP_TAB_VALUES.Products}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      Products
                    </Typography>
                  }
                  sx={{
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                      backgroundColor: (theme) => theme.palette.primary.s12p,
                    },
                    transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
                  }}
                />
                <Tab
                  data-uid={customerDetails$.contracts.tab}
                  value={CDP_TAB_VALUES.Contracts}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      Contracts
                    </Typography>
                  }
                  sx={{
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                      backgroundColor: (theme) => theme.palette.primary.s12p,
                    },
                    transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
                    color: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Contracts
                        ? theme.palette.primary.main
                        : undefined,
                    backgroundColor: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Contracts
                        ? theme.palette.primary.s12p
                        : undefined,
                  }}
                />
                <Tab
                  data-uid={customerDetails$.pipeline.tab}
                  value={CDP_TAB_VALUES.Pipeline}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      Pipeline
                    </Typography>
                  }
                  sx={{
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                      backgroundColor: (theme) => theme.palette.primary.s12p,
                    },
                    transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
                    color: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Pipeline
                        ? theme.palette.primary.main
                        : undefined,
                    backgroundColor: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Pipeline
                        ? theme.palette.primary.s12p
                        : undefined,
                  }}
                />
                <Tab
                  data-uid={customerDetails$.supportCases.tab}
                  value={CDP_TAB_VALUES.SupportCases}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      Support Cases
                    </Typography>
                  }
                  sx={{
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                      backgroundColor: (theme) => theme.palette.primary.s12p,
                    },
                    transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
                    color: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.SupportCases
                        ? theme.palette.primary.main
                        : undefined,
                    backgroundColor: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.SupportCases
                        ? theme.palette.primary.s12p
                        : undefined,
                  }}
                />
                <Tab
                  data-uid={customerDetails$.marketing.tab}
                  value={CDP_TAB_VALUES.Marketing}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      Marketing
                    </Typography>
                  }
                  sx={{
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                      backgroundColor: (theme) => theme.palette.primary.s12p,
                    },
                    transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
                    color: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Marketing
                        ? theme.palette.primary.main
                        : undefined,
                    backgroundColor: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Marketing
                        ? theme.palette.primary.s12p
                        : undefined,
                  }}
                />
                <Tab
                  data-uid={customerDetails$.sprints.tab}
                  value={CDP_TAB_VALUES.Sprints}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      Sprints
                    </Typography>
                  }
                  sx={{
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                      backgroundColor: (theme) => theme.palette.primary.s12p,
                    },
                    transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
                    color: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Sprints
                        ? theme.palette.primary.main
                        : undefined,
                    backgroundColor: (theme) =>
                      tabToHighlight === CDP_TAB_VALUES.Sprints
                        ? theme.palette.primary.s12p
                        : undefined,
                  }}
                />
                <Tab
                  data-uid={customerDetails$.customReports.tab}
                  value={CDP_TAB_VALUES.CustomReports}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      Custom Reports
                    </Typography>
                  }
                  sx={{
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                      backgroundColor: (theme) => theme.palette.primary.s12p,
                    },
                    transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
                  }}
                />
              </Tabs>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LoadingButton
                  data-uid={sprint$.customerDetailsPage.startBtn}
                  size="small"
                  onClick={onCreateSprintClick}
                  loading={createSprintLoading}
                  loadingIndicator="Creating..."
                  disabled={sprintAccounts.length < 1 || draftSprintDialogOpen}
                  color="primary"
                  variant="contained"
                  sx={{ maxHeight: '2.25rem', fontWeight: 'normal', fontSize: '0.75rem' }}
                >
                  Create Sprint
                </LoadingButton>
              </Box>
            </Stack>
            <Divider />
          </Box>
          <Outlet />
        </Stack>
      </Stack>
      <CreateDraftSprintDialog
        accounts={sprintAccounts}
        onClose={closeSprintDialog}
        open={draftSprintDialogOpen}
      />
    </Box>
  );
};
