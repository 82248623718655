import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { usePersistence } from '../hooks/useLocalStorage';
import { User } from '../hooks/user';
import { Account } from '../models/account';
import { Activity, Objective } from '../models/sprint';
import { PersistenceKeys } from './persistenceContext';
import { SearchContext } from './searchContext';

interface SearchProviderProps extends PropsWithChildren {
  persistedSearchTerm?: string;
  setPersistedSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
}
export const SearchProvider = ({
  children,
  persistedSearchTerm,
  setPersistedSearchTerm,
}: SearchProviderProps) => {
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const searchTerm = useMemo(
    () => (persistedSearchTerm != null ? persistedSearchTerm : localSearchTerm),
    [localSearchTerm, persistedSearchTerm],
  );
  const setSearchTerm = useMemo(
    () => (setPersistedSearchTerm != null ? setPersistedSearchTerm : setLocalSearchTerm),
    [setPersistedSearchTerm],
  );

  const searchActivities = useCallback(
    (activities: Activity[]) =>
      searchTerm
        ? activities.filter((act) =>
            act.account.name.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : activities,
    [searchTerm],
  );

  const searchAccounts = useCallback(
    (accounts: Account[]) =>
      searchTerm
        ? accounts.filter((acc) => acc.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : accounts,
    [searchTerm],
  );

  const searchPlaybooks = useCallback(
    (playbooks: Objective[]) =>
      searchTerm
        ? playbooks.filter((pb) => pb.title.toLowerCase().includes(searchTerm.toLowerCase()))
        : playbooks,
    [searchTerm],
  );

  const searchUsers = useCallback(
    (users: User[]) =>
      searchTerm
        ? users.filter((u) => u.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : users,
    [searchTerm],
  );

  const resetSearchTerm = useCallback(() => setSearchTerm(''), [setSearchTerm]);

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        resetSearchTerm,
        setSearchTerm,
        searchActivities,
        searchAccounts,
        searchPlaybooks,
        searchUsers,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

type PersistedAccountSearchProviderProps = React.PropsWithChildren;
export const PersistedAccountSearchProvider = ({
  children,
}: PersistedAccountSearchProviderProps) => {
  const [persistedSearchTerm, setPersistedSearchTerm] = usePersistence(
    PersistenceKeys.CustomersNameSearch,
    '',
  );
  return (
    <SearchProvider
      persistedSearchTerm={persistedSearchTerm}
      setPersistedSearchTerm={setPersistedSearchTerm}
    >
      {children}
    </SearchProvider>
  );
};
