import { Link, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';

import { Renewal } from '../../../../hooks/renewal';
import { FieldWrapper } from '../Account/FieldWrapper';

interface OpportunityCellProps
  extends GridRenderCellParams<
    Pick<Renewal, 'renewalOpportunityId' | 'renewalOpportunityLink' | 'renewalOpportunityName'>,
    string
  > {}
export const OpportunityCell = ({ field, value, row }: OpportunityCellProps) => {
  // clicking on the link should not grid row change selection value
  const onLinkClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        row.renewalOpportunityLink != null ? (
          <Link
            href={row.renewalOpportunityLink}
            rel="noopener noreferrer"
            target="_blank"
            data-opportunity-id={row.renewalOpportunityId}
            onClick={onLinkClick}
          >
            {value}
          </Link>
        ) : (
          <>{value}</>
        )
      ) : (
        <Typography variant="body2">&ndash;</Typography>
      )}
    </FieldWrapper>
  );
};
