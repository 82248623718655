import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Fade,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { REEF_SUPPORT_EMAIL } from '../../../constants';
import {
  AuthActionType,
  AuthStatus,
  useAuthError,
  useReefAuthContext,
} from '../../../contexts/reefAuthContext';
import { AuthError } from '../../Auth/AuthError';

export const Knock = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [knockSignUpComplete, setKnockSignUpComplete] = useState(false);
  const [authError, clearAuthError] = useAuthError();

  const [signingUp, setSigningUp] = useState(false);

  const [authCtx, { authenticate, logout }] = useReefAuthContext();

  const logoutCb = useCallback(async () => {
    if (authCtx.status === AuthStatus.SignedIn) {
      await logout();
    }
  }, [authCtx.status, logout]);

  const signUp = useCallback(
    async (username: string, password: string) => {
      setKnockSignUpComplete(false);
      clearAuthError();
      if (authCtx.status === AuthStatus.SignedOut) {
        setSigningUp(true);
        await authenticate({ type: AuthActionType.SkeletonSignUp, username, password });
        setSigningUp(false);
        setKnockSignUpComplete(true);
      }
    },
    [authCtx.status, authenticate, clearAuthError],
  );

  return (
    <>
      {authCtx.status === AuthStatus.SignedIn && (
        <Stack direction="column">
          <Typography>In order to 💀 knock, you must first sign-out.</Typography>
          <Box sx={{ mt: 3 }}>
            <Button variant="contained" onClick={logoutCb}>
              Sign out
            </Button>
          </Box>
        </Stack>
      )}
      <AuthError username={email} />
      {(!knockSignUpComplete || authError != null) && authCtx.status === AuthStatus.SignedOut && (
        <Box sx={{ width: '50%' }}>
          <Stack direction="column">
            <Box sx={{ mt: 3 }}>
              <TextField
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <TextField
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <TextField
                label="Confirm password"
                type="password"
                fullWidth
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 3, maxWidth: 200 }}>
              <LoadingButton
                variant="contained"
                fullWidth
                loading={signingUp}
                disabled={
                  email.length === 0 ||
                  password.length === 0 ||
                  confirmPassword.length === 0 ||
                  password !== confirmPassword
                }
                onClick={() => signUp(email, password)}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      )}
      <Fade in={knockSignUpComplete && authError == null}>
        <Box sx={{ width: '50%' }}>
          <Alert severity="success">
            <AlertTitle>Successful Knock 🗝</AlertTitle>
            Your Skeleton account <strong>{email}</strong> has been created. Please contact{' '}
            <Link
              href={`mailto:${REEF_SUPPORT_EMAIL}?subject=${encodeURIComponent(
                `Can you please enable the skeleton account "${email}"?`,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {REEF_SUPPORT_EMAIL}
            </Link>{' '}
            to enable your account. Once your account has been enabled, you can{' '}
            <Link component={RouterLink} to={'/skeleton'}>
              skeleton in here
            </Link>
            .
          </Alert>
        </Box>
      </Fade>
    </>
  );
};
