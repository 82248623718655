import React, { useCallback } from 'react';

import { UUID } from '../../../../models/uuid';
import { userWork$ } from '../../../../selectors';
import { MenuButton } from '../../../Buttons/MenuButton/MenuButton';
import { CreateSprintVariantType } from '../../Sprint/CreateSprint';

interface CreateSprintButtonProps {
  recommendationId: UUID;
  accountId: UUID;
  accountName: string;
  showDemoData: boolean;
  playbookTitle: string;
  playbookId: UUID;
}

export const CreateSprintButton = ({
  accountId,
  accountName,
  showDemoData,
  playbookTitle,
  playbookId,
  recommendationId,
}: CreateSprintButtonProps) => {
  const createSprintLink = useCallback(
    (type: CreateSprintVariantType) =>
      showDemoData
        ? `/sprint/${type}?name=${encodeURIComponent(`${playbookTitle} ${accountName}`)}&endOffset=7&playbook=${playbookId}&accounts=${accountId}`
        : `/sprint/${type}?name=${encodeURIComponent(`${playbookTitle} ${accountName}`)}&endOffset=7&playbook=${playbookId}&accounts=${accountId}&recId=${recommendationId}`,
    [showDemoData, playbookTitle, accountName, playbookId, accountId, recommendationId],
  );

  return (
    <MenuButton
      title="Create Sprint"
      data-uid={userWork$.recommendations.createSprintMenuOpenerBtn(recommendationId)}
      options={[
        {
          title: 'Create/Start',
          redirectUrl: createSprintLink('create'),
          'data-uid': userWork$.recommendations.createSprintBtn(recommendationId),
        },
        {
          title: 'In Progress',
          redirectUrl: createSprintLink('capture'),
          'data-uid': userWork$.recommendations.inProgressSprintBtn(recommendationId),
        },
        {
          title: 'Dismiss',
          redirectUrl: `/work/my/recommendations/${recommendationId}/dismiss`,
          'data-uid': userWork$.recommendations.rejectedSprintBtn(recommendationId),
        },
      ]}
    />
  );
};
