import { AccountStatus } from '../graphql/generated';
import { ContractDetails } from './contract';
import { Nominal, UUID } from './uuid';

export type Industry = Nominal<string, 'industry'>;
/**
 * Simple caster to use strings as Industry values.
 * @param v string value, presumably a valid Industry value.
 * @returns Industry value
 * @example
 *    const someIndustry: Industry = asIndustry('BananasInc');
 */
export const asIndustry = (v: string): Industry => v as Industry;

export type FiscalQuarter = `${number} Q${1 | 2 | 3 | 4}`;

export interface Milestone {
  occurredOn: Date | null;
  hasOccurred: boolean | null;
}

export interface Score {
  value: number;
}

export interface ProductConsumptionDetails {
  id: UUID;
  productName: string;
  contractYear: number | null;
  contractedUnits: number | null;
  overageUnitsTotal: number | null;
  overageDollarsTotal: number | null;
  overageDate: Date | null;
  projectedUnitsTotal: number | null;
  serviceStartDate: Date | null;
  serviceEndDate: Date | null;
  unitsUsedTotal: number | null;
  usageUpdatedOn: Date | null;
  hasProduct: boolean;
}

export interface SupportCaseDetails {
  id: UUID;
  caseLink: string;
  caseNumber: string;
  createdAt: Date | null;
  status: string | null;
  caseType: string | null;
  lastModifiedAt: Date | null;
  isOpen: boolean;
  closedOn: Date | null;
  durationDays: number | null;
  priority: string | null;
  supportTier: string | null;
}

export interface ServicePeriodDetails {
  id: UUID;
  parentId: UUID; // Contract or Opportunity?
  serviceStartDate: Date | null;
  serviceEndDate: Date | null;
  productId: UUID;
  productName: string;
  contractedUnits: number | null;
  contractedDollars: number | null;
  currentlyUsedUnits: number | null;
  projectedUnits: number | null;
  projectedUsageDollars: number | null;
  projectedOverageDollars: number | null;
  isFinalServicePeriod: boolean;
}

export interface Metric {
  value: string | null;
  bucket: number;
  category: string | null;
  color?: string;
}

export interface CustomNumericalMetric {
  value: number | null;
  bucket: number;
  category: string;
  color: string;
}

export interface Account {
  id: UUID;
  name: string;
  status: AccountStatus;
  /**
   * A link to the account in the CRM.
   */
  crmLink: string | null;
  /**
   * The user id of the account's primary owner.
   */
  ownerId: UUID | null;
  /**
   * The user name of the account's primary owner.
   */
  ownerName: string | null;
  ownerEmail: string | null;
  /**
   * The user name of the account's renewal owner.
   */
  renewalOwnerName: string | null;
  renewalOwnerEmail: string | null;
  /**
   * The user name of the account's secondary owner.
   */
  secondaryOwnerName: string | null;
  secondaryOwnerEmail: string | null;
  /**
   * Reef defined potential for the account.
   */
  potential: Score | null;
  /**
   * Reef defined engagement for the account.
   */
  engagement: Score | null;
  /**
   * Reef defined risk for the account.
   */
  risk: CustomNumericalMetric | null;
  /**
   * Reef defined growth for the account.
   */
  growth: CustomNumericalMetric | null;
  currentArr: number | null;
  customerRevenue: number | null;
  rawPipeline: number | null;
  sixMoWeightedPipeline: number | null;
  /**
   * Timestamp when the account is slated for renewal.
   */
  renewalDate: Date | null;
  daysUntilRenewal: number | null;
  /**
   * Date when the {@link Account} was updated last.
   */
  reefUpdatedOn: Date;
  /**
   * The industry the account is categorized in.
   */
  industry: Industry | null;
  /**
   * The region the account is categorized in.
   */
  region: string | null;
  /**
   * The date the customer signed on.
   */
  customerStartedOn: Date | null;

  /**
   * The quarter for the next renewal?
   */
  renewalFiscalQuarter: string | null;

  supportCaseClosedDuration: number | null;
  supportCaseCount: number | null;
  supportCaseOpenDuration: number | null;
  supportCaseTotal: number | null;
  /**
   * An account's healthscore metric.
   */
  customHealthscore: Metric | null;
  customerRevenueBand: Metric | null;
  uniqueProductUsers: Score | null;
  advancedProductUsers: Score | null;
  productEngagement: Score | null;
  productConsumptionPercent: number | null;
  supportEngagement: Score | null;
  totalOverageDollars: number | null;
  totalOverageProducts: number | null;

  // implemented milestone
  implementedOn: Date | null;
  hasBeenImplemented: boolean | null;
  daysSinceImplemented: number | null;

  // onboarded milestone
  onboardedOn: Date | null;
  hasBeenOnboarded: boolean | null;
  daysSinceOnboarded: number | null;

  productConsumption: ProductConsumptionDetails[] | null;
  cases: SupportCaseDetails[] | null;
}

export interface AccountProductDetails
  extends Pick<Account, 'id' | 'name' | 'renewalDate' | 'reefUpdatedOn'> {
  productConsumption: ProductConsumptionDetails[];
}

export interface AccountContractDetails extends Pick<Account, 'id' | 'name' | 'reefUpdatedOn'> {
  contracts: ContractDetails[] | null;
}

export interface AccountCaseDetails
  extends Pick<
    Account,
    | 'id'
    | 'name'
    | 'reefUpdatedOn'
    | 'supportCaseClosedDuration'
    | 'supportCaseCount'
    | 'supportCaseOpenDuration'
    | 'supportCaseTotal'
    | 'supportEngagement'
  > {
  cases: SupportCaseDetails[] | null;
}

/**
 * Account fields to consider when evaluating Data Sync quality.
 */
export type AccountHealthField = keyof Pick<
  Account,
  'name' | 'ownerName' | 'currentArr' | 'customerRevenue' | 'renewalDate'
>;
