import React from 'react';

import { useReefFlags } from '../../../../hooks/flags';
import { LegacyProducts } from './LegacyProducts';
import { Products } from './Products';

export const CustomerProducts = () => {
  const { showLegacyProducts } = useReefFlags();
  return showLegacyProducts ? <LegacyProducts /> : <Products />;
};
