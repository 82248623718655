import { Chip, ChipProps } from '@mui/material';
import { darken, lighten } from '@mui/material/styles';
import React, { useMemo } from 'react';

import { RENEWAL_ANALYTICS_COLORS } from '../Charts/Analytics/utils';

const SCORE_COLORS: Record<string, string | undefined> = {
  ...RENEWAL_ANALYTICS_COLORS.Healthscore,
  ...RENEWAL_ANALYTICS_COLORS.Outcome,
};
interface DynamicScoreChipProps extends ChipProps {
  'data-uid'?: string;
  value: string;
}
export const DynamicScoreChip = ({ value, ...props }: DynamicScoreChipProps) => {
  const definedColor = useMemo(
    () => (value != null ? (SCORE_COLORS[value] ?? null) : null),
    [value],
  );
  const sx = useMemo(
    (): ChipProps['sx'] => (theme) => ({
      color: definedColor ? darken(definedColor, 0.2) : theme.palette.text.primary,
      backgroundColor: () => (definedColor ? lighten(definedColor, 0.9) : undefined),
      borderColor: definedColor ? darken(definedColor, 0.2) : theme.palette.text.primary,
      minWidth: theme.spacing(7.5),
      height: '23px',
      fontWeight: 300,
    }),
    [definedColor],
  );
  return (
    <Chip
      data-uid={props['data-uid']}
      label={value}
      variant="outlined"
      size="small"
      sx={sx}
      {...props}
    />
  );
};
