import { Box, BoxProps, Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material';
import React, { HTMLAttributes } from 'react';

import {
  DATA_GRID_TEXT_LIMIT,
  LIST_MENU_ITEM_CHAR_LIMIT,
  PLAYBOOK_MANAGEMENT_TITLE_LIMIT,
  TITLE_CHAR_LIMIT,
} from '../../constants';
import { SelectorUID, tooltip$ } from '../../selectors';

interface ClampedTextProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  limit:
    | typeof LIST_MENU_ITEM_CHAR_LIMIT
    | typeof TITLE_CHAR_LIMIT
    | typeof PLAYBOOK_MANAGEMENT_TITLE_LIMIT
    | typeof DATA_GRID_TEXT_LIMIT;
  maxLines?: number;
  BoxProps?: Partial<BoxProps>;
  TypographyProps?: Partial<
    TypographyProps & { 'data-uid': SelectorUID } & {
      [key: `data-${string}`]: string | number | undefined;
    }
  >;
  TooltipProps?: Partial<TooltipProps>;
}
export const ClampedText = ({
  text,
  limit,
  maxLines = 1,
  BoxProps = {},
  TooltipProps,
  TypographyProps,
  ...rest
}: ClampedTextProps) => {
  const textContent =
    text.length > limit ? (
      <Tooltip data-uid={tooltip$.component} title={text} {...TooltipProps}>
        <Typography {...TypographyProps}>{text}</Typography>
      </Tooltip>
    ) : (
      <Typography {...TypographyProps}>{text}</Typography>
    );

  const { sx: BoxSx, ...BoxRest } = BoxProps;

  return (
    <Box
      // see https://css-tricks.com/line-clampin/#aa-the-standardized-way for styling choices
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: maxLines, // clamp after 1 line by default or more
        WebkitBoxOrient: 'vertical', // orient box for vertical lines
        overflow: 'hidden',
        wordBreak: 'break-all', // truncate on any text, not just words
        ...BoxSx,
      }}
      {...BoxRest}
      {...rest}
    >
      {textContent}
    </Box>
  );
};
