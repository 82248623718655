import { Logout } from '@mui/icons-material';
import { Link, Stack, Typography } from '@mui/material';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import React, { useCallback, useState } from 'react';

import { isSalesforceLink } from '../../../../utils';
import { FieldWrapper } from '../Account/FieldWrapper';

export interface CRMLinkCellProps extends GridRenderCellParams<GridValidRowModel, string> {
  onHover?: 'hide-icon';
}
export const CRMLinkCell = ({ field, value, onHover }: CRMLinkCellProps) => {
  const [hidden, setHidden] = useState(onHover === 'hide-icon');
  const onMouseEnter = useCallback(
    () => (onHover === 'hide-icon' ? setHidden(false) : undefined),
    [onHover],
  );
  const onMouseLeave = useCallback(
    () => (onHover === 'hide-icon' ? setHidden(true) : undefined),
    [onHover],
  );
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <Link
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body2">
              {isSalesforceLink(value) ? 'Salesforce' : 'Profile'}
            </Typography>
            <Logout
              fontSize="small"
              sx={{
                transition: (theme) => theme.transitions.create('opacity'),
                opacity: hidden ? 0 : 1,
              }}
            />
          </Stack>
        </Link>
      ) : (
        <Typography variant="body2">&ndash;</Typography>
      )}
    </FieldWrapper>
  );
};
