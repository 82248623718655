import { Box } from '@mui/material';
import React from 'react';

import { useProductsGrid } from '../../../hooks/useProductsGrid';
import { ProductConsumptionDetails } from '../../../models/account';
import { CustomGrid, CustomGridProps } from '../CustomGrid';

export interface ProductDetailsTableProps {
  products: ProductConsumptionDetails[];
  gridProps?: Partial<CustomGridProps<ProductConsumptionDetails>>;
}

export const ProductDetailsTable = ({ products, gridProps = {} }: ProductDetailsTableProps) => {
  const productsGridProps = useProductsGrid({ products });
  return (
    <Box>
      <CustomGrid
        rows={products}
        {...productsGridProps}
        GridWrapperBoxProps={{ outer: { sx: { height: 'calc(100vh - 13.5rem)' } } }}
        {...gridProps}
      />
    </Box>
  );
};
