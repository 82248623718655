import { ObjValues } from '../../types/util';

// TODO: add support for 'list' delete type as well
type DeleteType = 'action' | 'objective' | 'filter' | 'untyped';

const confirmDeleteDialog = (type: DeleteType): `delete-${DeleteType}-dialog` =>
  `delete-${type}-dialog`;
const cancelDeleteButton = (type: DeleteType): `cancel-delete-${DeleteType}-button` =>
  `cancel-delete-${type}-button`;
const confirmDeleteButton = (type: DeleteType): `confirm-delete-${DeleteType}-button` =>
  `confirm-delete-${type}-button`;

interface DeleteDialog$ {
  confirmDeleteDialog: typeof confirmDeleteDialog;
  confirmDeleteButton: typeof confirmDeleteButton;
  cancelDeleteButton: typeof cancelDeleteButton;
}

interface Dialog$ {
  delete: DeleteDialog$;
}

export const dialog$: Dialog$ = {
  delete: {
    confirmDeleteDialog,
    confirmDeleteButton,
    cancelDeleteButton,
  },
};

export type Dialog$Values = ObjValues<DeleteDialog$>;
