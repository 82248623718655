import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  AccountFilterType,
  AccountNameFilter,
  IndustryFilter,
  RegionFilter,
} from '../../../../models/filters';
import { filterCustomers$ } from '../../../../selectors';
import { FilterStack } from '../FilterStack';

interface StringFilterProps {
  options?: string[];
  disabled?: boolean;
  loading: boolean;
  onChange(filters: string[]): void;
}

interface AccountNameFilterProps extends StringFilterProps {
  // type is necessary because filters could be an empty list
  type: AccountFilterType.AccountNameContains;
  filter: AccountNameFilter | undefined;
}
interface IndustryFilterProps extends StringFilterProps {
  type: AccountFilterType.IndustryContains;
  filter: IndustryFilter | undefined;
}
interface RegionFilterProps extends StringFilterProps {
  type: AccountFilterType.RegionContains;
  filter: RegionFilter | undefined;
}
export const StringFilterInput = ({
  type,
  disabled,
  filter,
  options,
  loading,
  onChange,
}: AccountNameFilterProps | IndustryFilterProps | RegionFilterProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [inputValue, setInputValue] = useState<string>();

  const addValue = useCallback(
    (value: string | undefined) => {
      if (value != null && value !== '') {
        onChange([...(filter?.value ?? []), value]);
        setInputValue(undefined);
        inputRef.current?.focus();
      }
    },
    [filter, onChange],
  );

  const textFieldLabel = useMemo(() => {
    switch (type) {
      case AccountFilterType.AccountNameContains:
        return 'Search Customers';
      case AccountFilterType.IndustryContains:
        return 'Search Industries';
      case AccountFilterType.RegionContains:
        return 'Search Regions';
    }
  }, [type]);

  return (
    <Stack spacing={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addValue(inputValue);
        }}
      >
        <Stack direction="row" spacing={2} useFlexGap>
          <Autocomplete
            fullWidth
            disabled={disabled}
            size="small"
            options={options ?? []}
            freeSolo
            loading={loading}
            value={inputValue ?? null}
            onChange={(_, newValue) => newValue != null && setInputValue(newValue)}
            inputValue={inputValue ?? ''}
            onInputChange={(_, value) => setInputValue(value)}
            isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
            renderInput={(params) => (
              <TextField
                {...params}
                data-uid={filterCustomers$.editFilter.stringMatch.searchInput(type)}
                InputProps={{ ...params.InputProps, startAdornment: <SearchIcon /> }}
                inputRef={inputRef}
                label={textFieldLabel}
              />
            )}
          />
          <Box sx={{ display: 'flex', my: 'auto' }}>
            <LoadingButton
              data-uid={filterCustomers$.editFilter.stringMatch.saveBtn(type)}
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddIcon />}
              disabled={disabled || inputValue == null}
              onClick={() => addValue(inputValue)}
              loading={loading}
            >
              Add
            </LoadingButton>
          </Box>
        </Stack>
      </form>
      <FilterStack
        filters={filter != null ? [filter] : []}
        onDeleteFilter={(i) => {
          const values = filter?.value ?? [];
          onChange([...values.slice(0, i), ...values.slice(i + 1)]);
        }}
      />
    </Stack>
  );
};
