import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import React from 'react';

import { userWork$ } from '../../selectors';
import { customerDetails$ } from '../../selectors/customerDetails';
import { ComingSoonIcon } from './ComingSoonIcon';
import { ComingSoonSectionKey, SectionContent } from './types';

const CARD_PROPS: SectionContent['CardProps'] = {
  sx: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
};
const TAB_CARD_CONTENT_PROPS: SectionContent['CardContentProps'] = {
  sx: { height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
};
const TAB_ICON_STYLES: SectionContent['IconStyles'] = { maxWidth: '20rem' };
const SECTIONS: Record<ComingSoonSectionKey, SectionContent> = {
  notes: {
    uid: customerDetails$.overview.notes,
    header: 'Notes',
    CardProps: CARD_PROPS,
    CardContentProps: {
      sx: {
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    IconStyles: { maxWidth: '12rem' },
  },
  customReports: {
    uid: customerDetails$.customReports.section,
    header: 'Custom Reports',
    CardProps: CARD_PROPS,
    CardContentProps: TAB_CARD_CONTENT_PROPS,
    IconStyles: TAB_ICON_STYLES,
  },
  productReports: {
    uid: customerDetails$.products.section,
    header: 'Product Reports',
    CardProps: CARD_PROPS,
    CardContentProps: TAB_CARD_CONTENT_PROPS,
    IconStyles: TAB_ICON_STYLES,
  },
  marketing: {
    uid: customerDetails$.marketing.section,
    header: 'Marketing',
    CardProps: CARD_PROPS,
    CardContentProps: TAB_CARD_CONTENT_PROPS,
    IconStyles: TAB_ICON_STYLES,
  },
  renewals: {
    uid: customerDetails$.renewals.section,
    header: 'Renewals',
    CardProps: CARD_PROPS,
    CardContentProps: TAB_CARD_CONTENT_PROPS,
    IconStyles: TAB_ICON_STYLES,
  },
  recommendations: {
    uid: userWork$.recommendations.section,
    header: 'My Recommendations',
    CardProps: CARD_PROPS,
    CardContentProps: {
      ...TAB_CARD_CONTENT_PROPS,
      sx: {
        ...TAB_CARD_CONTENT_PROPS.sx,
        minHeight: '68vh',
      },
    },
    IconStyles: TAB_ICON_STYLES,
  },
  supportCases: {
    uid: customerDetails$.supportCases.section,
    header: 'Support Cases',
    CardProps: CARD_PROPS,
    CardContentProps: TAB_CARD_CONTENT_PROPS,
    IconStyles: TAB_ICON_STYLES,
  },
};

interface ComingSoonSectionProps {
  sectionKey: ComingSoonSectionKey;
}
export const ComingSoonSection = ({ sectionKey }: ComingSoonSectionProps) => {
  return (
    <Card
      variant="outlined"
      data-uid={SECTIONS[sectionKey].uid}
      {...SECTIONS[sectionKey].CardProps}
    >
      <CardHeader
        title={SECTIONS[sectionKey].header}
        titleTypographyProps={{ variant: 'body1', fontWeight: 500, fontSize: 14 }}
        sx={{ py: 1 }}
      />
      <Divider />
      <CardContent {...SECTIONS[sectionKey].CardContentProps}>
        <ComingSoonIcon style={SECTIONS[sectionKey].IconStyles} />
      </CardContent>
    </Card>
  );
};
