import { useApolloClient } from '@apollo/client';
import {
  AnalyticsOutlined as AnalyticsOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
  Logout as LogoutIcon,
  PeopleOutlined as PeopleOutlinedIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  SpaceDashboardOutlined as SpaceDashboardOutlinedIcon,
  SyncProblem as SyncProblemIcon,
  TimerOutlined as TimerOutlinedIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { REEF_SUPPORT_EMAIL } from '../../constants';
import { AuthStatus, useReefAuthContext } from '../../contexts/reefAuthContext';
import { useReefNavContext } from '../../contexts/reefNavContext';
import { useReefFlags } from '../../hooks/flags';
import { useCurrentUser } from '../../hooks/user';
import reefTurtleLogo from '../../images/reefTurtleLogo.svg';
import turtle from '../../images/turtle.svg';
import { ResultType } from '../../models/result';
import { UUID } from '../../models/uuid';
import { nav$ } from '../../selectors';
import { NotificationsFeed } from '../Knock';
import { FilterEditDrawer, SprintListMenu } from '../ListMenu';
import { BackgroundLettersAvatar } from './BackgroundLettersAvatar';
import { CollapsibleDrawer } from './CollapsibleDrawer';
import { NavListItem } from './NavListItem';
import { COLLAPSED_WIDTH, EXPANDED_WIDTH, ReefNavTab } from './navUtils';

const SUB_NAV_WIDTH_SM = 150;
const SUB_NAV_WIDTH_MD = 350;
const SUB_NAV_WIDTH_LG = 470;

export const ReefNavDrawer = () => {
  const navigate = useNavigate();
  const [authCtx, { logout }] = useReefAuthContext();
  const apolloClient = useApolloClient();
  const { renewalAnalyticsEnabled } = useReefFlags();

  const location = useLocation();
  const selectedTab = useMemo(
    () =>
      Object.values(ReefNavTab).find((tab) => location.pathname.startsWith(tab)) ??
      ReefNavTab.Filters,
    [location.pathname],
  );

  const { sprintId } = useParams();

  const userResult = useCurrentUser();
  const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLElement>();
  const userMenuOpen = Boolean(userMenuAnchor);

  const {
    sprintMenuState,
    analyticsMenuState,
    filterEditMenuState,
    subNavExpanded,
    expandSubNav,
    collapseSubNav,
    openMenu,
    close,
  } = useReefNavContext();

  const signOutContent = useMemo(() => {
    const isImpersonating =
      authCtx.status === AuthStatus.SignedIn && authCtx.session.isImpersonating;
    return isImpersonating ? 'Stop Impersonating' : 'Sign Out';
  }, [authCtx]);

  /**
   * Called when the user navigates to a sprint or the sprint dashboard.
   */
  const navigateToSprint = useCallback(
    (sprintId?: UUID) => {
      const path = sprintId != null ? `/sprint/${sprintId}` : '/sprint';
      navigate(path);
      close();
    },
    [close, navigate],
  );

  return (
    <>
      <Drawer
        open
        variant="permanent"
        anchor="left"
        sx={(theme) => ({
          '& .MuiDrawer-paper': {
            backgroundImage: `url(${turtle})`,
            backgroundColor: theme.palette.magic.drawerBackground.main,
            // this drawer will have other drawer slide out from underneath it so we need to
            // customize the zIndex to be higher than the default
            zIndex: (theme) => theme.zIndex.drawer + 1,
          },
        })}
      >
        <Stack direction="column" sx={{ my: 2, height: '100%' }}>
          <Box
            sx={{
              width: COLLAPSED_WIDTH,
              display: 'flex',
              cursor: 'pointer',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ maxHeight: 30, minWidth: 40, minHeight: 30 }}
              src={reefTurtleLogo}
              onClick={() => navigate('/')}
            />
          </Box>
          <Collapse collapsedSize={COLLAPSED_WIDTH} orientation="horizontal" in={subNavExpanded}>
            <List
              onMouseEnter={expandSubNav}
              onMouseLeave={collapseSubNav}
              sx={{ width: EXPANDED_WIDTH, my: 2 }}
            >
              <NavListItem
                data-uid={nav$.work.button}
                name="My Work"
                icon={<SpaceDashboardOutlinedIcon data-uid={nav$.work.icon} color="primary" />}
                iconWidth={COLLAPSED_WIDTH}
                active={selectedTab === ReefNavTab.Work}
                onClick={() => navigate('/work')}
              />
              <NavListItem
                data-uid={nav$.lists.button}
                name="Customers"
                icon={<PeopleOutlinedIcon data-uid={nav$.filters.icon} color="primary" />}
                iconWidth={COLLAPSED_WIDTH}
                active={selectedTab === ReefNavTab.Filters}
                onClick={() => navigate('/filter-customers')}
              />
              <NavListItem
                data-uid={nav$.sprints.button}
                name="Sprints"
                icon={<TimerOutlinedIcon data-uid={nav$.sprints.icon} color="primary" />}
                iconWidth={COLLAPSED_WIDTH}
                active={selectedTab === ReefNavTab.Sprints}
                onClick={() => openMenu({ menu: 'sprint' })}
              />
              <NavListItem
                data-uid={nav$.analytics.button}
                name="Analytics"
                icon={<AnalyticsOutlinedIcon data-uid={nav$.analytics.icon} color="primary" />}
                iconWidth={COLLAPSED_WIDTH}
                active={selectedTab === ReefNavTab.Analytics}
                onClick={() => openMenu({ menu: 'analytics' })}
              />
              <NavListItem
                data-uid={nav$.data.button}
                name="Data Sync"
                icon={<SyncProblemIcon data-uid={nav$.data.icon} color="primary" />}
                iconWidth={COLLAPSED_WIDTH}
                active={selectedTab === ReefNavTab.Data}
                onClick={() => {
                  close();
                  navigate('/data');
                }}
              />
            </List>
          </Collapse>
          <Box
            sx={{ width: COLLAPSED_WIDTH, display: 'flex', mt: 'auto', justifyContent: 'center' }}
          >
            <Stack direction="column" spacing={1}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <NotificationsFeed />
              </Box>
              <IconButton
                data-uid={nav$.user.button}
                color="primary"
                onClick={(e) => setUserMenuAnchor(e.currentTarget)}
              >
                {userResult.state === ResultType.Loading && <CircularProgress />}
                {userResult.state === ResultType.Value && (
                  <BackgroundLettersAvatar
                    data-uid={nav$.user.avatar}
                    name={userResult.value.name}
                  />
                )}
                {(userResult.state === ResultType.NoValue ||
                  userResult.state === ResultType.Error) && <PersonIcon />}
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      </Drawer>
      <CollapsibleDrawer
        open={sprintMenuState.open}
        onClose={close}
        width={SUB_NAV_WIDTH_MD}
        horizontalOffset={EXPANDED_WIDTH}
      >
        <SprintListMenu
          selectedSprint={sprintId as UUID | undefined}
          onSelectSprintDashboard={navigateToSprint}
          onSelectSprint={navigateToSprint}
        />
      </CollapsibleDrawer>
      <CollapsibleDrawer
        open={analyticsMenuState.open}
        onClose={close}
        width={SUB_NAV_WIDTH_SM}
        horizontalOffset={EXPANDED_WIDTH}
      >
        <List>
          {renewalAnalyticsEnabled ? (
            <ListItemButton
              data-uid={nav$.analytics.renewalsSubNav}
              component={RouterLink}
              to="/analytics/renewals"
              onClick={close}
            >
              <ListItemText
                primary="Renewals"
                primaryTypographyProps={{ variant: 'button', fontWeight: 400 }}
              />
            </ListItemButton>
          ) : null}
          <ListItemButton
            data-uid={nav$.analytics.riskSubNav}
            component={RouterLink}
            to="/analytics/risk"
            onClick={close}
          >
            <ListItemText
              primary="Risk"
              primaryTypographyProps={{ variant: 'button', fontWeight: 400 }}
            />
          </ListItemButton>
          <ListItemButton
            data-uid={nav$.analytics.growthSubNav}
            component={RouterLink}
            to="/analytics/growth"
            onClick={close}
          >
            <ListItemText
              primary="Growth"
              primaryTypographyProps={{ variant: 'button', fontWeight: 400 }}
            />
          </ListItemButton>
        </List>
      </CollapsibleDrawer>
      <FilterEditDrawer
        open={filterEditMenuState.open}
        onClose={close}
        anchor="right"
        // allow users to interact with the grid while managing filters
        hideBackdrop
        width={SUB_NAV_WIDTH_LG}
        horizontalOffset={EXPANDED_WIDTH}
      />
      <Menu
        open={userMenuOpen}
        anchorEl={userMenuAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setUserMenuAnchor(undefined)}
      >
        {userResult.state === ResultType.Error && (
          <Alert severity="error">Failed to get user data.</Alert>
        )}
        {userResult.state === ResultType.Value && (
          <Box sx={{ m: 2 }}>
            <Typography>
              <strong>{userResult.value.name}</strong>
            </Typography>
            <Typography>{userResult.value.email}</Typography>
          </Box>
        )}
        <MenuItem
          component={Link}
          href={`mailto:${REEF_SUPPORT_EMAIL}?subject=${encodeURIComponent(
            '[Reef.ai] Support Request',
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          Support
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/manage');
            setUserMenuAnchor(undefined);
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={async () => {
            if (authCtx.status === AuthStatus.SignedIn) {
              await logout();
              await apolloClient.clearStore(); // 🧹 query cache on logout
            }
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {signOutContent}
        </MenuItem>
      </Menu>
    </>
  );
};
