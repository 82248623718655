import { Cancel, Link as LinkIcon } from '@mui/icons-material';
import { Box, Chip, Tooltip } from '@mui/material';
import React from 'react';

import { action$ } from '../../selectors';
import { manage$ } from '../../selectors/manage';

/**
 * A basic link will have at least a title and a url, but new links may not have an id yet.
 * So we can omit that here and just build the UI with the understanding that we can
 * edit or just show the links.
 */
interface Link {
  title: string;
  url: string;
}

interface ActionLinksProps<T extends Link> {
  /**
   * A collection of links to render as {@link Chip} components.
   */
  links: T[];
  /**
   * If `'edit'`, puts the chip in an edit context for links and allows more interaction with the chip component.
   */
  mode?: 'edit';
  /**
   * Called when the link is clicked while in edit mode.
   * @param link the link clicked
   * @param index the index of the link that was clicked
   */
  onClick?(link: T, index: number): void;
  /**
   * Called when the link is deleted while in edit mode.
   * @param link the link clicked
   * @param index the index of the link that was clicked
   */
  onDelete?(link: T, index: number): void;
  /**
   * If `true`, clicking a link will not bubble events to the dom.
   */
  stopPropagation?: boolean;
}

export const ActionLinks = <T extends Link>({
  links,
  mode,
  onClick,
  onDelete,
  stopPropagation,
  ...rest
}: ActionLinksProps<T>) => (
  <Box {...rest} sx={{ display: 'flex', flexWrap: 'wrap', mt: -1 }}>
    {links.map((link, index) => (
      <Box key={`${link.title}-${index}`} sx={{ mr: 1, mt: 1 }}>
        {mode === 'edit' ? (
          <Tooltip title={link.url}>
            <Chip
              data-uid={action$.card.link(index)}
              variant="outlined"
              color="primary"
              icon={<LinkIcon />}
              label={link.title}
              onClick={() => onClick?.(link, index)}
              onDelete={() => onDelete?.(link, index)}
              deleteIcon={<Cancel data-uid={manage$.playbooks.deleteActionLinkBtn(index)} />}
            />
          </Tooltip>
        ) : (
          <Chip
            data-uid={action$.card.link(index)}
            variant="outlined"
            color="primary"
            label={link.title}
            component="a"
            href={link.url}
            onClick={(e) => stopPropagation && e.stopPropagation()}
            target="_blank"
            rel="noopener"
            clickable
            icon={<LinkIcon />}
          />
        )}
      </Box>
    ))}
  </Box>
);
