import { ObjValues } from '../types/util';

const score = {
  chip: 'score-cell-chip',
  nullIcon: 'score-null-help-icon',
} as const;

const renewalScore = {
  chip: 'renewal-score-cell-chip',
} as const;

const metric = {
  chip: 'metric-cell-chip',
} as const;

export const cells$ = {
  score,
  metric,
  renewalScore,
} as const;

export type Cells$Values =
  | ObjValues<typeof score>
  | ObjValues<typeof metric>
  | ObjValues<typeof renewalScore>;
