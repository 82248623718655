import { Link, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';

import { FieldWrapper } from '../Account/FieldWrapper';
import { OpportunitiesGridRowModel, OpportunityServicePeriodGridRowModel } from './types';

export type OpportunityNameCellProps = GridRenderCellParams<
  OpportunitiesGridRowModel | OpportunityServicePeriodGridRowModel,
  string
>;
export const OpportunityNameCell = ({ field, value, row }: OpportunityNameCellProps) => {
  // clicking on the link should not propagate for row selections
  const onLinkClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        row.opportunityLink != null ? (
          <Link
            href={row.opportunityLink}
            rel="noopener noreferrer"
            target="_blank"
            data-opportunity-id={row.id}
            onClick={onLinkClick}
          >
            {value}
          </Link>
        ) : (
          <>{value}</>
        )
      ) : (
        <Typography variant="body2">&ndash;</Typography>
      )}
    </FieldWrapper>
  );
};
