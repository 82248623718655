import { ApolloError, useQuery } from '@apollo/client';
import { sumBy } from 'lodash';
import { useMemo } from 'react';

import { useUserSettingsContext } from '../contexts/userSettingsContext';
import {
  Account as APIAccount,
  GetCustomerPipelinePageDocument,
  Opportunity as APIOpportunity,
} from '../graphql/generated';
import { useDemoAugmenters } from '../graphql/hooks';
import { AccountOpportunityDetails, OpportunityDetails } from '../models/opportunity';
import { Result, ResultType, useResultFromQuery } from '../models/result';
import { UUID } from '../models/uuid';
import { toLoggableErrorMessages, transformDate } from '../utils';
import { transformServicePeriods } from './contract';

export const transformOpportunity = (
  o: APIOpportunity,
  currencyCode: string,
): OpportunityDetails => {
  const servicePeriods = transformServicePeriods(o.servicePeriods, currencyCode);
  return {
    id: o.id,
    isClosed: o.isClosed,
    isPipeline: o.isPipeline,
    isRenewal: o.isRenewal,
    opportunityLink: o.crmLink,
    opportunityName: o.name,
    opportunityStage: o.stage,
    scheduledCloseDate: transformDate(o.scheduledCloseDate),
    servicePeriods,
    // FIXME: replace with actual field when available
    totalOpportunityValue: sumBy(servicePeriods, 'contractedDollars'),
  };
};
export const transformOpportunities = (
  opportunities: APIOpportunity[] | null,
  currencyCode: string,
): OpportunityDetails[] | null =>
  opportunities != null ? opportunities.map((o) => transformOpportunity(o, currencyCode)) : null;

type AccountOpportunityFields = Pick<APIAccount, 'id' | 'name' | 'lastUpdated' | 'opportunities'>;
/**
 * Builds Account opportunities from query.
 * @param fields query fields
 * @param currencyCode current user's currency display
 * @returns AccountOpportunityDetails
 */
function getAccountOpportunityDetailsFromAPI(
  fields: AccountOpportunityFields,
  currencyCode: string,
) {
  return {
    id: fields.id,
    name: fields.name,
    reefUpdatedOn: transformDate(fields.lastUpdated),
    opportunities: transformOpportunities(fields.opportunities, currencyCode),
  } satisfies AccountOpportunityDetails;
}

export const useCustomerOpportunities = (
  accountId?: UUID,
): Result<AccountOpportunityDetails, ApolloError> => {
  const { currencyCode } = useUserSettingsContext();
  const variables = accountId != null ? { id: accountId } : undefined;
  const apiOpportunitiesResult = useResultFromQuery(
    useQuery(GetCustomerPipelinePageDocument, { variables, skip: accountId == null }),
  );

  const { augmentAPIOpportunityFieldsWithDemoData, shouldAugmentData } = useDemoAugmenters();

  return useMemo((): Result<AccountOpportunityDetails, ApolloError> => {
    if (accountId == null) {
      return { state: ResultType.NoValue, value: undefined };
    }

    if (apiOpportunitiesResult.state === ResultType.Value) {
      if (apiOpportunitiesResult.value.node.__typename === 'Account') {
        const opportunitiesResult = shouldAugmentData
          ? augmentAPIOpportunityFieldsWithDemoData(apiOpportunitiesResult.value.node)
          : apiOpportunitiesResult.value.node;
        return {
          ...apiOpportunitiesResult,
          value: getAccountOpportunityDetailsFromAPI(opportunitiesResult, currencyCode),
        };
      }

      return { state: ResultType.NoValue, value: undefined };
    }

    if (apiOpportunitiesResult.state === ResultType.Error) {
      console.warn(
        ...toLoggableErrorMessages(
          'useCustomerOpportunities failed to fetch',
          apiOpportunitiesResult.value,
        ),
      );
    }

    return apiOpportunitiesResult;
  }, [
    accountId,
    apiOpportunitiesResult,
    shouldAugmentData,
    augmentAPIOpportunityFieldsWithDemoData,
    currencyCode,
  ]);
};
