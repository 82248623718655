import { useMutation } from '@apollo/client';
import { Box, Card } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { useCallback, useMemo } from 'react';

import { UpdateActivityStatusDocument } from '../../../../graphql/generated';
import { useMyActivities } from '../../../../hooks/sprint';
import {
  UpdateActivityStatusOptions,
  useUserActivitiesGrid,
} from '../../../../hooks/useUserActivitiesGrid';
import { ResultType } from '../../../../models/result';
import { userWork$ } from '../../../../selectors';
import { FailedResult } from '../../../Alerts';
import { CustomGrid } from '../../../Tables/CustomGrid';

export const MyActions = () => {
  const myActivitiesResult = useMyActivities();
  const [updateStatus] = useMutation(UpdateActivityStatusDocument);

  const updateActivityStatus = useCallback(
    async ({ activityId, status }: UpdateActivityStatusOptions): Promise<void> => {
      await updateStatus({ variables: { activityId, status } });
    },
    [updateStatus],
  );

  const loading = useMemo(
    () => myActivitiesResult.state === ResultType.Loading,
    [myActivitiesResult.state],
  );
  const activities = useMemo(
    () => (myActivitiesResult.state === ResultType.Value ? myActivitiesResult.value : []),
    [myActivitiesResult.state, myActivitiesResult.value],
  );
  const activitiesGridProps = useUserActivitiesGrid({ activities, updateActivityStatus });

  if (
    myActivitiesResult.state === ResultType.Error ||
    myActivitiesResult.state === ResultType.NoValue
  ) {
    return <FailedResult resultType="account sprint details" error={myActivitiesResult.value} />;
  }

  return (
    <Card data-uid={userWork$.actions.section} elevation={0}>
      <Box
        sx={{
          '& .MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="true"]': {
            color: (theme: Theme) => theme.palette.success.main,
          },
        }}
      >
        <CustomGrid
          loading={loading}
          GridWrapperBoxProps={{ outer: { sx: { height: 'calc(100vh - 12.5rem)' } } }}
          {...activitiesGridProps}
        />
      </Box>
    </Card>
  );
};
