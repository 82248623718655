import {
  Breadcrumbs as MUIBreadcrumbs,
  BreadcrumbsProps as MUIBreadcrumbsProps,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactNode, useCallback, useState } from 'react';
import { To } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import reefTurtleLogo from '../../images/reefTurtleLogo.svg';
import { CopyURLButton } from '../Buttons';

interface BreadcrumbsProps extends MUIBreadcrumbsProps {
  showHome?: boolean;
  showURLCopy?: boolean;
  autoHide?: boolean;
  crumbs: [To, ReactNode][];
}

export const Breadcrumbs = ({
  crumbs,
  sx,
  showHome = false,
  showURLCopy = true,
  autoHide = true,
  ...rest
}: BreadcrumbsProps) => {
  const [hidden, setHidden] = useState(autoHide);
  const onMouseEnter = useCallback(() => (autoHide ? setHidden(false) : undefined), [autoHide]);
  const onMouseLeave = useCallback(() => (autoHide ? setHidden(true) : undefined), [autoHide]);
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        '*, .MuiTypography-root': {
          fontSize: '0.75rem',
        },
        ...sx,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      {showHome ? (
        <Link component={RouterLink} underline="hover" color="inherit" to="/">
          <img style={{ height: '0.75rem' }} src={reefTurtleLogo} />
        </Link>
      ) : null}
      {crumbs.map(([to, label], index) => {
        const last = index === crumbs.length - 1;

        return last ? (
          <Stack key={`${to}`} direction="row" spacing={0.4} alignItems="center">
            <Typography color="text.primary">{label}</Typography>
            {showURLCopy ? (
              <CopyURLButton
                hidden={autoHide ? hidden : false}
                IconButtonProps={{
                  size: 'small',
                  sx: {
                    py: '1px',
                    px: 0,
                    svg: {
                      fontSize: '1rem',
                    },
                  },
                }}
              />
            ) : null}
          </Stack>
        ) : (
          <Link component={RouterLink} underline="hover" color="primary.main" to={to} key={`${to}`}>
            <Typography key={`${to}`}>{label}</Typography>
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};
