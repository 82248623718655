import { ObjValues } from '../types/util';

const customerName = 'customer-list-customer-name';
const crmLink = 'customer-crm-link';
const industry = 'customer-industry';
const ownerName = 'customer-list-owner-name';
const currentArr = 'customer-list-current-arr';
const rawPipeline = 'customer-list-raw-pipeline';
const sixMoWeightedPipeline = 'customer-list-six-month-weighted-pipeline';
const renewalDate = 'customer-list-renewal-date';
const potentialScore = 'customer-list-potential-score';
const engagementScore = 'customer-list-engagement-score';

// drilldown columns
const uniqueProductUsers = 'product-users-score';
const advancedProductUsers = 'advanced-users-score';
const totalEmailConversations = 'email-conversations-score';
const productEngagement = 'product-engagement-score';
const emailEngagement = 'email-engagement-score';
const eventEngagement = 'event-engagement-score';
const supportCaseCount = 'support-case-count-score';
const customHealthscore = 'custom-healthscore';
const newExperiences = 'new-experiences-score';
const totalExperiences = 'total-experiences-score';
const totalOverageDollars = 'total-overage-dollars-score';
const totalOverageProducts = 'total-overage-products-score';
interface CustomersList$ {
  customerName: typeof customerName;
  crmLink: typeof crmLink;
  industry: typeof industry;
  ownerName: typeof ownerName;
  currentArr: typeof currentArr;
  rawPipeline: typeof rawPipeline;
  sixMoWeightedPipeline: typeof sixMoWeightedPipeline;
  renewalDate: typeof renewalDate;
  potentialScore: typeof potentialScore;
  engagementScore: typeof engagementScore;
  uniqueProductUsers: typeof uniqueProductUsers;
  advancedProductUsers: typeof advancedProductUsers;
  totalEmailConversations: typeof totalEmailConversations;
  productEngagement: typeof productEngagement;
  emailEngagement: typeof emailEngagement;
  eventEngagement: typeof eventEngagement;
  supportCaseCount: typeof supportCaseCount;
  customHealthscore: typeof customHealthscore;
  newExperiences: typeof newExperiences;
  totalExperiences: typeof totalExperiences;
  totalOverageDollars: typeof totalOverageDollars;
  totalOverageProducts: typeof totalOverageProducts;
}

export const customersList$: CustomersList$ = {
  customerName,
  crmLink,
  industry,
  ownerName,
  currentArr,
  rawPipeline,
  sixMoWeightedPipeline,
  renewalDate,
  potentialScore,
  engagementScore,
  uniqueProductUsers,
  advancedProductUsers,
  totalEmailConversations,
  productEngagement,
  emailEngagement,
  eventEngagement,
  supportCaseCount,
  customHealthscore,
  newExperiences,
  totalExperiences,
  totalOverageDollars,
  totalOverageProducts,
};
export type CustomersList$Values = ObjValues<CustomersList$>;
