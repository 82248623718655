import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';

import { customerDetails$ } from '../../selectors/customerDetails';
import { ComingSoonIcon } from '../ComingSoon';
import { ComingSoonFields } from './types';

const FIELD_LABELS: Record<ComingSoonFields, string> = {
  activeSprints: 'Active Sprints',
  executiveSponsor: 'Executive Sponsor',
  start: 'Customer Since',
  contractSummary: 'Contract Summary field',
  pipelineSummary: 'Pipeline Summary field',
};

interface ComingSoonFieldItemProps {
  field: ComingSoonFields;
  loading: boolean;
}
export const ComingSoonFieldItem = ({ field, loading }: ComingSoonFieldItemProps) => (
  <Stack data-uid={customerDetails$.overview.field(field)}>
    <Box sx={{ py: 0.5, maxHeight: '1rem' }}>
      {loading ? (
        <Skeleton variant="text" sx={{ minHeight: 24 }} />
      ) : (
        <ComingSoonIcon style={{ maxWidth: '7rem' }} />
      )}
    </Box>
    <Typography variant="caption" color={(theme) => theme.palette.text.disabled}>
      {FIELD_LABELS[field]}
    </Typography>
  </Stack>
);
